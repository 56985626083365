import React, { useState, useEffect, useCallback } from "react";

import { dialogBox } from "../../template/utils/dialogBox";

import { parseJsonToTableDataList } from "../../template/utils/parser";

import MainContainer from "../../template/components/MainContainer";
import Layout from "../../template/components/Layout";
import Table from "../../template/components/Table";
import Pagination from "../../template/components/Pagination";
import DetalharModal from "./components/DetalharModal";
import RestaurarModal from "./components/RestaurarModal";
import Button from "../../template/components/Button";
import SearchBar from "../../template/components/SearchBar";
import PdfModal from "./components/PdfModal";
import DocumentPDF from "./components/DocumentPDF";
import FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { FaFileCsv } from "react-icons/fa";
import { json2csv } from "json-2-csv";

import { FiUsers } from "react-icons/fi";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdPictureAsPdf } from "react-icons/md";

import { Container, CustomReactSelect, CustomSpinner2 } from "./styles";

import api from "../../services/api";
import { Spinner } from "reactstrap";

import { CustomSpinner } from "../Gerenciar/Pontos/components/styles";
import currentUser from "../../template/utils/currentUser";

const fields = [
  "Responsável",
  "Código Familiar",
  "Município",
  "Renda",
  "Justificativa",
  "Ações",
];

const Restaurar = () => {
  const [familias, setFamilias] = useState<any[]>([]);
  const [listaFamilias, setListaFamilias] = useState<any[]>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalRestaurar, setModalRestaurar] = useState(false);
  const [row, setRow] = useState<any>(null);
  const [extraDataFamily, setExtraDataFamily] = useState<any>(null);
  const [selectedFamilias, setSelectedFamilias] = useState<any>([]);
  const [isFamiliasCleared, setIsFamiliasCleared] = useState(false);
  const [situacaoEspera, setSituacaoEspera] = useState(-1);
  const [situacaoInapta, setSituacaoInapta] = useState(-1);
  //Lista utilizada para paginação
  const [currentFamilia, setCurrentFamilia] = useState<any>([]);
  // Municípios
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(0);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  //paginação
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [csvCheck, setCsvCheck] = useState<boolean>(false);
  const itemsPerPage = 10;
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);

  const [isGestor, setIsGestor] = useState<boolean>(false);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const getSituacao = async () => {
      const { data } = await api.get("situacoes/");
      let situacaoEspera = data.find(
        (element: { situacao: string }) => element.situacao === "EM ESPERA"
      );
      let situacaoInapta = data.find(
        (element: { situacao: string }) => element.situacao === "INAPTO"
      );
      setSituacaoEspera(situacaoEspera.id);
      setSituacaoInapta(situacaoInapta.id);
    };
    getSituacao();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const user = await currentUser();
        setIsGestor(user.is_gestor_estadual);
      } catch (err) {
        notify("error", "Ocorreu um erro");
      }
    })();
  }, []);

  const loadFamilias = React.useCallback(() => {
    setSearching(true);

    let municipioParam =
      municipioSelected === 0 ? "" : municipioSelected.toString();
    if (situacaoInapta > -1) {
      api
        .get(
          `familias/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&situacao=${situacaoInapta}&municipio=${municipioParam}&ordering=responsavel`
        )
        .then((data) => {
          const { results, count } = data.data;
          setFamilias(
            parseJsonToTableDataList(parseFamilias(results), [
              "responsavel",
              "cod_familia",
              "municipio",
              "renda_percapita",
              "observacao",
            ])
          );
          setExtraDataFamily(results);
          setTotal(count);
        })
        .catch((error) => {
          if (error.response.data.municipio) {
            notify("error", `${error.response.data.municipio[0]}`);
          } else notify("error", "Erro no servidor");

          notify("error", "Erro no servidor");
        })
        .finally(() => {
          setSearching(false);
        });
    } else return;
  }, [offset, searchedValue, municipioSelected, situacaoInapta, notify]);

  function parseFamilias(familia: any[]) {
    let tmp = [];
    for (var i = 0; i < familia.length; i++) {
      const object = {
        id: familia[i].id,
        cod_familia: familia[i].cod_familia,
        municipio: familia[i].endereco.municipio.nome,
        responsavel: familia[i].responsavel.nome,
        renda_percapita: familia[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        observacao: familia[i].observacao,
      };
      tmp.push(object);
    }
    return tmp;
  }

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  useEffect(() => {
    loadFamilias();
  }, [loadFamilias]);

  const restaurarSituacaoFamilia = () => {
    if (selectedFamilias.length == 0) {
      notify("error", "É necessário selecionar uma família");
      return;
    }
    const restaurar = async () => {
      try {
        for (var i = 0; i < selectedFamilias.length; i++) {
          await api.patch(`/familias/${selectedFamilias[i]}/`, {
            situacao: situacaoEspera,
            // observacao: data.observacao, Nao possui isso no backend ainda
          });
        }
        notify("success", "Situação restaurada com sucesso");
        loadFamilias();
        toggleRestaurar();
        removeAll();
      } catch (err: any) {
        notify("error", `Erro ao restaurar situação`);
      }
    };
    restaurar();
  };

  // Paginação

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  useEffect(() => {
    const options: any = [{ label: "Todos", value: 0 }];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setSelectOptions(options);
  }, [municipios]);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = (row: any) => {
    toggleDetalhar();
    setRow(row);
  };

  const toggleRestaurar = () => {
    selectedFamilias.length > 0
      ? setModalRestaurar(!modalRestaurar)
      : notify("warning", "Selecione uma família, por favor.");
  };

  //Atualiza lista localmente sem a necessidade de puxar a atualização da api
  const modificarAtributos = useCallback(
    (id: number, obj: any) => {
      const newArr = familias;
      const oldObj = newArr.find((fObject: any) => fObject.id === id);
      newArr.splice(newArr.indexOf(oldObj), 1, obj);
      setCurrentFamilia(newArr.slice(offset, offset + itemsPerPage));
    },
    [currentFamilia]
  );

  const changeSelected = (obj: any) => {
    if (selectedFamilias.find((element: any) => element === obj.id)) {
      setSelectedFamilias(
        selectedFamilias.filter((element: any) => element !== obj.id)
      );
    } else {
      setSelectedFamilias([...selectedFamilias, obj.id]);
    }
  };

  const search = useCallback((searchedValue: string) => {
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  function parseFamiliasDocument(familia: any[]) {
    const tmp = familia.map((fam: any) => {
      return {
        responsavel: fam.responsavel.nome,
        cod_familia: fam.cod_familia,
        renda: fam.renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        justificativa: fam.observacao,
        municipio: fam.endereco.municipio.nome,
      };
    });
    return tmp;
  }

  const getListaFamilias = async (startPage: number, endPage: number) => {
    if (municipioSelected) {
      const start = Number(startPage);
      const end = Number(endPage);

      if (
        start > Math.ceil(total / itemsPerPage) ||
        end > Math.ceil(total / itemsPerPage)
      ) {
        notify(
          "error",
          "Um dos valores informados é maior que o valor final da paginação da lista"
        );
        return;
      }

      setStartPage(startPage);
      setEndPage(endPage);

      const calcEndPage = end - start;

      togglePdf();
      setDownloading(true);
      try {
        const {
          data: { results },
        } = await api.get(
          `familias/?situacao=${situacaoInapta}&municipio=${municipioSelected}&ordering=responsavel&limit=${
            calcEndPage === 0
              ? itemsPerPage
              : calcEndPage * itemsPerPage + itemsPerPage
          }&offset=${(startPage - 1) * itemsPerPage}&search=${searchedValue}`
        );
        const parsedData = parseFamiliasDocument(results);
        setListaFamilias(parsedData);
        setDownloading(false);
      } catch (err) {
        notify(
          "error",
          "Ocorreu um erro ao obter a lista de famílias em espera para pdf"
        );
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (listaFamilias.length !== 0)
      csvCheck === true ? createCSV() : createPDF();
  }, [listaFamilias]);

  const createPDF = async () => {
    try {
      const municipio = municipios?.find(
        (item) => item.id == municipioSelected
      );
      const blob = await pdf(
        <DocumentPDF
          data={listaFamilias}
          count={total}
          totalListaGerada={listaFamilias.length}
          municipio={municipio.nome}
          startPage={startPage}
          endPage={endPage}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      FileSaver.saveAs(
        blob,
        `familias_inaptas_${municipio.nome}_${datenow}.pdf`
      );
      setDownloading(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
    }
  };

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        listaFamilias,
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(
            csvData,
            `relatorio_familias_inaptas_${listaFamilias[0].municipio}_.csv`
          );
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
      console.log(listaFamilias);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
      setCsvCheck(false);
    }
  };

  const addAll = () => {
    let familiasIDs: number[] = [];
    familias.forEach((familia) => {
      familiasIDs.push(familia.id);
    });
    setSelectedFamilias(familiasIDs);
  };

  const removeAll = () => {
    setSelectedFamilias([]);
  };

  const resetClear = () => {
    setIsFamiliasCleared(false);
  };

  const clearFamilias = () => {
    setIsFamiliasCleared(true);
  };

  const togglePdf = () => {
    if (isGestor) setModalPdf(!modalPdf);
    else notify("warning", "Ação permitida apenas para gestores estaduais.");
  };

  const toggleCsv = () => {
    setCsvCheck(false);
  };

  return (
    <Layout>
      <MainContainer
        titlePage={"Lista de famílias inaptas"}
        iconPage={<FiUsers />}
      >
        <PdfModal
          isOpen={modalPdf}
          toggleCsv={toggleCsv}
          toggle={togglePdf}
          csvCheck={csvCheck}
          className="modal-md"
          getFamilias={(startPage: number, endPage: number) =>
            getListaFamilias(startPage, endPage)
          }
          endPageDefault={Math.ceil(total / itemsPerPage)}
        />
        <DetalharModal
          isOpen={modalDetalhar}
          toggle={toggleDetalhar}
          reload={loadFamilias}
          row={row}
          otherData={extraDataFamily}
          modificarObjeto={(id: number, obj: any) =>
            modificarAtributos(id, obj)
          }
        />
        <RestaurarModal
          isOpen={modalRestaurar}
          toggle={toggleRestaurar}
          className="modal-lg"
          qtdFamilias={selectedFamilias.length}
          restaurarFamilia={restaurarSituacaoFamilia}
        />
        <Container>
          <div className="row">
            <CustomReactSelect
              name="municipiosSelect"
              className="space"
              placeholder="Selecione um município"
              options={selectOptions}
              onChange={(e: any) => {
                setMunicipioSelected(e.value);
                first();
              }}
              noOptionsMessage={() => "Nenhum município encontrado"}
            />
            <div
              className="searchBar space"
              style={{ display: "flex", alignItems: "center" }}
            >
              {searching && <CustomSpinner />}
              <SearchBar
                placeholder="Nome do responsável"
                submitHandler={search}
              />
            </div>
          </div>
          <Table
            fields={fields}
            rows={familias}
            iconName="CustomBsPencil"
            functions={[
              {
                name: "Detalhar",
                action: detalhar,
              },
            ]}
            hasSelection={true}
            hasSelectionAll={true}
            changeSelected={changeSelected}
            addAll={addAll}
            removeAll={removeAll}
            isMultiple={true}
            clear={isFamiliasCleared}
            resetClear={resetClear}
          />
          <div className="bottom-buttons">
            <Button
              name={`Desfazer seleção
								${selectedFamilias.length > 0 ? "(" + selectedFamilias.length + ")" : ""}
								`}
              iconButtom={<AiOutlineClose />}
              color="grey"
              onClick={clearFamilias}
            />
            <span className="space">
              <Button
                name={`Restaurar situação ${
                  selectedFamilias.length > 0
                    ? "(" + selectedFamilias.length + ")"
                    : ""
                }`}
                iconButtom={<AiOutlineCheck />}
                onClick={toggleRestaurar}
                color="blue"
              />
            </span>
            {municipioSelected > 0 && (
              <div className="space d-flex align-items-center">
                <Button
                  onClick={() => {
                    if (!downloading) {
                      togglePdf();
                    }
                  }}
                  name={"Baixar PDF"}
                  color={downloading || !isGestor ? "grey" : "blue"}
                  iconButtom={<MdPictureAsPdf />}
                />
                <Button
                  onClick={() => {
                    if (!downloading) {
                      setCsvCheck(true);
                      togglePdf();
                    }
                  }}
                  name={"Baixar CSV"}
                  color={downloading || !isGestor ? "grey" : "blue"}
                  iconButtom={<FaFileCsv />}
                />
                {downloading && <CustomSpinner2 />}
              </div>
            )}
          </div>
          <div className="row">
            Visualizando {familias.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Restaurar;
