import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.span`

  .no-color{
    background: transparent;
    color: ${colors.ultraDarkGrey};
    border:none;

    :hover {
      color: ${colors.darkGrey};
    }
  }

  .blue {
    background: ${colors.bluePLP};
    color: ${colors.light};

    :hover {
      background-color: ${colors.blueOfficial};
      color: ${colors.light};
    }
  }

  .grey {
    background: ${colors.darkGrey};
    color: #fff;

    :hover {
      background-color: ${colors.lightGrey};
      color: ${colors.ultraDarkGrey};
    }
  }

  
  .green {
    background: ${colors.greenOfficial};
    color: #fff;

    :hover {
      background-color: ${colors.greenLightOfficial};
      color: ${colors.light};
    }
  }

  .text {
    margin-bottom: 10px;
  }
`;

export const CustomButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px 5px 10px;
  height: 39px;
  font-size: 14px;
  vertical-align: middle;
  align-items: center;
  color: ${colors.ultraDarkGrey};

  :focus {
    outline: 0;
  }

  :hover {
    text-decoration: none;
    color: ${colors.ultraDarkGrey};
  }
`;

export const CustomIcon = styled.span`
  font-size: 20px;
  margin-right: 7px;
`;
