export default [
  {
    name: "Página inicial",
    icon: "CustomAiOutlineHome",
    route: "/dashboard",
    hasSubMenu: false,
  },
  {
    name: "Gerenciar pessoas",
    icon: "CustomGrGroup",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Entidades Beneficiárias",
        route: "/gerenciar_entidades_beneficiarias",
      },
      {
        name: "Entidades Parceiras",
        route: "/gerenciar_entidades_parceiras",
      },
      {
        name: "Usuários",
        route: "/gerenciar_usuarios",
      },
      {
        name: "Gestores Laticínios",
        route: "/gestor_laticinios",
      },
    ],
  },
  {
    name: "Laticínios",
    icon: "CustomRiTruckLine",
    route: "/gerenciar_laticinios",
    hasSubMenu: false,
  },
  {
    name: "Produtores",
    icon: "CustomGrGroup",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Cadastrar Produtores",
        route: "/cadastrar_produtores",
      },
      {
        name: "Listar produtores",
        route: "/listar_produtores",
      },
      {
        name: "Cadastros pendentes",
        route: "/produtores_pendentes",
      },
    ],
  },
  {
    name: "Listar famílias",
    icon: "CustomFiUsers",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Buscar família",
        route: "/buscar_familias",
      },
      {
        name: "Familias em espera",
        icon: "CustomFiUsers",
        route: "/familias_espera",
      },
      {
        name: "Famílias inaptas",
        icon: "CustomFiUsers",
        route: "/familias_inaptas",
      },
      {
        name: "Famílias vinculadas",
        icon: "CustomFiUsers",
        route: "/familias_vinculadas",
        hasSubMenu: false,
      },
    ],
  },
  {
    name: "Pontos de distribuição",
    icon: "CustomFaRegBuilding",
    route: "/gerenciar_pontos",
    hasSubMenu: false,
  },
  // {
  //   name: "Laticínios",
  //   icon: "CustomRiTruckLine",
  //   route: "#",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       name: "op 1",
  //       route: "/confirmar",
  //     },
  //   ],
  // },
  // {
  //   name: "Produtores",
  //   icon: "CustomGrUserWorker",
  //   route: "#",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       name: "op 1",
  //       route: "/confirmar",
  //     },
  //   ],
  // },
  {
    name: "Relatórios",
    icon: "CustomRiPagesLine",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Distribuições do leite por Ponto",
        route: "/relatorio_distribuicoes_ponto",
      },
      {
        name: "Doações do leite",
        route: "/relatorio_doacoes",
      },
      {
        name: "Entregas do leite por Laticínio",
        route: "/relatorio_entregas_laticinio",
      },
      {
        name: "Presença das famílias nas distribuições",
        route: "/relatorio_presenca",
      },
    ],
  },
];
