import styled from "styled-components";
import Select from "react-select";
import { Spinner } from "reactstrap";

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }
  .space {
    margin-left: 2px;
  }
  .bottom-buttons {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 12px 0;
  }
  table {
    margin-top: 10px;
  }
  .searchBar {
    margin-right: 9px;
  }
`;

export const TableCount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const CustomReactSelect = styled(Select)`
  width: 200px;
`;

export const CustomSpinner2 = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
`;
