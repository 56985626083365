import React, { useCallback, useEffect, useState } from "react";

import { pdf } from "@react-pdf/renderer";

import FileSaver from "file-saver";
import Button from "../../../template/components/Button";
import Layout from "../../../template/components/Layout";
import MainContainer from "../../../template/components/MainContainer";
import Table from "../../../template/components/Table";
import { dialogBox } from "../../../template/utils/dialogBox";
import DocumentPDF from "./components/DocumentPDF";

import { useForm } from "react-hook-form";
import { MdPictureAsPdf } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";

import { CustomInput } from "../../../template/styles/styles";
import {
  parseDate,
  parseDate2,
  parseJsonToTableDataList,
} from "../../../template/utils/parser";

import api from "../../../services/api";

import { json2csv } from "json-2-csv";
import { FaFileCsv } from "react-icons/fa";
import Pagination from "../../../template/components/Pagination";
import SearchBar from "../../../template/components/SearchBar";
import { PontoInterface, TableData } from "../../../template/utils/types";
import { Container, CustomSpinner, CustomSpinner2 } from "./styles";

const RelatorioDistribuicoesPontos = () => {
  const [agruparCheck, setAgruparCheck] = useState<boolean>(false);
  const [distribuicoes, setDistribuicoes] = useState<any[]>([]);
  const [distribuicoesTotais, setDistribuicoesTotais] = useState<any[]>([]);
  //verificadorDistribuicao representa se os dados de alguma distribuição foram carregados e qual
  // 0 - Não foi carregada, 1 - Distribuições totais, 2 - Distribuições agrupadas
  const [verificadorDistribuicao, setVerificadorDistribuicao] =
    useState<number>(0);
  const [distribuicoesTableData, setDistribuicoesTableData] = useState<
    TableData[]
  >([]);
  const [distribuicoesAgrupadas, setDistribuicoesAgrupadas] = useState<any[]>(
    []
  );
  const [downloading, setDownloading] = useState<Boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [pontos, setPontos] = useState<PontoInterface[]>([]);
  const [pontoSelected, setPontoSelected] = useState<number>(-1);
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(-1);
  const [laticinio, setLaticinio] = useState<any>();
  const [totalBovino, setTotalBovino] = useState(0);
  const [totalCaprino, setTotalCaprino] = useState(0);
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [csvCheck, setCsvCheck] = useState<boolean>(false);
  const { register, handleSubmit, setValue, getValues, errors } =
    useForm<any>();

  const itemsPerPage = 10;

  //Titulo da tabela
  const fields = [
    "Código da família",
    "Responsável da família",
    "Qtd Bovino",
    "Qtd Caprino",
    "Tipo",
    "Data",
  ];

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada = `pontos/?municipio=${municipioSelected}`;

        //Retorna todos os pontos cadastrados
        if (municipioSelected == -1) {
          chamada = `pontos/`;
        }
        const { data } = await api.get(chamada);
        setPontos(data.results);
      } catch (error) {
        notify("error", "Erro no servidor");
      }
    };
    loadPontos();
  }, [municipioSelected]);

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  const onSubmit = () => {
    setIsSearching(true);
    loadDistribuicoes();
  };

  useEffect(() => {
    if (distribuicoes?.length !== 0) loadDistribuicoes();
  }, [offset, searchedValue, notify]);

  useEffect(() => {
    setIsSearching(false);
  }, [distribuicoesTableData]);

  useEffect(() => {
    let laticinio2 = pontos.find(
      (item) => item.id === pontoSelected
    )?.laticinio;
    setLaticinio(laticinio2);
  }, [pontoSelected]);

  const loadDistribuicoes = useCallback(async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      //Query
      let queryTablePaginada = `distribuicoes/relatorio/?limit=${itemsPerPage}&offset=${offset}&agrupar=&municipio=${
        municipioSelected > -1 ? municipioSelected : ""
      }&ponto=${
        pontoSelected > -1 ? pontoSelected : ""
      }&data_inicial=${dataInicial}&data_final=${dataFinal}&search=${searchedValue}&ordering=-updated_at`;

      //Busca os dados na API
      const { data } = await api.get(queryTablePaginada);
      const {
        count,
        results,
        qtd_total_leite_bovino,
        qtd_total_leite_caprino,
      } = data;
      setDistribuicoes(results);
      //Quantidade total de leite
      setTotalBovino(qtd_total_leite_bovino);
      setTotalCaprino(qtd_total_leite_caprino);
      //Total de registros
      setTotal(count);
      //Zera o verificador de distribuição
      setVerificadorDistribuicao(0);
      //Adiciona o resultado ao array de distribuicoes
      setDistribuicoesTableData(
        parseJsonToTableDataList(results, [
          "cod_familia",
          "responsavel__nome",
          "quantidade_leite_bovino",
          "quantidade_leite_caprino",
          "tipo",
          "data",
        ])
      );
      //Verifica se não distribuições
      if (count === 0) {
        setIsSearching(false);
        notify("warning", "Sem distribuições");
        return;
      }
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  }, [offset, searchedValue, municipioSelected, pontoSelected, notify]);

  function agruparCheckHandle() {
    setAgruparCheck(!agruparCheck);
  }

  const getDistribuicoesTotais = async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      if (
        getValues("dataInicial") === undefined ||
        getValues("dataFinal") === undefined
      )
        return;
      //Query
      let query = `distribuicoes/relatorio/?limit=&offset=&agrupar=&municipio=${
        municipioSelected > -1 ? municipioSelected : ""
      }&ponto=${
        pontoSelected > -1 ? pontoSelected : ""
      }&data_inicial=${dataInicial}&data_final=${dataFinal}&search=&ordering=-updated_at`;
      //Busca os dados na API
      const { data } = await api.get(query);
      const { count, results } = data;
      setDistribuicoesTotais(results);
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  const getDistribuicoesAgrupadas = async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      if (dataInicial === undefined || dataFinal === undefined) return;
      //Query
      let query = `distribuicoes/relatorio/?limit=&offset=&agrupar=true&municipio=${
        municipioSelected > -1 ? municipioSelected : ""
      }&ponto=${
        pontoSelected > -1 ? pontoSelected : ""
      }&data_inicial=${dataInicial}&data_final=${dataFinal}&search=&ordering=-updated_at`;
      //Busca os dados na API
      const { data } = await api.get(query);
      const { count, results } = data;
      setDistribuicoesAgrupadas(results);
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  //Modifica o indicador do tipo de distribuição a ser utilizada na criação do PDF
  const getVerificadorDistribuicao = async () => {
    try {
      if (distribuicoes.length === 0) {
        notify("warning", "Sem distribuições");
      } else if (agruparCheck) {
        await getDistribuicoesAgrupadas();
        setVerificadorDistribuicao(2);
      } else {
        await getDistribuicoesTotais();
        setVerificadorDistribuicao(1);
      }
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      verificadorDistribuicao !== 0 &&
      (distribuicoesTotais.length !== 0 || distribuicoesAgrupadas.length !== 0)
    ) {
      csvCheck === true ? createCSV() : createPDF();
    }
  }, [verificadorDistribuicao]);

  const handleDownload = async () => {};

  //Cria o PDF
  const createPDF = async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      //Query
      let queryTablePaginada = `distribuicoes/relatorio-distribuicao/pdf/?ponto=${pontoSelected}&data_inicial=${dataInicial}&data_final=${dataFinal}&search=${searchedValue}&limit=20`;

      //Busca os dados na API
      await api
        .get(
          queryTablePaginada,
          { responseType: "blob" } // !!!
        )
        .then((response) => {
          setAgruparCheck(false);
          setDownloading(false);
          setVerificadorDistribuicao(0);
          window.open(URL.createObjectURL(response.data));
        });
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        agruparCheck ? distribuicoesAgrupadas : distribuicoesTotais,
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "Relatorio_Distribuicoes_Ponto.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloading(false);
      setCsvCheck(false);
    }
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((value: string) => {
    value = value.replace(/[./-]/g, "").trim();
    let query;
    setSearchedValue(value);
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer
        titlePage="Relatório de Distribuições do Leite por Ponto"
        iconPage={<RiPagesLine />}
      >
        <Container>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching && <CustomSpinner2 />}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomInput>
                      <select
                        className={
                          errors.municipio
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder={"Selecione um município"}
                        name="municipio"
                        defaultValue={municipioSelected}
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setMunicipioSelected(e.currentTarget.value);
                          setPontoSelected(-1);
                          setValue("ponto", -1);
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {municipios.map((item) => (
                          <option value={parseInt(item.id)}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.municipio && (
                        <span className="error-message">
                          Selecione um município
                        </span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label>Ponto</label>
                    <CustomInput>
                      <select
                        className={
                          errors.ponto
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        name="ponto"
                        defaultValue={pontoSelected}
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setPontoSelected(parseInt(e.currentTarget.value));
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {pontos.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.ponto && (
                        <span className="error-message">
                          Selecione um ponto
                        </span>
                      )}
                    </CustomInput>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="dataInicial">Data inicial</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataInicial
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de início"
                        name="dataInicial"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataInicio(e.currentTarget.value);
                        }}
                      />
                      {errors.dataInicial?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataInicial?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="dataFinal">Data final</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataFinal
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de fim"
                        name="dataFinal"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataFim(e.currentTarget.value);
                        }}
                      />
                      {errors.dataFinal?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataFinal?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isSearching ? true : false}
                    onClick={(event) => {
                      if (
                        getValues("dataInicial") === undefined ||
                        getValues("dataFinal") === undefined
                      ) {
                        event.preventDefault();
                        notify("error", "Preencha todos os campos");
                      } else if (
                        getValues("dataInicial") > getValues("dataFinal")
                      ) {
                        event.preventDefault();
                        notify("error", "Data inicial maior que data final");
                      }
                      setSearchedValue("");
                      setOffset(0);
                      setPage(1);
                    }}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="searchBar">
            <SearchBar
              placeholder="Resposável ou Código"
              submitHandler={search}
            />
          </div>
          <Table
            fields={fields}
            rows={distribuicoesTableData}
            hasSelection={false}
            hasSelectionAll={false}
          />
          <div className="containerFooterTable">
            Visualizando {distribuicoes.length} de um total de {total} registros
            <div className="pagesButtons">
              <Pagination
                itemCount={total}
                itemsPerPage={itemsPerPage}
                selectedPage={page}
                handlePageChange={changePage}
                handleNextPage={next}
                handlePreviousPage={previous}
                handleLastPage={last}
                handleFirstPage={first}
                maxPages={10}
              />
            </div>
          </div>
          <div>Total de leite Bovino distribuído: {totalBovino}</div>
          <div>Total de leite Caprino distribuído: {totalCaprino}</div>
          <div>Total de leite distribuído: {totalBovino + totalCaprino}</div>
          {searchedValue === "" ? (
            <>
              <div className="CheckBoxContainer">
                <label className="space">
                  <input
                    type="checkbox"
                    name="agregar"
                    checked={agruparCheck}
                    onChange={agruparCheckHandle}
                  />{" "}
                  <span style={{ marginLeft: 5 }}>Agrupar por família</span>
                </label>
              </div>
              <div className="botoes" style={{ marginTop: 10 }}>
                <Button
                  onClick={() => {
                    if (distribuicoes.length === 0) {
                      return;
                    } else if (!downloading) {
                      setDownloading(true);
                      getVerificadorDistribuicao();
                    }
                  }}
                  name="Baixar PDF"
                  color={
                    downloading || distribuicoes.length === 0 ? "grey" : "blue"
                  }
                  iconButtom={<MdPictureAsPdf />}
                />

                <Button
                  onClick={() => {
                    if (distribuicoes.length === 0) {
                      return;
                    } else if (!downloading) {
                      setDownloading(true);
                      setCsvCheck(true);
                      getVerificadorDistribuicao();
                    }
                  }}
                  name="Baixar CSV"
                  color={
                    downloading || distribuicoes.length === 0 ? "grey" : "blue"
                  }
                  iconButtom={<FaFileCsv />}
                />

                {downloading && (
                  <div>
                    <CustomSpinner /> Processando
                  </div>
                )}
              </div>{" "}
            </>
          ) : (
            <></>
          )}
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RelatorioDistribuicoesPontos;
