import React, { useCallback, useEffect, useState } from "react";

import { dialogBox } from "../../template/utils/dialogBox";

import { parseJsonToTableDataList } from "../../template/utils/parser";

import Button from "../../template/components/Button";
import Layout from "../../template/components/Layout";
import MainContainer from "../../template/components/MainContainer";
import Pagination from "../../template/components/Pagination";
import Table from "../../template/components/Table";
import DetalharModal from "./components/DetalharModal";
import VincularModal from "./components/VincularModal";

import { FiUsers } from "react-icons/fi";

import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

import { createFilter } from "react-select";
import { Container, CustomReactSelect, CustomSpinner2 } from "./styles";

import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { json2csv } from "json-2-csv";
import { Controller, useForm } from "react-hook-form";
import { FaFileCsv } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";
import NumberFormat from "react-number-format";
import { Input } from "reactstrap";
import api from "../../services/api";
import SearchBar from "../../template/components/SearchBar";
import AlertModal from "./components/AlertModal";
import DocumentPDF from "./components/DocumentPDF";
import PdfModal from "./components/PdfModal";
import currentUser from "../../template/utils/currentUser";
//Header da tabela
const fields = [
  "Responsável",
  "Código Familiar",
  "Situação",
  "Renda",
  "Tipo",
  "Bairro",
  "Ações",
];

const Vincular = () => {
  const [familias, setFamilias] = useState<any[]>([]);
  const [pontos, setPontos] = useState<any[]>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalVincular, setModalVincular] = useState(false);
  const [isFamiliasCleared, setIsFamiliasCleared] = useState(false);
  const [selectedFamilias, setSelectedFamilias] = useState<any>([]);
  const [selectedTodasFamilias, setSelectedTodasFamilias] = useState<any>([]);
  const [selectedPonto, setSelectedPonto] = useState<any>(null);
  const [row, setRow] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  // Municípios
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(-1);
  //paginação
  const [total, setTotal] = useState<number>(0);
  const [totalPontos, setTotalPontos] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const itemsPerPage = 10;
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [extraDataFamily, setExtraDataFamily] = useState<any>([]);
  const { handleSubmit, control, errors } = useForm<any>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  //Lista utilizada para paginação
  const [modalAlert, setModalAlert] = useState<boolean>(false);
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );
  const [isVinculandoTodos, setIsVinculandoTodos] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [municipioSelecionadoLabel, setMunicipioSelecionadoLabel] =
    useState<string>("");
  const [municipioSelecionado, setMunicipioSelecionado] = useState<number>();
  const [bairroSelecionado, setBairroSelecionado] = useState<number>();
  const [rendaSelecionada, setRendaSelecionado] = useState<number>();
  const [posVinculacao, setPosVinculacao] = useState<boolean>(false);
  const [listaFamilias, setListaFamilias] = useState<any[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);
  const [situacao, setSituacao] = useState<number>(0);
  const [csvCheck, setCsvCheck] = useState<boolean>(false);

  const [isGestor, setIsGestor] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await api.get("situacoes/");
        const situacaoId = data.find(
          (obj: any) => obj.situacao === "EM ESPERA"
        ).id;
        setSituacao(situacaoId);
      } catch (err) {
        notify("error", "Ocorreu um erro ao chamar situação");
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const user = await currentUser();
        setIsGestor(user.is_gestor_estadual);
      } catch (err) {
        notify("error", "Ocorreu um erro");
      }
    })();
  }, []);

  const loadFamilias = async (
    municipioSelecionado = 0,
    bairroSelecionado = 0,
    rendaSelecionada = 0,
    posVinc = false
  ) => {
    try {
      if (municipioSelecionado && rendaSelecionada && situacao) {
        const { data } = await api.get(
          `familias/?situacao=${situacao}&municipio=${municipioSelecionado}&bairro=${bairroSelecionado}&renda_percapita=${rendaSelecionada}&ordering=renda_percapita&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}`
        );
        const results = data.results;

        const dadosFormatados = parseJsonToTableDataList(
          parseFamilias(results),
          [
            "responsavel",
            "cod_familia",
            "situacao",
            "renda_percapita",
            "tipo_leite",
            "bairro",
          ]
        );
        setFamilias(dadosFormatados);

        //Pega dados originais da requisição sem passar pelos parses para serem utilizados para acessar atributos não acessiveis após o parser
        setExtraDataFamily(results);

        setTotal(data.count);
        if (data.count === 0 && !posVinc) {
          notify("warning", "Nenhuma família encontrada");
        }

        clearFamilias();
        setIsSearching(false);
        setPosVinculacao(false);
      }
    } catch (error) {
      setIsSearching(false);
      notify("error", "Erro no servidor");
    }
  };

  const search = useCallback((searchedValue: string) => {
    setSearchedValue(searchedValue);
    first();
  }, []);

  const priceFormatter = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value / 100);
    return `${prefix}${amount}`;
  }, []);

  const priceUnformatter = useCallback(
    (value: any) =>
      value.toString().replace(/[R$ ]/g, "").replace(".", "").replace(",", "."),
    []
  );

  const onSubmit = (data: any) => {
    setIsSearching(true);
    const {
      filtro_municipioSelect,
      filtro_bairroSelect,
      filtro_rendaPerCapitaSelect,
    } = data;
    const { label, value } = filtro_municipioSelect;
    const renda = parseFloat(priceUnformatter(filtro_rendaPerCapitaSelect));
    setMunicipioSelecionado(value);
    setMunicipioSelecionadoLabel(label);
    setRendaSelecionado(renda);
    setBairroSelecionado(filtro_bairroSelect);
    getPontos(value);
    // Se quando pesquisar tiver valor preenchido no campo de pesquisa para os resultados do filtro, resetar campo de pesquisas dos resultados
    if (searchedValue) {
      setSearchedValue("");
    } else {
      // Se página for igual a 1 apenas executar a chamada de loadfamilias sem resetar paginação para a primeira página
      if (page === 1) {
        loadFamilias(value, filtro_bairroSelect, renda);
      } else {
        first();
      }
    }
  };

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  const getPontos = async (municipioSelecionado = 0) => {
    if (municipioSelecionado) {
      const { data } = await api.get(
        `pontos/?municipio=${municipioSelecionado}`
      );
      const { count, results } = data;
      console.log(results);
      setPontos(
        parseJsonToTableDataList(parsePontos(results), [
          "nome",
          "entidade",
          "vagas",
        ])
      );
      setTotalPontos(count);
    }
  };

  const changeSelected = (obj: any) => {
    clearTodasFamilias();
    if (selectedFamilias.find((element: any) => element === obj.id)) {
      setSelectedFamilias(
        selectedFamilias.filter((element: any) => element !== obj.id)
      );
    } else {
      setSelectedFamilias([...selectedFamilias, obj.id]);
    }
  };

  const clearTodasFamilias = () => {
    setSelectedTodasFamilias([]);
  };

  const selectPonto = (obj: any) => {
    setSelectedPonto(obj);
  };

  const addAll = async (quant: number) => {
    //Limpa lista de famílias e fecha o modal de alert
    clearFamilias();
    toggleAlert();
    setIsVinculandoTodos(true);
    api
      .get(
        `familias/?situacao=${situacao}&municipio=${municipioSelecionado}&bairro=${bairroSelecionado}&renda_percapita=${rendaSelecionada}&ordering=renda_percapita&limit=${quant}&offset=0&search=${searchedValue}`
      )
      .then((res) => {
        const familias: any[] = res.data.results;
        let familiasIDs: number[] = [];
        familias.map((fam) => {
          familiasIDs.push(fam.id);
        });

        setSelectedTodasFamilias(familiasIDs);
        toggleVincular();
        setIsVinculandoTodos(false);
      })
      .catch((error) => {
        notify("error", "Ocorreu um erro ao vincular todas as famílias");
        setIsVinculandoTodos(false);
      });
  };

  const removeAll = () => {
    setSelectedFamilias([]);
  };

  const clearFamilias = () => {
    setIsFamiliasCleared(true);
  };

  const resetClear = () => {
    setIsFamiliasCleared(false);
  };

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const toggleVincular = () => {
    setSelectedPonto(null);
    setModalVincular(!modalVincular);
  };

  const detalhar = (row: any) => {
    toggleDetalhar();
    setRow(row);
  };

  const vincularFamiliasAoPonto = () => {
    if (!selectedPonto) {
      notify("warning", "É necessário selecionar um ponto");
      return;
    }

    const checarVagasPonto = (
      pontoVinc: number,
      selectedFamiliasArr: number[]
    ) => {
      const checkPonto = pontos.find((ponto) => ponto.id === pontoVinc);
      return checkPonto?.vagas >= selectedFamiliasArr.length ? false : true;
    };

    const vincular = async () => {
      const arrFamiliasIds =
        selectedTodasFamilias.length > 0
          ? selectedTodasFamilias
          : selectedFamilias;
      if (checarVagasPonto(selectedPonto.id, arrFamiliasIds)) {
        notify("error", "Esse ponto não possui mais vagas");
        return;
      }
      try {
        setLoading(true);
        await api.patch("familias/vincular_familias/", {
          ponto_id: selectedPonto.id,
          familias: arrFamiliasIds,
        });
        notify("success", "Familia(s) vinculada(s)");
        setPosVinculacao(true);
        setLoading(false);
        // Se a paginação for maior que 1 faz o reset da paginação com o valor atualizado do offset, se estiver na primeira página apenas chama loadFamílias sem atualizar o valor do offset
        if (page > 1) {
          first();
        } else {
          loadFamilias(
            municipioSelecionado,
            bairroSelecionado,
            rendaSelecionada,
            true
          );
        }
        // Reseta selecionadores e campo de pesquisa após ocorrer vinculação
        resetEstados();
        //Atualiza lista de pontos após vinculação de famílias a um ponto
        getPontos(municipioSelecionado);
        toggleVincular();
      } catch (err) {
        setLoading(false);
        notify("error", `Erro no servidor`);
      }
    };
    vincular();
  };

  const resetEstados = () => {
    clearFamilias();
    clearTodasFamilias();
    setSearchedValue("");
  };

  const getListaFamilias = async (startPage: number, endPage: number) => {
    if (municipioSelecionado && rendaSelecionada) {
      const start = Number(startPage);
      const end = Number(endPage);

      if (
        start > Math.ceil(total / itemsPerPage) ||
        end > Math.ceil(total / itemsPerPage)
      ) {
        notify(
          "error",
          "Um dos valores informados é maior que o valor final da paginação da lista"
        );
        return;
      }

      setStartPage(startPage);
      setEndPage(endPage);

      const calcEndPage = end - start;

      togglePdf();
      setDownloading(true);
      try {
        const {
          data: { results },
        } = await api.get(
          `familias/?situacao=${situacao}&municipio=${municipioSelecionado}&bairro=${bairroSelecionado}&renda_percapita=${rendaSelecionada}&ordering=renda_percapita&limit=${
            calcEndPage === 0
              ? itemsPerPage
              : calcEndPage * itemsPerPage + itemsPerPage
          }&offset=${(startPage - 1) * itemsPerPage}&search=${searchedValue}`
        );
        const parsedData = parseFamiliasDocument(results);
        setListaFamilias(parsedData);
        setDownloading(false);
      } catch (err) {
        notify(
          "error",
          "Ocorreu um erro ao obter a lista de famílias em espera para pdf"
        );
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (listaFamilias.length !== 0) {
      setDownloading(true);
      csvCheck === true ? createCSV() : createPDF();
    }
  }, [listaFamilias]);

  const createPDF = async () => {
    try {
      const blob = await pdf(
        <DocumentPDF
          data={listaFamilias}
          count={total}
          totalListaGerada={listaFamilias.length}
          municipio={municipioSelecionadoLabel}
          startPage={startPage}
          endPage={endPage}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      FileSaver.saveAs(
        blob,
        `familias_em_espera_${municipioSelecionadoLabel}_${datenow}.pdf`
      );
      setDownloading(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
    }
  };

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        listaFamilias,
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "relatorio_familias_em_espera.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloading(false);
      setCsvCheck(false);
    }
  };

  function parseFamiliasDocument(familia: any[]) {
    let tmp = [];
    for (var i = 0; i < familia.length; i++) {
      const object = {
        id: familia[i].id,
        cod_familia: familia[i].cod_familia,
        situacao: familia[i].situacao,
        responsavel: familia[i].responsavel.nome,
        renda: familia[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        tipo_leite: familia[i].tipo_leite,
        bairro: familia[i].endereco.bairro,
        municipio: familia[i].endereco.municipio.nome,
      };
      tmp.push(object);
    }
    return tmp;
  }

  function parseFamilias(familia: any[]) {
    let tmp = [];
    for (var i = 0; i < familia.length; i++) {
      const object = {
        id: familia[i].id,
        cod_familia: familia[i].cod_familia,
        situacao: familia[i].situacao,
        responsavel: familia[i].responsavel.nome,
        renda_percapita: familia[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        tipo_leite: familia[i].tipo_leite,
        bairro: familia[i].endereco.bairro,
        endereco: familia[i].endereco.id,
      };
      tmp.push(object);
    }
    return tmp;
  }

  function parsePontos(pontos: any[]) {
    let tmp = [];
    for (var i = 0; i < pontos.length; i++) {
      let calcVagas =
        pontos[i].cota_leite_bovino +
        pontos[i].cota_leite_caprino -
        pontos[i].familias_vinculadas;
      const object = {
        id: pontos[i].id,
        nome: pontos[i].nome,
        entidade: pontos[i].entidade.razao_social,
        vagas: calcVagas >= 0 ? calcVagas : 0,
      };
      tmp.push(object);
    }
    return tmp;
  }

  // Paginação

  useEffect(() => {
    loadFamilias(
      municipioSelecionado,
      bairroSelecionado,
      rendaSelecionada,
      posVinculacao
    );
  }, [offset, searchedValue]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback(
    (page: number) => {
      let newOffset = (page - 1) * itemsPerPage;
      setOffset(newOffset);
      setPage(page);
    },
    [page]
  );

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  useEffect(() => {
    const options: any = [];

    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );

    setSelectOptions(options);
  }, [municipios]);

  const toggleAlert = () => {
    setModalAlert(!modalAlert);
  };

  const togglePdf = () => {
    if (isGestor) setModalPdf(!modalPdf);
    else notify("warning", "Você não tem permissão para executar essa ação.");
  };

  const toggleCsv = () => {
    setCsvCheck(false);
  };

  return (
    <Layout>
      <MainContainer
        titlePage={
          municipioSelected < 0
            ? "Lista de famílias por município "
            : "Lista de famílias do município " +
              municipios[municipioSelected - 1]?.nome
        }
        iconPage={<FiUsers />}
      >
        <AlertModal
          isOpen={modalAlert}
          toggle={toggleAlert}
          className="modal-md"
          addAll={(quant: number) => addAll(quant)}
          msg={"Selecionar todas as famílias para vincular a um ponto?"}
          total={familias.length}
        />
        <PdfModal
          isOpen={modalPdf}
          toggle={togglePdf}
          toggleCsv={toggleCsv}
          csvCheck={csvCheck}
          className="modal-md"
          getFamilias={(startPage: number, endPage: number) =>
            getListaFamilias(startPage, endPage)
          }
          endPageDefault={Math.ceil(total / itemsPerPage)}
        />
        <DetalharModal
          isOpen={modalDetalhar}
          toggle={toggleDetalhar}
          reload={() =>
            loadFamilias(
              municipioSelecionado,
              bairroSelecionado,
              rendaSelecionada
            )
          }
          row={row}
          otherData={extraDataFamily}
        />
        <VincularModal
          municipio={municipioSelecionadoLabel}
          isOpen={modalVincular}
          toggle={toggleVincular}
          pontos={pontos.length > 0 ? pontos : []}
          selectPonto={selectPonto}
          className="modal-lg"
          vincularAoPonto={vincularFamiliasAoPonto}
          total={totalPontos}
          quantFamilias={
            selectedTodasFamilias.length
              ? selectedTodasFamilias.length
              : selectedFamilias.length
          }
          isVinculando={loading}
        />
        <Container>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching && <CustomSpinner2 />}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <Controller
                      rules={{ required: true }}
                      name="filtro_municipioSelect"
                      className={`${
                        errors.filtro_municipioSelect ? "error-input" : ""
                      }`}
                      control={control}
                      placeholder={"Selecione um município"}
                      options={selectOptions}
                      as={CustomReactSelect}
                      filterOption={createFilter({ ignoreAccents: false })}
                      noOptionsMessage={() => "Nenhum município encontrado"}
                    />
                    {errors.filtro_municipioSelect?.type === "required" && (
                      <span>Campo obrigatório</span>
                    )}
                  </div>
                  <div className="form-group col">
                    <label>Bairro</label>
                    <Controller
                      rules={{ required: false }}
                      name="filtro_bairroSelect"
                      className={`${
                        errors.filtro_bairroSelect ? "error-input" : ""
                      }`}
                      control={control}
                      defaultValue={""}
                      placeholder={"Ex: Planalto"}
                      as={Input}
                    />
                    {errors.filtro_bairroSelect?.type === "required" && (
                      <span>Campo obrigatório</span>
                    )}
                  </div>
                  <div className="form-group col">
                    <label>Renda per capita de até</label>
                    <Controller
                      className={`form-control ${
                        errors.filtro_rendaPerCapitaSelect ? "error-input" : ""
                      }`}
                      placeholder={"R$ 0,00"}
                      rules={{ required: true }}
                      as={NumberFormat}
                      control={control}
                      format={priceFormatter}
                      name="filtro_rendaPerCapitaSelect"
                      displayType="input"
                      inputMode="numeric"
                      defaultValue=""
                      isAllowed={(values: any) => {
                        const intVal = "".padStart(3, "9");
                        const decVal = "".padStart(2, "9");
                        const maxVal = parseFloat(`${intVal}.${decVal}`);
                        const { formattedValue, floatValue } = values;
                        return (
                          formattedValue === "" || floatValue / 100 <= maxVal
                        );
                      }}
                    />
                    {errors.filtro_rendaPerCapitaSelect?.type ===
                      "required" && <span>Campo obrigatório</span>}
                    {errors.filtro_rendaPerCapitaSelect?.type ===
                      "isNotANumber" && <span>Informe um valor númerico</span>}
                    {errors.filtro_rendaPerCapitaSelect?.type ===
                      "maiorOuIgualZero" && (
                      <span>Informe um valor válido</span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isSearching ? true : false}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>
          {(familias.length > 0 || searchedValue) && (
            <div className="local-search mt-4">
              <SearchBar
                placeholder="Responsável ou código familiar"
                submitHandler={search}
              />
            </div>
          )}
          {familias && (
            <Table
              fields={fields}
              rows={familias}
              functions={[{ name: "Detalhar", action: detalhar }]}
              hasSelection={true}
              hasSelectionAll={false}
              changeSelected={changeSelected}
              // addAll={addAll}
              removeAll={removeAll}
              isMultiple={true}
              clear={isFamiliasCleared}
              resetClear={resetClear}
            />
          )}
          {familias.length > 0 && (
            <>
              <div className="bottom-buttons">
                <Button
                  name={`Desfazer seleção
								${selectedFamilias.length > 0 ? "(" + selectedFamilias.length + ")" : ""}
								`}
                  iconButtom={<AiOutlineClose />}
                  color="grey"
                  onClick={clearFamilias}
                />
                {selectedFamilias.length > 0 && (
                  <div className="space">
                    <Button
                      name={`Vincular a um ponto ${
                        selectedFamilias.length > 0
                          ? "(" + selectedFamilias.length + ")"
                          : ""
                      }`}
                      iconButtom={<AiOutlineCheck />}
                      onClick={toggleVincular}
                      color="blue"
                    />
                  </div>
                )}
                <div className="space d-flex align-items-center">
                  <Button
                    name={"Vincular todas as famílias"}
                    iconButtom={<AiOutlineCheck />}
                    color="blue"
                    onClick={() => {
                      toggleAlert();
                    }}
                  />
                  {isVinculandoTodos && <CustomSpinner2 />}
                </div>
                <div className="space d-flex align-items-center">
                  <Button
                    onClick={() => {
                      if (!downloading) {
                        // setDownloading(true);
                        togglePdf();
                      }
                    }}
                    name={"Baixar PDF"}
                    color={downloading || !isGestor ? "grey" : "blue"}
                    iconButtom={<MdPictureAsPdf />}
                  />

                  <Button
                    onClick={() => {
                      if (!downloading) {
                        setCsvCheck(true);
                        togglePdf();
                      }
                    }}
                    name={"Baixar CSV"}
                    color={downloading || !isGestor ? "grey" : "blue"}
                    iconButtom={<FaFileCsv />}
                  />
                  {downloading && <CustomSpinner2 />}
                </div>
              </div>
            </>
          )}
          <div className="row">
            Visualizando {familias.length} de {total} registros no município
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Vincular;
