import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import InputMask from "react-input-mask";

import {
  FormContainer,
  PlusButton,
  RemoveButton,
  AddFormButton,
  SaveFormButton,
} from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container, customStyles } from "./../../styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { BsPlusCircleFill } from "react-icons/bs";
import { TiMinus } from "react-icons/ti";
import { FaRegEdit } from "react-icons/fa";

import api from "../../../../../services/api";
import { CNPJMask } from "../../../../../template/utils/masks";
import { parseDate2, parseCPF } from "../../../../../template/utils/parser";
import {
  LaticinioInterface,
  BancoInterface,
  ContratoInterface,
  GestorLaticinioInterface,
} from "../../../../../template/utils/types";
import { AiOutlinePlus } from "react-icons/ai";
import Select, { createFilter } from "react-select";

interface IProps {
  toggle: () => void;
  loadLaticinios: () => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, errors, control, setError, setValue } =
    useForm<LaticinioInterface>({
      mode: "onSubmit",
    });

  const { fields, append, remove } = useFieldArray<BancoInterface>({
    control,
    name: "bancos",
  });

  const {
    register: registerGestor,
    getValues: getValuesGestor,
    reset: resetGestor,
    errors: errorsGestor,
    clearErrors: clearErrorsGestor,
    trigger: triggerGestor,
    setValue: setValueGestor,
  } = useForm<GestorLaticinioInterface>({ mode: "onBlur" });

  const {
    fields: fieldsContrato,
    append: appendContrato,
    remove: removeContrato,
  } = useFieldArray<ContratoInterface, "fieldId">({
    control,
    name: "contratos",
    keyName: "fieldId",
  });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [gestorSelected, setGestorSelected] = useState<number>(-1);
  const [editarGestor, setEditarGestor] = useState<Boolean>(false);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [cpfGestor, setCpfGestor] = useState<any>("");
  const [telefoneGestor, setTelefoneGestor] = useState<any>("");
  const [gestorAtual, setGestorAtual] =
    useState<GestorLaticinioInterface>(Object);
  const [gestoresLaticinios, setGestoresLaticinios] = useState<
    GestorLaticinioInterface[]
  >([]);
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    if (gestorSelected !== -1) detailToUpdate();
  }, [gestorSelected]);

  function handleCPF(cpf: any) {
    setCpfGestor(
      cpf
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    );
  }

  function handleTelefone(tel: any) {
    setTelefoneGestor(
      tel
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "($1)$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1")
    );
  }

  function handleTelefoneLat(tel: any) {
    if (tel.length < 14) {
      setValue(
        "telefone",
        tel
          .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{2})(\d)/, "($1)$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{4})(\d)/, "$1-$2")
          .replace(/(-\d{4})\d+?$/, "$1")
      );
    } else {
      setValue(
        "telefone",
        tel
          .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{2})(\d)/, "($1)$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{5})(\d)/, "$1-$2")
          .replace(/(-\d{4})\d+?$/, "$1")
      );
    }
  }

  const addGestor = () => {
    if (gestorAtual.pessoa?.telefone.length > 14) {
      gestorAtual.pessoa.telefone = gestorAtual.pessoa.telefone.substring(
        0,
        14
      );
    }
    if (gestorAtual.documento?.numero.length > 11) {
      gestorAtual.documento.numero = gestorAtual.documento.numero
        .replace(/[.-]/g, "")
        .trim();
    }
    let listaGestoresLaticinios: GestorLaticinioInterface[] =
      gestoresLaticinios;
    if (editarGestor) {
      listaGestoresLaticinios[gestorSelected] = gestorAtual;
      setGestoresLaticinios(listaGestoresLaticinios);
    } else {
      listaGestoresLaticinios.push(gestorAtual);
      setGestoresLaticinios(listaGestoresLaticinios);
    }
    editarGestor
      ? notify("success", `Gestor editado com sucesso!`)
      : notify("success", `Gestor cadastrado com sucesso!`);
    resetGestor();
    setEditarGestor(false);
    setGestorSelected(-1);
    setCpfGestor("");
    setTelefoneGestor("");
    setFormVisible(false);
  };

  function removeGestor(index: number) {
    let listaGestoresLaticinios: GestorLaticinioInterface[] =
      gestoresLaticinios;
    listaGestoresLaticinios.splice(index, 1);
    setGestoresLaticinios(listaGestoresLaticinios);
    addFormGestor();
  }

  function addFormGestor() {
    setEditarGestor(false);
    setGestorSelected(-1);
    setFormVisible(true);
    setCpfGestor("");
    setTelefoneGestor("");
    resetGestor();
    setGestorAtual({
      pessoa: {
        nome: "",
        data_nascimento: new Date(),
        apelido: "",
        telefone: "",
      },
      usuario: {
        username: "",
        email: "",
        password: "",
        check_password: "",
      },
      documento: {
        numero: "",
        tipo: 1,
      },
      data_expiracao: new Date(
        new Date().setFullYear(new Date().getFullYear() + 2)
      ),
      tipo_usuario: 3,
    });
  }

  function validarEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //Preenche os campos do formulário do gestor laticínio com as informações do estado de gestorAtual
  const toggleFormState = () => {
    setCpfGestor(parseCPF(gestorAtual?.documento?.numero));
    setTelefoneGestor(gestorAtual?.pessoa?.telefone);
    setValueGestor("pessoa.nome", gestorAtual?.pessoa?.nome, {
      shouldDirty: true,
    });
    setValueGestor(
      "pessoa.data_nascimento",
      gestorAtual?.pessoa?.data_nascimento,
      { shouldDirty: true }
    );
    setValueGestor("pessoa.telefone", gestorAtual?.pessoa?.telefone, {
      shouldDirty: true,
    });
    setValueGestor("usuario.username", gestorAtual?.documento?.numero, {
      shouldDirty: true,
    });
    setValueGestor("usuario.email", gestorAtual?.usuario?.email, {
      shouldDirty: true,
    });
    setValueGestor("usuario.password", gestorAtual?.usuario?.password, {
      shouldDirty: true,
    });
    setValueGestor(
      "usuario.check_password",
      gestorAtual?.usuario?.check_password,
      {
        shouldDirty: true,
      }
    );
    setValueGestor("documento.numero", gestorAtual?.documento?.numero, {
      shouldDirty: true,
    });
  };
  /*
  const validarUser = async (user: any) => {
    let respostaLocal = false;
    for (let i = 0; i < gestoresLaticinios.length; i++) {
      if (
        gestoresLaticinios[i].usuario.username === user.toLowerCase() &&
        i !== gestorSelected
      ) {
        respostaLocal = true;
        break;
      }
    }
    let respostaApi = await api.get(`usuarios/?username=${user.toLowerCase()}`);
    if (respostaApi.data[0] === undefined && !respostaLocal) return true;
    else return false;
  };
*/
  const validarCPF = async (data: any, tipo: any) => {
    let dadoExistente = false;
    let cpf = {
      numero: data.replace(/[.-]/g, "").trim(),
      tipo: 1,
    };
    for (let i = 0; i < gestoresLaticinios.length; i++) {
      if (
        gestoresLaticinios[i].documento.numero.replace(/[.-]/g, "").trim() ===
          cpf.numero &&
        i !== gestorSelected
      ) {
        dadoExistente = true;
        break;
      }
    }
    let dado = await api.post(`documentos/valida/`, cpf);
    if (tipo === 1) {
      return dado.data.valido;
    } else if (dadoExistente) return false;
    else {
      return !dado.data.existente;
    }
  };

  const onSubmit = async (data: any) => {
    if (!formVisible) {
      data.endereco.numero = data.endereco.numero.replace(/\D/g, "");
      data.endereco.municipio = data.endereco.municipio.value;
      const laticinio: LaticinioInterface = data;
      laticinio.cnpj = laticinio.cnpj.replace(/[./-]/g, "").trim();
      laticinio.contratos = laticinio.contratos?.map((contrato) => {
        contrato.numero = contrato.numero.replace(/[.-]/g, "").trim();
        return contrato;
      });
      laticinio.bancos = laticinio.bancos?.map((banco) => {
        banco.agencia = banco.agencia.replace(/[.-]/g, "").trim();
        banco.conta = banco.conta.replace(/[.-]/g, "").trim();
        return banco;
      });
      // laticinio.gestores = gestoresLaticinios?.map((gestor) => {
      //   gestor.documento.numero = gestor.documento.numero
      //     .replace(/[.-]/g, "")
      //     .trim();
      //   gestor.usuario.username = gestor.documento.numero
      //     .replace(/[.-]/g, "")
      //     .trim();
      //   return gestor;
      // });
      // Cadastra as informações do laticínio
      api
        .post(`laticinios/`, laticinio)
        .then(function () {
          // Notifica que houve sucesso na edição
          notify("success", `Laticínio cadastrada com sucesso!`);
          // Atualiza a tabela
          props.loadLaticinios();
          //Fecha o modal
          props.toggle();
        })
        .catch(function (error) {
          if (laticinio.bancos === undefined) {
            notify("error", `Por favor, inserir dados bancários`);
          } else if (error.response.data.cnpj) {
            setError("cnpj", {
              type: "manual",
              message: error.response.data.cnpj[0],
            });
          } else if (error.response.data?.usuario?.username) {
            notify("error", `Usuário informado já existe`);
          } else if (error.response.data.numero) {
            notify("error", `CPF de gestor latícinio já cadastrado`);
          } else if (error.response.data.contratos) {
            notify("error", `Por favor, inserir dados contratuais`);
          } else if (error.response.data.cpf) {
            notify("error", `${error.response.data.cpf[0]}`);
          } else if (error.response.data.gestores) {
            notify("error", `${error.response.data.gestores}`);
          } else {
            notify("error", `Conta bancária informada já existe`);
          }
        });
    } else {
      notify("error", `Fechar formulário de gestor`);
    }
  };

  function detailToUpdate() {
    setEditarGestor(true);
    setFormVisible(true);
    toggleFormState();
  }

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
  }, [municipios]);

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <h6>Dados do Laticínio</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="nome">Nome</label>
              <input
                className={
                  errors.nome ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="Nome do Laticínio"
                name="nome"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres.
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="sigla">Sigla</label>
              <input
                className={
                  errors.sigla ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="Sigla"
                name="sigla"
                ref={register({ required: true })}
              />
              {errors.sigla?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="telefone">Telefone</label>
              <input
                type="text"
                className={
                  errors.telefone ? "CustomInput error-input" : "CustomInput"
                }
                name="telefone"
                placeholder="(99)99999-9999"
                ref={register({
                  required: true,
                  minLength: 13,
                })}
                onChange={(e: any) => {
                  handleTelefoneLat(e.currentTarget.value);
                }}
              />
              {errors.telefone?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.telefone?.type === "minLength" && (
                <span className="error-message">Mínimo 10 dígitos</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="cnpj">CNPJ</label>
              <Controller
                className={
                  errors.cnpj ? "CustomInput error-input" : "CustomInput"
                }
                as={InputMask}
                name="cnpj"
                placeholder="99.999.999/9999-99"
                control={control}
                mask={CNPJMask.mask}
                maskChar=""
                rules={{
                  required: true,
                  minLength: CNPJMask.maxLength,
                }}
              />
              {errors.cnpj?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.cnpj?.type === "minLength" && (
                <span className="error-message">CNPJ deve ter 14 dígitos</span>
              )}
              {errors.cnpj && (
                <span className="error-message">{errors.cnpj.message}</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className={
                  errors.email ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="exemplo@email.com"
                name="email"
                ref={register({
                  required: true,
                  validate: (value) => validarEmail(value) === true,
                })}
              />
              {errors.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.email?.type === "validate" && (
                <span className="error-message">Inserir email válido</span>
              )}
            </CustomInput>
          </div>

          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_expiracao">Data de expiração</label>
              <input
                type="date"
                className={
                  errors.data_expiracao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                defaultValue={parseDate2(
                  new Date(new Date().setFullYear(new Date().getFullYear() + 2))
                )}
                placeholder="dd/mm/aaaa"
                name="data_expiracao"
                ref={register({
                  required: true,
                  validate: (value) => value > parseDate2(new Date()),
                })}
              />
              {errors.data_expiracao?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.data_expiracao?.type === "validate" && (
                <span className="error-message">Inserir data futura</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.cep">CEP</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                maskChar=""
                placeholder="99999-999"
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">Deve conter 8 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                type="text"
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.rua"
                ref={register({ required: true })}
                placeholder={"Av Maria Lacerda"}
              />
              {errors.endereco?.rua?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.numero">Número</label>
              <input
                type="text"
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.numero"
                ref={register()}
                defaultValue="s/n"
                onChange={(e) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                type="text"
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.bairro"
                ref={register({ required: true })}
                placeholder={"Centro"}
              />
              {errors.endereco?.bairro?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.localidade.nome">Localidade</label>
              <input
                type="text"
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.localidade.nome"
                ref={register({ required: true })}
                placeholder={"Comunidade Maria"}
              />
              {errors.endereco?.localidade?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <div className="label">
              <label htmlFor="endereco.municipio">Município</label>
              <Controller
                name="endereco.municipio"
                placeholder={"Selecione um município"}
                rules={{ required: true }}
                control={control}
                className={errors.endereco?.municipio ? "error-input" : ""}
                as={Select}
                menuPlacement="top"
                options={municipiosOptions}
                styles={customStyles}
                noOptionsMessage={() => "Nenhum município encontrado"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              {/* <select
                name="endereco.municipio"
                className={
                  errors.endereco?.municipio
                    ? "CustomSelect error-input"
                    : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                <option value="">Selecione um município</option>
                {municipios.map((item) => (
                  <option value={parseInt(item.id)}>{item.nome}</option>
                ))}
              </select> */}
              {errors.endereco?.municipio && (
                <span className="error-message">Selecione um município</span>
              )}
            </div>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-sm">
            <h6>Contratos</h6>
          </div>
        </div>
        {fieldsContrato.map((contrato, index) => (
          <div className="row" key={contrato.fieldId}>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`contratos[${index}].numero`}>Número</label>
                <Controller
                  className={
                    errors.contratos?.[index]?.numero
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  as={InputMask}
                  name={`contratos[${index}].numero`}
                  control={control}
                  mask="9999999"
                  maskChar=""
                  rules={{ required: true }}
                />
                {errors.contratos?.[index]?.numero?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`contratos[${index}].ano`}>Ano</label>
                <Controller
                  className={
                    errors.contratos?.[index]?.ano
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  as={InputMask}
                  name={`contratos[${index}].ano`}
                  control={control}
                  mask="9999"
                  maskChar=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    validate: (value) =>
                      value.toString() <= new Date().getFullYear().toString(),
                  }}
                />
                {errors.contratos?.[index]?.ano?.type === "minLength" && (
                  <span className="error-message">Ano inválido</span>
                )}
                {errors.contratos?.[index]?.ano?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.contratos?.[index]?.ano?.type === "validate" && (
                  <span className="error-message">Proibida data futura</span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm-5">
              <CustomInput>
                <label htmlFor={`contratos[${index}].descricao`}>
                  Descrição (opcional)
                </label>
                <input
                  type="text"
                  className={
                    errors.contratos?.[index]?.descricao
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name={`contratos[${index}].descricao`}
                  maxLength={200}
                  placeholder="No máximo, 200 caracteres"
                  ref={register()}
                />
              </CustomInput>
              <input
                type="hidden"
                name={`contratos[${index}].laticinio`}
                ref={register()}
                defaultValue=""
              />
            </div>
            <div className="col-sm" style={{ marginTop: "1.15rem" }}>
              <RemoveButton
                type="button"
                onClick={() => {
                  removeContrato(index);
                }}
              >
                <TiMinus /> Remover
              </RemoveButton>
            </div>
          </div>
        ))}
        <PlusButton
          type="button"
          onClick={() => {
            appendContrato({
              numero: "",
              ano: "",
              descricao: "",
            });
          }}
        >
          <BsPlusCircleFill />
        </PlusButton>

        <hr />
        <div className="row">
          <div className="col-sm">
            <h6>Dados bancários</h6>
          </div>
        </div>
        {fields.map((banco, index) => (
          <div className="row" key={banco.id}>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`bancos[${index}].codigo`}>Código</label>
                <input
                  type="number"
                  className={
                    errors.bancos?.[index]?.codigo
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name={`bancos[${index}].codigo`}
                  ref={register({
                    required: true,
                    validate: (value) => value <= 32767,
                  })}
                />
                {errors.bancos?.[index]?.codigo?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.bancos?.[index]?.codigo?.type === "validate" && (
                  <span className="error-message">
                    Certifique-se de que este valor seja inferior ou igual a
                    32767.
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`bancos[${index}].nome`}>Banco</label>
                <input
                  type="text"
                  className={
                    errors.bancos?.[index]?.nome
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name={`bancos[${index}].nome`}
                  ref={register({
                    required: true,
                    maxLength: 64,
                  })}
                />
                {errors.bancos?.[index]?.nome?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.bancos?.[index]?.nome?.type === "maxLength" && (
                  <span className="error-message">
                    Certifique-se de que esse campo não tenha mais que 64
                    caracteres
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`bancos[${index}].agencia`}>Agência</label>
                <input
                  type="text"
                  className={
                    errors.bancos?.[index]?.agencia
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name={`bancos[${index}].agencia`}
                  ref={register({ required: true })}
                />
                {errors.bancos?.[index]?.agencia?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={`bancos[${index}].conta`}>Conta</label>
                <input
                  type="text"
                  className={
                    errors.bancos?.[index]?.conta
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name={`bancos[${index}].conta`}
                  ref={register({ required: true })}
                />
                {errors.bancos?.[index]?.conta?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </CustomInput>
              <input
                type="hidden"
                name={`bancos[${index}].laticinio`}
                ref={register()}
                defaultValue=""
              />
            </div>
            <div className="col-sm" style={{ marginTop: "1.15rem" }}>
              <RemoveButton
                type="button"
                onClick={() => {
                  remove(index);
                }}
              >
                <TiMinus /> Remover
              </RemoveButton>
            </div>
          </div>
        ))}

        <PlusButton
          type="button"
          onClick={() => {
            append({
              nome: "",
              agencia: "",
              conta: "",
            });
          }}
        >
          <BsPlusCircleFill />
        </PlusButton>
        <hr />
        <div className="row">
          <div className="col-sm">
            <h6>Gestores Laticínios</h6>
          </div>
        </div>
        <span>
          <div className="col-sm">
            {gestoresLaticinios?.map(
              (gestor: GestorLaticinioInterface, index) => (
                <ul className="list">
                  <li key={index}>
                    <span>{gestor.pessoa.nome}</span>
                    <div className="acaoGestor">
                      <div className="row">
                        <RemoveButton
                          type="button"
                          onClick={() => {
                            removeGestor(index);
                          }}
                        >
                          <TiMinus /> Remover
                        </RemoveButton>
                        <AddFormButton
                          type="button"
                          className="editarGestor"
                          onClick={() => {
                            clearErrorsGestor();
                            setGestorSelected(index);
                            setGestorAtual(gestoresLaticinios[index]);
                          }}
                        >
                          <FaRegEdit />{" "}
                          <span className="TextEditarParticipante">Editar</span>
                        </AddFormButton>
                      </div>
                    </div>
                  </li>
                </ul>
              )
            )}
          </div>
        </span>
        <div id="createForm" className="createForm">
          <AddFormButton type="button" onClick={addFormGestor}>
            <AiOutlinePlus /> Novo gestor
          </AddFormButton>
        </div>
        <hr
          style={{
            color: "blue",
            backgroundColor: "#0e4f9f",
            height: 1,
          }}
        />
      </FormContainer>

      <FormContainer id="FormGestor" hidden={!formVisible}>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.nome">Nome</label>
              <input
                type="text"
                className={
                  errorsGestor.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.nome"
                placeholder="Ex: João da Silva"
                ref={registerGestor({
                  required: true,
                  maxLength: 60,
                })}
                onChange={(e) =>
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      nome: e.currentTarget.value,
                    },
                  })
                }
              />
              {errorsGestor.pessoa?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.pessoa?.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres.
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.data_nascimento">Nascimento</label>
              <input
                type="date"
                className={
                  errorsGestor.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.data_nascimento"
                ref={registerGestor({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      data_nascimento: e.currentTarget.value,
                    },
                  })
                }
              />
              {errorsGestor.pessoa?.data_nascimento?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.pessoa?.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.email">Email</label>
              <input
                type="email"
                className={
                  errorsGestor.usuario?.email
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="usuario.email"
                placeholder="exemplo@email.com"
                ref={registerGestor({
                  required: true,
                  validate: (value) => validarEmail(value) === true,
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      email: e.currentTarget.value,
                    },
                  })
                }
              />
              {errorsGestor.usuario?.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.usuario?.email?.type === "validate" && (
                <span className="error-message">Inserir email válido</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.telefone">Telefone (Opcional)</label>
              <input
                type="text"
                className={
                  errorsGestor.pessoa?.telefone
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.telefone"
                placeholder="(99)99999-9999"
                ref={registerGestor({
                  required: false,
                  minLength: 14,
                })}
                value={telefoneGestor}
                onChange={(e: any) => {
                  handleTelefone(e.currentTarget.value);
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      telefone: e.currentTarget.value,
                    },
                  });
                }}
              />
              {errorsGestor.pessoa?.telefone?.type === "minLength" && (
                <span className="error-message">
                  Se preenchido, campo deve conter 11 dígitos
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.password">Senha</label>
              <input
                type="password"
                className={
                  errorsGestor.usuario?.password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="usuario.password"
                placeholder="Mínimo 6 dígitos"
                ref={registerGestor({
                  required: true,
                  minLength: 6,
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      password: e.currentTarget.value,
                    },
                  })
                }
              />
              {errorsGestor.usuario?.password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.usuario?.password?.type === "minLength" && (
                <span className="error-message">
                  A senha deve ter no mínimo 6 dígitos
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.check_password">Repetir senha</label>
              <input
                type="password"
                className={
                  errorsGestor.usuario?.check_password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                minLength={6}
                name="usuario.check_password"
                placeholder="Mínimo 6 dígitos"
                ref={registerGestor({
                  required: true,
                  validate: (value) =>
                    value === getValuesGestor("usuario.password"),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      check_password: e.currentTarget.value,
                    },
                  })
                }
              />
              {errorsGestor.usuario?.check_password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.usuario?.check_password?.type === "validate" && (
                <span className="error-message">
                  A senha de confirmação não é igual à senha digitada
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="documento.numero">CPF</label>
              <input
                type="text"
                className={
                  errorsGestor.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="documento.numero"
                placeholder="999.999.999-99"
                ref={registerGestor({
                  required: true,
                  minLength: 14,
                  validate: {
                    valido: (value) => validarCPF(value, 1),
                    unico: (value) => validarCPF(value, 2),
                  },
                })}
                value={cpfGestor}
                onChange={(e: any) => {
                  handleCPF(e.currentTarget.value);
                  setGestorAtual({
                    ...gestorAtual,
                    documento: {
                      ...gestorAtual.documento,
                      numero: e.currentTarget.value,
                    },
                  });
                }}
              />
              {errorsGestor.documento?.numero?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.documento?.numero?.type === "minLength" && (
                <span className="error-message">Deve conter 11 dígitos</span>
              )}
              {errorsGestor.documento?.numero?.type === "valido" && (
                <span className="error-message">CPF inválido</span>
              )}
              {errorsGestor.documento?.numero?.type === "unico" && (
                <span className="error-message">CPF já cadastrado</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_expiracao">Data de Expiração</label>
              <input
                type="date"
                className={
                  errorsGestor.data_expiracao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="data_expiracao"
                ref={registerGestor({
                  required: true,
                  validate: (value) => value > parseDate2(new Date()),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    data_expiracao: e.currentTarget.value,
                  })
                }
              />
              {errorsGestor.data_expiracao?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errorsGestor.data_expiracao?.type === "validate" && (
                <span className="error-message">Inserir data futura</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div id="saveForm" className="saveForm">
          <RemoveButton
            type="button"
            onClick={() => {
              resetGestor();
              setEditarGestor(false);
              setGestorSelected(-1);
              setCpfGestor("");
              setTelefoneGestor("");
              setFormVisible(false);
            }}
          >
            Cancelar
          </RemoveButton>
          <SaveFormButton
            type="button"
            onClick={async () => {
              const result = await triggerGestor();
              if (result) addGestor();
            }}
          >
            {editarGestor ? "Editar" : "Cadastrar"}
          </SaveFormButton>
        </div>
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
