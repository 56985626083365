import React from "react";
import Modal from "../../../../template/components/Modal";
import AlertForm from "../AlertForm";
import "./styles.ts";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../DetalharModal/styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  msg: string;
  addAll: (quant: number) => void;
  total: number;
}

const AlertModal = ({
  msg,
  isOpen,
  toggle,
  addAll,
  total,
  className,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="alertForm">
          <CustomAiOutlineCheck /> Confirmar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={false}
      modalTitle={"Quantas famílias você deseja vincular? (Obs: máx 500)"}
      footerContent={footerButtons()}
      className={className}
    >
      <div className="mt-2">
        <AlertForm addAll={(quant: number) => addAll(quant)} total={total} />
      </div>
    </Modal>
  );
};

export default AlertModal;
