import React, { useState } from "react";
import Loading from "../../../../components/Loading";
import Modal from "../../../../../template/components/Modal";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
  ButtonContentContainer,
} from "./styles";
import api from "../../../../../services/api";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { LoadingComponent } from "../../../../components/Loading/styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadLaticinios: () => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

const notify = (type: string, message: string) => dialogBox(type, message);

const AtivoModal = ({
  row,
  isOpen,
  toggle,
  loadLaticinios,
  isLoading,
}: IProps) => {
  const [isLoadingAtivo, setIsLoadingAtivo] = useState(false);

  const handleAlterarLaticinio = () => {
    setIsLoadingAtivo(true);
    const updatedDate = {
      ativo: !row.ativo,
    };
    api
      .patch(`laticinios/${row.id}/`, updatedDate)
      .then(function () {
        // Notifica que houve sucesso na edição
        console.log(row);
        !row.ativo
          ? notify("success", ` Laticínio ativado com sucesso!`)
          : notify("success", ` Laticínio desativado com sucesso!`);
        // Atualiza a tabela
        loadLaticinios();
        setIsLoadingAtivo(false);
        // Fecha o modal somente após a conclusão da requisição
        toggle();
      })
      .catch(function (error) {
        // Handle error here if the request fails
        console.error("Erro na requisição: ", error);
        setIsLoadingAtivo(false);
      });
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose} disabled={isLoadingAtivo}>
          <CustomAiOutlineClose /> Não
        </ButtonClose>
        <ButtonSave
          type="submit"
          onClick={handleAlterarLaticinio}
          disabled={isLoadingAtivo || isLoading}
        >
          <ButtonContentContainer>
            {isLoadingAtivo ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Sim</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  const handleClose = () => {
    if (!isLoadingAtivo) {
      toggle();
    }
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={!row.ativo ? "Ativar Laticínio" : "Desativar Laticínio"}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      {isLoading ? (
        <Loading
          heigthContainer="30px"
          heightLoading="20px"
          widthLoading="20px"
        />
      ) : !row.ativo ? (
        <h6>Você deseja ativar o Laticínio {row.nome}?</h6>
      ) : (
        <h6>Você deseja desativar o Laticínio {row.nome}?</h6>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default AtivoModal;
