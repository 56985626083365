import React, { useState } from "react";

import Modal from "../../../../template/components/Modal";

import DetalharForm from "../DetalharForm";

import { ButtonClose, CustomAiOutlineClose } from "./styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  reload: () => void;
  otherData: any;
  modificarObjeto: (id: number, obj: any) => void;
}

const DetalharModal = ({
  row,
  isOpen,
  toggle,
  reload,
  otherData,
  modificarObjeto,
}: IProps) => {
  const [wasModified, setWasModified] = useState<boolean>(false);

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle="Informações da família"
      footerContent={footerButtons()}
    >
      {/* {infoFamilia()} */}
      <DetalharForm
        row={row}
        otherData={otherData}
        toggle={toggle}
        reload={reload}
        modified={(b: boolean) => setWasModified(b)}
        modificarObjeto={(id: number, obj: any) => modificarObjeto(id, obj)}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default DetalharModal;
