import React from "react";
import Modal from "../../../../template/components/Modal";
import "./styles.ts";
import PdfForm from "../PdfForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../DetalharModal/styles";

interface IProps {
  isOpen: boolean;
  csvCheck: boolean;
  toggle: () => void;
  toggleCsv: () => void;
  className?: string;
  getFamilias: (start: number, end: number) => void;
  endPageDefault: number;
}

const PdfModal = ({
  isOpen,
  toggle,
  csvCheck,
  toggleCsv,
  className,
  getFamilias,
  endPageDefault,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose
          onClick={() => {
            toggle();
            toggleCsv();
          }}
        >
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="pdfForm">
          <CustomAiOutlineCheck />
          {csvCheck ? "Confirmar geração de CSV" : "Confirmar geração de PDF"}
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={false}
      modalTitle={
        csvCheck
          ? "Selecione o intervalo da paginação para gerar o csv da lista (Obs: Intervalo máximo 50)"
          : "Selecione o intervalo da paginação para gerar o pdf da lista (Obs: Intervalo máximo 50)"
      }
      footerContent={footerButtons()}
      className={className}
    >
      <div className="mt-2">
        <PdfForm
          endPageDefault={endPageDefault}
          getFamilias={(startPage: number, endPage: number) =>
            getFamilias(startPage, endPage)
          }
        />
      </div>
    </Modal>
  );
};

export default PdfModal;
