import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`

  .content-notifications {
    margin-left:12px;
    position: relative;
    display: inline-block;
  } 
`;
export const Badge  = styled.span`
    background-color: ${colors.redError};
    padding: 4px;
    border-radius: 4px;
    position: relative;
    top: 6px;
    right: 24px;
`;

export const Notifications = styled.ul`
    background: ${colors.light};
    width: 500px;
    margin:5px;
    height:auto;
    max-height: 240px;
    position: absolute;
    right: 0;
    box-shadow: inset 0px 4px 4px 0px #00000040;
    overflow-y: scroll;

    list-style:none;
    display:flex;
    flex-direction: column;

    .no-notifications{
      text-align: center;
    }

    ::-webkit-scrollbar {width: 6px;}
    ::-webkit-scrollbar-thumb {
        background: ${colors.darkGrey}; 
}


`;
export const NotificationNoItem = styled.li`
    color: #374C96;
    font-size: 14px;
    text-align: left;
    padding: 1em 1.5em;
    border-bottom: 1px solid #E1E1E1;

    span{
      font-weight:700;
    }
    p{
    margin: 0}
    :hover{
      background-color: ${colors.lightGrey};
      cursor:pointer;
    }

 
`;

