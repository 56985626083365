import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  CustomFaBars,
  CustomRiLogoutBoxLine,
  LogoutButton,
} from "./styles";

import { logout } from "../../../services/auth";

import logoEstado from "../../assets/icons/logo_estado.svg";


import CurrentUser from "../CurrentUser"
import Notification from "../Notification"
import currentUser from "../../utils/currentUser";
import { UsuarioGeralInterface } from "../../utils/types";

interface IProps {
  toggle: () => void;
}

const Header = ({ toggle }: IProps) => {
  const history = useHistory();
  const [user, setUser] = useState<UsuarioGeralInterface>();

  const handleLogout = () => {
    logout();
    history.push("/");
  };

  async function loadCurrentUser() {
    const usuarioInfo = await currentUser();
    setUser(usuarioInfo);
    return
  }

  useEffect(() => {
    loadCurrentUser();
  }, []);


  return (
    <Container>
      <div>
        <img src={logoEstado} alt="Logo do estado do RN" />
        <CustomFaBars onClick={toggle} />
        <a href="http://www.sethas.rn.gov.br/">Portal SETHAS</a>
        <a href="https://sei.rn.gov.br/">SEI</a>
      </div>

      <div>
        {user &&
          <>
            <CurrentUser />
            <Notification gestor_estadual={user?.is_gestor_estadual} />
          </>
        }
       
        <LogoutButton onClick={handleLogout}>
          Sair <CustomRiLogoutBoxLine className="inside" />
        </LogoutButton>
      </div>
     
    </Container>
  );
};

export default Header;
