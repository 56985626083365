import React, { useCallback, useEffect, useState } from "react";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import { FiUsers } from "react-icons/fi";
import { Container } from "./styles";
import { FamiliaReadInterface } from "../../../template/utils/types";
import { dialogBox } from "../../../template/utils/dialogBox";
import api from "../../../services/api";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import DetalharModal from "./components/DetalharModal";
import CadastrarModal from "./components/CadastrarModal";
import Button from "../../../template/components/Button";
import { GoPlus } from "react-icons/go";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Pagination from "../../../template/components/Pagination";
import { CustomSpinner } from "../Pontos/components/styles";

const Familias = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [firstEntry, setFirstEntry] = useState<boolean>(false);
  const [familia, setFamilia] = useState<any[]>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [familiaEditar, setFamiliaEditar] =
    useState<FamiliaReadInterface>(Object);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  // Cabeçalho da tabela
  const fields = [
    "Responsável",
    "Situação",
    "Renda per capita",
    "Ponto",
    "Municipio",

    "Ação",
  ];

  const loadFamilias = useCallback(async () => {
    setSearching(true);
    //Carrega as familias
    try {
      const { data } = await api
        .get(
          `familias/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=responsavel`
        )
        .finally(() => {
          setSearching(false);
        });
      const { results, count } = data;
      setFamilia(
        parseJsonToTableDataList(parseFamilias(results), [
          "responsavel",
          "situacao",
          "renda_percapita",
          "ponto",
          "municipio",
        ])
      );
      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
  }, [offset, searchedValue, notify]);

  useEffect(() => {
    if (firstEntry) {
      loadFamilias();
    } else setFirstEntry(true);
  }, [loadFamilias]);

  // Parse Gestores
  function parseFamilias(familias: any[]) {
    let tmp = [];

    for (var i = 0; i < familias.length; i++) {
      const object = {
        id: familias[i].id,
        responsavel: familias[i].responsavel.nome,
        situacao: familias[i].situacao,
        renda_percapita: familias[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        ponto: familias[i].ponto ? familias[i].ponto.nome : "Não Definido",
        municipio: familias[i].endereco.municipio.nome,
        /*renda_media: familias[i].renda_media.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),*/
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  //Paginação
  const itemsPerPage = 20;

  // Aumenta o total quando um gestor é criado
  /*   const increaseTotal = useCallback(() => {
    let newTotal = total + 1;
    setTotal(newTotal);
  }, [total]); */

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset;
    let newPage = page;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    let SV;
    if (parseInt(searchedValue)) {
      SV = parseInt(searchedValue);
    } else SV = searchedValue;

    setSearch(SV.toString());
    setOffset(0);
    setPage(1);
  }, []);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`familias/${row.id}/`);
    setFamiliaEditar(data);
    console.log(data);
    toggleDetalhar();
  };

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };
  return (
    <Layout>
      <MainContainer titlePage="Familias" iconPage={<FiUsers />}>
        <Container>
          {modalDetalhar ? (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              familiaSelecionada={familiaEditar}
              loadFamilias={loadFamilias}
            />
          ) : (
            ""
          )}
          {modalCadastrar ? (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              loadFamilias={loadFamilias}
            />
          ) : (
            ""
          )}
          <div className="row">
            <div>
              {/*
                  <Button
                    color="green"
                    name="Cadastrar Família"
                    iconButtom={<GoPlus />}
                    onClick={() => toggleCadastrar()}
                  />
              */}
            </div>
            <div
              className="searchBar space"
              style={{ display: "flex", alignItems: "center" }}
            >
              {searching && <CustomSpinner />}
              <SearchBar
                placeholder="Responsável ou Código familiar"
                submitHandler={search}
              />
            </div>
          </div>
          <Table
            fields={fields}
            rows={familia}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[{ name: "Visualizar", action: detalhar }]}
          />
          <div className="containerFooterTable">
            Visualizando {familia.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Familias;
