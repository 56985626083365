import styled from "styled-components";
import colors from "../../../template/styles/colors";
import arrow from "../../../template/assets/icons/arrow.png";

import { TiDeleteOutline } from "react-icons/ti";
import Select from "react-select";

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 5px;
  }

  .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    justify-content: flext-start;
    align-items: center;
  }

  .space {
    margin-left: 20px;
  }

  .space-row {
    margin-top: 18px;
  }

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  .searchBar {
    margin-right: 9px;
  }

  .list {
    list-style-type: none;
    font-size: 14px;
  }

  .list li {
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    align-items: center;
    display: flex;
    margin-top: 5px;
  }

  .containerFooterTable {
    display: flex;
    justify-content: space-between;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  .error-message {
    color: ${colors.redError};
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }

  .CustomSelect {
    height: 39px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid #c8ced3;
    /* border: 1px solid${colors.bluePLP}; */
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 35px 8px 8px;
    background: url(${arrow}) 95% / 10px no-repeat;
    background-color: ${colors.light};
  }

  h6 {
    font-weight: bold;
    color: ${colors.ultraDarkGrey};
  }

  .listaTitulo {
    font-size: 14px;
    font-weight: bolder;
    color: ${colors.ultraDarkGrey};
  }

  .list {
    list-style-type: none;
    font-size: 14px;
  }

  .list li {
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    height: 45px;
    padding-left: 10px;
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
  }

  .listItem {
    height: 20px;
    width: 100%;
  }

  .excluir {
    padding: 5px;
    background-color: ${colors.redError};
    color: ${colors.light};
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      color: ${colors.ultraDarkGrey};
    }
  }
`;

export const CustomIconDesvincular = styled(TiDeleteOutline)`
  font-size: 1.5rem;
  color: red;
`;

// Coloca estilo no componente ReactSelect
export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 39,
    height: 39,
    minWidth: 200,
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
    padding: "0 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: 0,
  }),

  indicatorSeparator: (state: any) => ({
    display: "none",
  }),

  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
  }),
};

export const CustomReactSelect = styled(Select)`
  width: 200px;
`;
