import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container } from "../../styles";
import Select, { createFilter } from "react-select";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import api from "../../../../../services/api";
import { CPFMask } from "../../../../../template/utils/masks";
import { parseDate2 } from "../../../../../template/utils/parser";
import { UsuarioGeralInterface } from "../../../../../template/utils/types";
import colors from "../../../../../template/styles/colors";
import { FaCheck } from "react-icons/fa";

interface IProps {
  toggle: () => void;
  loadUsuarios: () => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, getValues, errors, control, setError } =
    useForm({ mode: "onSubmit" });

  const [backgroundColor0, setBackgroundColor0] = useState<any>(
    colors.lightGrey
  );
  const [backgroundColor1, setBackgroundColor1] = useState<any>(
    colors.lightGrey
  );
  const [backgroundColor2, setBackgroundColor2] = useState<any>(
    colors.lightGrey
  );
  const [backgroundColor3, setBackgroundColor3] = useState<any>(
    colors.lightGrey
  );
  const funcoes = [
    { id: 0, nome: "Gestor Estadual" },
    { id: 1, nome: "Gestor Local" },
    { id: 2, nome: "Colaborador do Ponto" },
    { id: 3, nome: "Técnico Estadual" },
  ];
  const [opcaoChecked, setOpcaoChecked] = useState(
    new Array(funcoes.length).fill(false)
  );
  const [entidadesParceiras, setEntidadesParceiras] = useState<any[]>([]);
  const [entidadesOptions, setEntidadesOptions] = useState<any[]>([]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const handleOnChange = (posicao: number) => {
    const updatedOpcaoChecked = opcaoChecked.map((item, index) =>
      index === posicao ? !item : item
    );
    setOpcaoChecked(updatedOpcaoChecked);
  };

  useEffect(() => {
    const getEntidadesParceiras = async () => {
      const { data } = await api.get("entidades_cooperadas/");
      setEntidadesParceiras(data);
    };
    getEntidadesParceiras();
  }, []);

  useEffect(() => {
    const options: any = [];
    entidadesParceiras?.map((entidadeP) =>
      options.push({ value: entidadeP.id, label: entidadeP.razao_social })
    );
    setEntidadesOptions(options);
  }, [entidadesParceiras]);

  const onSubmit = async (data: any) => {
    if (
      opcaoChecked[0] === false &&
      opcaoChecked[1] === false &&
      opcaoChecked[2] === false &&
      opcaoChecked[3] === false
    ) {
      return notify("error", "O usuário deve possuir algum papel no sistema!");
    }
    if (data.matricula === "") {
      data.matricula = null;
    }
    if (opcaoChecked[1] === true) {
      data.documento.numero = data.documento.numero.replace(/[.-]/g, "").trim();
      data.username = data.documento.numero;
      data.entidade = data.entidade.value;
      delete data.apelido;
      const usuario: UsuarioGeralInterface = data;
      usuario.documento.tipo = 1;
      usuario.is_gestor_estadual = opcaoChecked[0];
      usuario.is_gestor_local = opcaoChecked[1];
      usuario.is_colaborador_ponto = opcaoChecked[2];
      usuario.is_tecnico_estadual = opcaoChecked[3];
      usuario.is_gestor_laticinio = false;
      usuario.is_funcionario_laticinio = false;
    }
    data.documento.numero = data.documento.numero.replace(/[.-]/g, "").trim();
    data.username = data.documento.numero;
    delete data.apelido;
    const usuario: UsuarioGeralInterface = data;
    usuario.documento.tipo = 1;
    usuario.is_gestor_estadual = opcaoChecked[0];
    usuario.is_gestor_local = opcaoChecked[1];
    usuario.is_colaborador_ponto = opcaoChecked[2];
    usuario.is_tecnico_estadual = opcaoChecked[3];
    usuario.is_gestor_laticinio = false;
    usuario.is_funcionario_laticinio = false;

    // Cadastra novo usuário
    api
      .post(`/usuarios/`, usuario)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Usuário cadastrado com sucesso!`);
        // Atualiza a tabela
        props.loadUsuarios();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.numero) {
          setError("documento.numero", {
            type: "manual",
            message: error.response.data.numero[0],
          });
        } else if (error.response.data.cpf) {
          notify("error", `${error.response.data.cpf[0]}`);
        } else if (error.response.data.matricula) {
          setError("matricula", {
            type: "manual",
            message: error.response.data.matricula,
          });
        } else if (error.response.data?.usuario?.email) {
          setError("email", {
            type: "manual",
            message: error.response.data.usuario.email[0],
          });
        } else if (error.response.data?.username) {
          notify("warning", `Usuário com o CPF informado já existe.`);
        } else {
          notify(
            "error",
            `Ocorreu um erro no cadastro, verifique o formulário`
          );
        }
      });
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.nome">Nome</label>
              <input
                className={
                  errors.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Nome completo da pessoa"
                name="pessoa.nome"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.pessoa?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.data_nascimento">Nascimento</label>
              <input
                type="date"
                className={
                  errors.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="01/01/2001"
                name="pessoa.data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
              />
              {errors.pessoa?.data_nascimento?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.telefone">Telefone (Opcional)</label>
              <Controller
                className={
                  errors.pessoa?.telefone
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="pessoa.telefone"
                control={control}
                mask="(99)99999-9999"
                maskChar=""
                placeholder="(99)99999-9999"
                rules={{ minLength: 14 }}
              />
              {errors.pessoa?.telefone?.type === "minLength" && (
                <span className="error-message">
                  Se preenchido, campo deve conter 11 dígitos
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className={
                  errors.email ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="exemplo@email.com"
                name="email"
                ref={register({
                  required: true,
                })}
              />
              {errors.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="documento">CPF</label>
              <Controller
                className={
                  errors.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="documento.numero"
                as={InputMask}
                placeholder="Ex.: 999.999.999-99"
                control={control}
                mask={CPFMask.mask}
                maskChar=""
                rules={{
                  required: true,
                  minLength: CPFMask.maxLength,
                }}
              />
              {errors.documento?.numero?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.documento?.numero?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 11 dígitos
                </span>
              )}
              {errors.documento?.numero && (
                <span className="error-message">
                  {errors.documento.numero.message}
                </span>
              )}
              {errors.documento?.cpf && (
                <span className="error-message">
                  {errors.documento.cpf.message}
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_expiracao">Data de expiração</label>
              <input
                type="date"
                className={
                  errors.data_expiracao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                defaultValue={parseDate2(
                  new Date(new Date().setFullYear(new Date().getFullYear() + 2))
                )}
                name="data_expiracao"
                ref={register({
                  required: true,
                  validate: (value) => value > parseDate2(new Date()),
                })}
              />
              {errors.data_expiracao?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.data_expiracao?.type === "validate" && (
                <span className="error-message">Inserir data futura</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        ></div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="password">Senha</label>
              <input
                type="password"
                className={
                  errors.password ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="Mínimo 6 digitos"
                name="password"
                ref={register({
                  required: true,
                  minLength: 6,
                })}
              />
              {errors.password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.password?.type === "minLength" && (
                <span className="error-message">
                  A senha deve ter no mínimo 6 dígitos
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="check_password">Repetir senha</label>
              <input
                type="password"
                className={
                  errors.check_password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Minimo 6 digitos"
                name="check_password"
                ref={register({
                  required: true,
                  minLength: 6,
                  validate: (value) => value === getValues("password"),
                })}
              />
              {errors.check_password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.check_password?.type === "validate" && (
                <span className="error-message">
                  A senha da confirmação não é igual à senha digitada
                </span>
              )}
              {errors.check_password?.type === "minLength" && (
                <span className="error-message">
                  A senha deve ter no mínimo 6 dígitos
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          {opcaoChecked[0] === true ? (
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="matricula">Matrícula (Opcional)</label>
                <input
                  type="text"
                  className={
                    errors.matricula ? "CustomInput error-input" : "CustomInput"
                  }
                  name="matricula"
                  ref={register({ maxLength: 20 })}
                  placeholder={"Ex.: 202021212"}
                />
                {errors.matricula?.type === "maxLength" && (
                  <span className="error-message">
                    Não deve ter mais que 20 caracteres
                  </span>
                )}
                {errors.matricula && (
                  <span className="error-message">Matrícula já existente</span>
                )}
              </CustomInput>
            </div>
          ) : (
            <></>
          )}

          {opcaoChecked[1] === true ? (
            <div className="col-sm">
              <label htmlFor="entidade">Entidade</label>
              <Controller
                name="entidade"
                placeholder={"Selecione uma entidade"}
                rules={{ required: true }}
                control={control}
                className={errors.entidade ? "error-input" : ""}
                as={Select}
                menuPlacement="bottom"
                options={entidadesOptions}
                noOptionsMessage={() => "Nenhuma entidade encontrada"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              {errors.entidade && (
                <span className="error-message">
                  É necessário selecionar uma entidade
                </span>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <hr />
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <h6 style={{ fontWeight: "bold" }}>
                Selecione papéis para o usuário:
              </h6>
              <div
                className="CheckBoxContainer-0"
                style={{ backgroundColor: backgroundColor0 }}
                onClick={(e) => {
                  handleOnChange(0);
                  backgroundColor0 === colors.lightGrey
                    ? setBackgroundColor0("lightGreen")
                    : setBackgroundColor0(colors.lightGrey);
                }}
              >
                <input
                  type="checkbox"
                  className="checkbox-input"
                  name="is_gestor_estadual"
                  value={opcaoChecked[0]}
                  checked={opcaoChecked[0]}
                />
                <label htmlFor="is_gestor_estadual">Gestor Estadual</label>
                {opcaoChecked[0] === true ? (
                  <FaCheck className="white-check" />
                ) : (
                  <></>
                )}
              </div>
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <div
                className="CheckBoxContainer-1"
                style={{ backgroundColor: backgroundColor1 }}
                onClick={(e) => {
                  handleOnChange(1);
                  backgroundColor1 === colors.lightGrey
                    ? setBackgroundColor1("lightGreen")
                    : setBackgroundColor1(colors.lightGrey);
                }}
              >
                <input
                  type="checkbox"
                  className="checkbox-input"
                  name="is_gestor_local"
                  value={opcaoChecked[1]}
                  checked={opcaoChecked[1]}
                />
                <label htmlFor="is_gestor_local">Gestor Local</label>
                {opcaoChecked[1] === true ? (
                  <FaCheck className="white-check" />
                ) : (
                  <></>
                )}
              </div>
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <div
                className="CheckBoxContainer-2"
                style={{ backgroundColor: backgroundColor2 }}
                onClick={(e) => {
                  handleOnChange(2);
                  backgroundColor2 === colors.lightGrey
                    ? setBackgroundColor2("lightGreen")
                    : setBackgroundColor2(colors.lightGrey);
                }}
              >
                <input
                  type="checkbox"
                  className="checkbox-input"
                  name="is_colaborador_ponto"
                  value={opcaoChecked[2]}
                  checked={opcaoChecked[2]}
                />
                <label htmlFor="is_colaborador_ponto">
                  Colaborador do Ponto
                </label>
                {opcaoChecked[2] === true ? (
                  <FaCheck className="white-check" />
                ) : (
                  <></>
                )}
              </div>
            </CustomInput>
          </div>
        </div>

        {/* <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <div
                className="CheckBoxContainer-2"
                style={{ backgroundColor: backgroundColor3}}
                onClick={(e) => {
                  handleOnChange(3);
                  backgroundColor3 === colors.lightGrey
                    ? setBackgroundColor3("lightGreen")
                    : setBackgroundColor3(colors.lightGrey);
                }}
              >
                <input
                  type="checkbox"
                  className="checkbox-input"
                  name="is_tecnico_estadual"
                  value={opcaoChecked[3]}
                  checked={opcaoChecked[3]}
                />
                <label htmlFor="is_tecnico_estadual">
                  Técnico Estadual
                </label>
                {opcaoChecked[3] === true ? (
                  <FaCheck className="white-check" />
                ) : (
                  <></>
                )}
              </div>
            </CustomInput>
          </div>
        </div> */}
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
