import React from "react";
import Modal from "../../../../../template/components/Modal";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import VincularForm from "../VincularForm";
import { createGlobalStyle } from "styled-components";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  vincularProdutor: (row: any, data: any) => void;
}

interface IStyle {
  modalOpen: boolean;
}

// Retira a barra de rolagem do body após fechar o modal, mas mantém a barra com o modal aberto
const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

const VincularModal = ({ row, isOpen, toggle, vincularProdutor }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="confirmarForm">
          <CustomAiOutlineCheck /> Confirmar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={"Confirmar Vinculação"}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <VincularForm
        toggle={toggle}
        row={row}
        vincularProdutor={vincularProdutor}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default VincularModal;
