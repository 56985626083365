import React, { useCallback, useEffect, useState } from "react";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import Table from "../../../template/components/Table";
import SearchBar from "../../../template/components/SearchBar";
import Pagination from "../../../template/components/Pagination";

import { FaRegBuilding } from "react-icons/fa";
import { GoPlus } from "react-icons/go";

import { parseJsonToTableDataList } from "../../../template/utils/parser";

import api from "../../../services/api";

import { Container, CustomReactSelect } from "./styles";

import CadastrarModal from "./components/CadastrarModal";
import EditarModal from "./components/EditarModal";
import {
  LaticinioInterface,
  MunicipioInterface,
  PontoInterface,
} from "../../../template/utils/types";
import { dialogBox } from "../../../template/utils/dialogBox";
import { createFilter } from "react-select";

const Pontos = () => {
  const [pontos, setPontos] = useState<any[]>([]);
  const [municipios, setMunicipios] = useState<MunicipioInterface[]>([]);
  const [laticinios, setLaticinios] = useState<LaticinioInterface[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(0);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [pontoEditar, setPontoEditar] = useState<PontoInterface>(Object);
  const [selectOptions, setSelectOptions] = useState<any>([]);

  const itemsPerPage = 20;

  const fields = [
    "Nome",
    "Endereço",
    "Cota bovino",
    "Cota caprino",
    "Laticínio",
    "Ação",
  ];

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada = `pontos/?limit=${itemsPerPage}&offset=${offset}&municipio=${municipioSelected}&search=${searchedValue}&ordering=-updated_at`;

        //Retorna todos os pontos cadastrados
        if (municipioSelected === 0) {
          chamada = `pontos/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`;
        }
        const { data } = await api.get(chamada);
        const { count, results } = data;

        setPontos(
          parseJsonToTableDataList(parsePontos(results), [
            "nome",
            "endereco",
            "cota_leite_bovino",
            "cota_leite_caprino",
            "laticinio",
          ])
        );
        setTotal(count);
      } catch (error) {
        notify("error", "Erro no servidor");
      }
    };
    loadPontos();
    console.log(municipioSelected);
  }, [offset, total, searchedValue, municipioSelected, modalDetalhar, notify]);

  useEffect(() => {
    (async function () {
      //Carrega os laticínios da API
      const { data } = await api.get("laticinios/");
      setLaticinios(data);
    })();

    (async function () {
      //Carrega os municópios da API
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    })();
  }, []);

  // Aumenta o total quando um ponto é criada
  const increaseTotal = useCallback(() => {
    let newTotal = total + 1;
    setTotal(newTotal);
  }, [total]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  function parsePontos(pontos: any[]) {
    let tmp = [];
    for (var i = 0; i < pontos.length; i++) {
      const object = {
        id: pontos[i].id,
        nome: pontos[i].nome,
        endereco:
          pontos[i].endereco.rua +
          (pontos[i].endereco.numero
            ? ", " + pontos[i].endereco.numero
            : " S/N") +
          ", " +
          pontos[i].endereco.bairro +
          " - " +
          pontos[i].endereco.municipio.nome,
        cota_leite_bovino: pontos[i].cota_leite_bovino,
        cota_leite_caprino: pontos[i].cota_leite_caprino,
        laticinio: pontos[i].laticinio.nome,
      };
      tmp.push(object);
    }
    console.log("tmp parsePontos: ", tmp);
    return tmp;
  }

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`pontos/${row.id}/`);
    setPontoEditar(data);
    toggleDetalhar();
  };

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  useEffect(() => {
    const options: any = [{ label: "Todos", value: 0 }];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setSelectOptions(options);
  }, [municipios]);

  return (
    <Layout>
      <MainContainer
        titlePage="Pontos de Distribuição do Leite"
        iconPage={<FaRegBuilding />}
      >
        <Container>
          <CadastrarModal
            increaseTotal={increaseTotal}
            isOpen={modalCadastrar}
            toggle={toggleCadastrar}
            className="modal-lg"
            municipios={municipios}
            laticinios={laticinios}
          />
          {modalDetalhar ? (
            <EditarModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              className="modal-lg"
              municipioSelecionado={municipioSelected}
              pontoEditar={pontoEditar}
              municipios={municipios}
              laticinios={laticinios}
            />
          ) : (
            ""
          )}
          <div className="row">
            <div className="inputContainer">
              <Button
                color="green"
                name="Cadastrar ponto"
                iconButtom={<GoPlus />}
                onClick={toggleCadastrar}
              />
              <CustomReactSelect
                name="municipiosSelect"
                className="space"
                placeholder="Selecione um município"
                options={selectOptions}
                onChange={(e: any) => {
                  setMunicipioSelected(e.value);
                  first();
                }}
                noOptionsMessage={() => "Nenhum município encontrado"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
            <div className="searchBar space">
              <SearchBar submitHandler={search} />
            </div>
          </div>
          <Table
            fields={fields}
            rows={pontos}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[{ name: "     Editar", action: detalhar }]}
          />
          <div className="containerFooterTable">
            Visualizando {pontos.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Pontos;
