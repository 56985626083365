import React, { useCallback, useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { TiUserDeleteOutline } from "react-icons/ti";
import { createFilter } from "react-select";
import api from "../../../services/api";
import Layout from "../../../template/components/Layout";
import MainContainer from "../../../template/components/MainContainer";
import Pagination from "../../../template/components/Pagination";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import { dialogBox } from "../../../template/utils/dialogBox";
import {
  parseCNPJ,
  parseCPF,
  parseDate2,
  parseJsonToTableDataList,
  parseTelefone,
} from "../../../template/utils/parser";
import {
  FamiliaReadInterface,
  MunicipioInterface,
  PontoInterface,
} from "../../../template/utils/types";
import { Container, CustomSpinner2 } from "./styles";
import { MdPictureAsPdf } from "react-icons/md";
import { FaFileCsv } from "react-icons/fa";
import { json2csv } from "json-2-csv";
import { useForm } from "react-hook-form";
import { CustomInput } from "../../../template/styles/styles";
import DocumentPDF from "./components/DocumentPDF";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import Button from "../../../template/components/Button";
import PdfModal from "./components/PdfModal";

const RelatorioDoacoes = () => {
  const { register, handleSubmit, clearErrors, getValues, setValue, errors } =
    useForm<any>();

  const [doacao, setDoacao] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [municipioSelected, setMunicipioSelected] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [totalBovino, setTotalBovino] = useState(0);
  const [totalCaprino, setTotalCaprino] = useState(0);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [municipios, setMunicipios] = useState<MunicipioInterface[]>([]);
  const [pontos, setPontos] = useState<any[]>([]);
  const [selectedPonto, setSelectedPonto] = useState<number>(0);
  const [totalPontos, setTotalPontos] = useState<number>(0);
  const [pontosOptions, setPontosOptions] = useState<any[]>([]);
  const [pontosFiltrado, setPontosFiltrado] = useState<any>([]);
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [listaDoacoes, setListaDoacoes] = useState<any>([]);
  const [csvCheck, setCsvCheck] = useState<boolean>(false);
  const [tipoDoacao, setTipoDoacao] = useState<number>(1);
  const [totalBovinoRelatorio, setTotalBovinoRelatorio] = useState(0);
  const [totalCaprinoRelatorio, setTotalCaprinoRelatorio] = useState(0);
  const [agruparCheck, setAgruparCheck] = useState<boolean>(false);
  const itemsPerPage = 10;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  // Cabeçalho da tabela
  const fields = [
    "Nome",
    "CPF/CNPJ",
    "Telefone",
    "Qtd bovino",
    "Qtd caprino",
    "Ponto",
  ];

  useEffect(() => {
    (async function () {
      try {
        const municipiosRes = api.get("municipios/");
        const [municipioRes] = await Promise.all([municipiosRes]);
        setMunicipios(municipioRes.data);
      } catch (err) {
        notify("error", "Ocorreu um erro no servidor");
      }
    })();
  }, []);

  useEffect(() => {
    loadPontos();
    clearErrors("pontoSelect");
  }, [municipioSelected]);

  const loadPontos = async () => {
    const { data } = await api.get(`pontos/?municipio=${municipioSelected}`);
    const { count, results } = data;
    setPontos(
      parseJsonToTableDataList(parsePontos(results), [
        "nome",
        "entidade",
        "vagas",
      ])
    );
    setTotalPontos(count);
  };

  const getDoacoesData = async () =>
  {
    let dataInicial = getValues("dataInicial");
    let dataFinal = getValues("dataFinal");
    let totalBov = 0;
    let totalCap = 0;

    const { data } =
    tipoDoacao == 1
    ? await api.get(
        `doacoes_pessoas_fisicas/?limit=&offset=0&municipio=${municipioSelected}
    &ponto=${
      selectedPonto == 0 ? "" : selectedPonto
    }&colaborador_ponto=&search=${searchedValue}
    &data_inicial=${dataInicial}&data_final=${dataFinal}&cpf=&tipo=&ordering=-updated_at`
      )
    : await api.get(
        `doacoes_entidades_beneficiarias/?limit=&offset=0&municipio=${municipioSelected}
    &ponto=${
      selectedPonto == 0 ? "" : selectedPonto
    }&colaborador_ponto=&search=${searchedValue}
    &data_inicial=${dataInicial}&data_final=${dataFinal}&entidade_beneficiaria=&tipo=&ordering=-updated_at`
    );
    
    data.forEach((value:any) => {
      totalBov += value.quantidade_leite_bovino
      totalCap += value.quantidade_leite_caprino
    });
   
    setTotalBovino(totalBov);
    setTotalCaprino(totalCap);
  }

  const loadDoacoes = async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      if (municipioSelected == 0 || !dataInicial || !dataFinal) return;
      let response: any = [];
      const { data } =
        tipoDoacao == 1
          ? await api.get(
              `doacoes_pessoas_fisicas/?limit=${itemsPerPage}&offset=${offset}&municipio=${municipioSelected}
          &ponto=${
            selectedPonto == 0 ? "" : selectedPonto
          }&colaborador_ponto=&search=${searchedValue}
          &data_inicial=${dataInicial}&data_final=${dataFinal}&cpf=&tipo=&ordering=-updated_at`
            )
          : await api.get(
              `doacoes_entidades_beneficiarias/?limit=${itemsPerPage}&offset=${offset}&municipio=${municipioSelected}
          &ponto=${
            selectedPonto == 0 ? "" : selectedPonto
          }&colaborador_ponto=&search=${searchedValue}
          &data_inicial=${dataInicial}&data_final=${dataFinal}&entidade_beneficiaria=&tipo=&ordering=-updated_at`
            );

      const { results, count } = data;

      await getDoacoesData();

      response = results;
      setTotal(count);
      tipoDoacao == 1
        ? setDoacao(
            parseJsonToTableDataList(parseDoacoesPF(response), [
              "nome",
              "documento",
              "telefone",
              "quantidade_leite_bovino",
              "quantidade_leite_caprino",
              "ponto",
            ])
          )
        : setDoacao(
            parseJsonToTableDataList(parseDoacoesEB(response), [
              "nome",
              "documento",
              "telefone",
              "quantidade_leite_bovino",
              "quantidade_leite_caprino",
              "ponto",
            ])
          );

      setPontosFiltrado(pontos.filter((ponto) => ponto.id !== selectedPonto));
      setIsSearching(false);
      if (count === 0) {
        notify("warning", "Sem doações");
        return;
      }
    } catch (error) {
      setIsSearching(false);
      notify("error", "Erro no servidor");
    }
  };

  useEffect(() => {
    loadDoacoes();
  }, [offset, searchedValue]);

  // Parse Doações para pessoas físicas
  function parseDoacoesPF(doacoes: any[]) {
    let tmp = [];
    for (var i = 0; i < doacoes.length; i++) {
      const object = {
        id: doacoes[i].id,
        nome: doacoes[i].nome,
        documento: parseCPF(doacoes[i].cpf),
        telefone: doacoes[i].telefone
          ? parseTelefone(doacoes[i].telefone)
          : "NÃO CADASTRADO",
        quantidade_leite_bovino: doacoes[i].quantidade_leite_bovino,
        quantidade_leite_caprino: doacoes[i].quantidade_leite_caprino,
        ponto: doacoes[i].ponto.nome,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  // Parse Doações para entidades beneficiárias
  function parseDoacoesEB(doacoes: any[]) {
    let tmp = [];
    for (var i = 0; i < doacoes.length; i++) {
      const object = {
        id: doacoes[i].id,
        nome: doacoes[i].entidade_beneficiaria.razao_social,
        documento: doacoes[i].entidade_beneficiaria.cnpj
          ? parseCNPJ(doacoes[i].entidade_beneficiaria.cnpj)
          : "NÃO CADASTRADO",
        telefone: doacoes[i].entidade_beneficiaria.telefone
          ? parseTelefone(doacoes[i].entidade_beneficiaria.telefone)
          : "NÃO CADASTRADO",
        quantidade_leite_bovino: doacoes[i].quantidade_leite_bovino,
        quantidade_leite_caprino: doacoes[i].quantidade_leite_caprino,
        ponto: doacoes[i].ponto.nome,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  function parsePontos(pontos: any[]) {
    let tmp = [];
    for (var i = 0; i < pontos.length; i++) {
      let calcVagas =
        pontos[i].cota_leite_bovino +
        pontos[i].cota_leite_caprino -
        pontos[i].familias_vinculadas;
      const object = {
        id: pontos[i].id,
        nome: pontos[i].nome,
        entidade: pontos[i].entidade,
        vagas: calcVagas >= 0 ? calcVagas : 0,
      };
      tmp.push(object);
    }
    return tmp;
  }

  const getListaDoacoes = async (startPage: number, endPage: number) => {
    if (municipioSelected > 0) {
      const start = Number(startPage);
      const end = Number(endPage);
      if (
        start > Math.ceil(total / itemsPerPage) ||
        end > Math.ceil(total / itemsPerPage)
      ) {
        notify(
          "error",
          "Um dos valores informados é maior que o valor final da paginação da lista"
        );
        return;
      }

      setStartPage(startPage);
      setEndPage(endPage);

      const calcEndPage = end - start;
      togglePdf();
      setDownloading(true);
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");

      try {
        const {
          data: { results },
        } = await api.get(
          `${
            tipoDoacao == 1
              ? "doacoes_pessoas_fisicas"
              : "doacoes_entidades_beneficiarias"
          }/${agruparCheck ? "relatorio_agrupado/" : ""}?limit=${
            calcEndPage === 0
              ? itemsPerPage
              : calcEndPage * itemsPerPage + itemsPerPage
          }&offset=${
            (startPage - 1) * itemsPerPage
          }&municipio=${municipioSelected}&ponto=${
            selectedPonto == 0 ? "" : selectedPonto
          }&colaborador_ponto=&search=${searchedValue}&data_inicial=${
            !agruparCheck ? dataInicial : ""
          }&data_final=${
            !agruparCheck ? dataFinal : ""
          }&cpf=&tipo=&ordering=-updated_at`
        );

        //console.log(results)

        let parsedData;
        if (agruparCheck) {
          parsedData = await parseDoacoesEntidadesEFamilias(results);
        } else {
          parsedData =
            tipoDoacao == 1
              ? parseDoacoesDocumentPF(results)
              : parseDoacoesDocumentEB(results);
        }

        setListaDoacoes(parsedData);
        setDownloading(false);
      } catch (err) {
        notify("error", "Ocorreu um erro ao obter a lista de doações para pdf");
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (listaDoacoes.length !== 0) {
      setDownloading(true);
      csvCheck === true ? createCSV() : createPDF();
    }
  }, [listaDoacoes]);

  const createPDF = async () => {
    try {
      let pontoNome = "";
      let municipioNome = municipios?.find(
        (item) => item.id == municipioSelected
      )?.nome;
      if (selectedPonto > 0) {
        pontoNome = pontos?.find((item) => item.id == selectedPonto)?.nome;
      }
      const blob = await pdf(
        <DocumentPDF
          data={listaDoacoes}
          totalBovino={totalBovinoRelatorio}
          totalCaprino={totalCaprinoRelatorio}
          count={total}
          municipio={municipioNome ? municipioNome : ""}
          ponto={pontoNome}
          selectedPonto={selectedPonto}
          startPage={startPage}
          endPage={endPage}
          agregado={agruparCheck}
          tipoDoacao={tipoDoacao}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      FileSaver.saveAs(blob, `doacoes_${municipioNome}_${datenow}.pdf`);

      setDownloading(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
    }
  };

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        listaDoacoes,
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "relatorio_doacoes.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
      setCsvCheck(false);
    }
  };

  useEffect(() => {
    loadDoacoes();
  }, [offset, searchedValue, notify]);

  function parseDoacoesDocumentPF(doacoes: any[]) {
    let tmp: any[] = [];
    let qtdBov = 0;
    let qtdCap = 0;
    doacoes.map((doacao: any) => {
      const object = {
        nome: doacao.nome,
        documento: parseCPF(doacao.cpf),
        telefone: doacao.telefone
          ? parseTelefone(doacao.telefone)
          : "NÃO CADASTRADO",
        quantidade_leite_bovino: doacao.quantidade_leite_bovino,
        quantidade_leite_caprino: doacao.quantidade_leite_caprino,
        ponto: doacao.ponto.nome,
      };
      qtdBov += doacao.quantidade_leite_bovino;
      qtdCap += doacao.quantidade_leite_caprino;
      tmp.push(object);
    });
    setTotalBovinoRelatorio(qtdBov);
    setTotalCaprinoRelatorio(qtdCap);
    return tmp;
  }

  function parseDoacoesDocumentEB(doacoes: any[]) {
    let tmp: any[] = [];
    let qtdBov = 0;
    let qtdCap = 0;
    doacoes.map((doacao: any) => {
      const object = {
        nome: doacao.entidade_beneficiaria.razao_social,
        documento: parseCNPJ(doacao.entidade_beneficiaria.cnpj),
        telefone: doacao.entidade_beneficiaria.telefone
          ? parseTelefone(doacao.entidade_beneficiaria.telefone)
          : "NÃO CADASTRADO",
        quantidade_leite_bovino: doacao.quantidade_leite_bovino,
        quantidade_leite_caprino: doacao.quantidade_leite_caprino,
        ponto: doacao.ponto.nome,
      };
      qtdBov += doacao.quantidade_leite_bovino;
      qtdCap += doacao.quantidade_leite_caprino;
      tmp.push(object);
    });
    setTotalBovinoRelatorio(qtdBov);
    setTotalCaprinoRelatorio(qtdCap);
    return tmp;
  }

  async function parseDoacoesEntidadesEFamilias(doacoes: any[]) {
    let tmp: any[] = [];

    let qtdBov = 0;
    let qtdCap = 0;
    let dataInicial = getValues("dataInicial");
    let dataFinal = getValues("dataFinal");

    for (let i = 0; i < doacoes.length; i++) {
      let res: any;
      let quantidade_leite_bovino = 0;
      let quantidade_leite_caprino = 0;
      let quantidade_doacoes = 0;

      await api
        .get(
          `${
            tipoDoacao == 1
              ? "doacoes_pessoas_fisicas"
              : "doacoes_entidades_beneficiarias"
          }/?limit=&offset=&municipio=${municipioSelected}&ponto=${
            selectedPonto == 0 ? "" : selectedPonto
          }&colaborador_ponto=&search=${searchedValue}&data_inicial=${dataInicial}&data_final=${dataFinal}&cpf=${
            doacoes[i].cpf
          }&tipo=&ordering=-updated_at`
        )
        .then((response) => {
          res = response;
        });

      res.data.map((res: any) => {
        quantidade_leite_bovino += res.quantidade_leite_bovino;
        quantidade_leite_caprino += res.quantidade_leite_caprino;
        quantidade_doacoes++;
      });

      const object =
        tipoDoacao == 1
          ? {
              nome: doacoes[i].nome,
              cpf: doacoes[i].cpf,
              quantidade_leite_bovino: quantidade_leite_bovino,
              quantidade_leite_caprino: quantidade_leite_caprino,
              quantidade_doacoes: quantidade_doacoes,
            }
          : {
              entidade_beneficiaria: doacoes[i].entidade_beneficiaria,
              quantidade_leite_bovino: quantidade_leite_bovino,
              quantidade_leite_caprino: quantidade_leite_caprino,
              quantidade_doacoes: quantidade_doacoes,
            };

      tmp.push(object);

      qtdBov += doacoes[i].quantidade_leite_bovino;
      qtdCap += doacoes[i].quantidade_leite_caprino;
    }
    setTotalBovinoRelatorio(qtdBov);
    setTotalCaprinoRelatorio(qtdCap);
    console.log(tmp);
    return tmp;
  }

  //Paginação

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    setSearchedValue(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const onSubmit = () => {
    setIsSearching(true);
    if (searchedValue) {
      setSearchedValue("");
    } else {
      // Se página for igual a 1, apenas executar a chamada de loadDoacoes sem resetar paginação para a primeira página
      if (page === 1) {
        loadDoacoes();
      } else {
        first();
        loadDoacoes();
      }
    }
  };

  const togglePdf = () => {
    setModalPdf(!modalPdf);
  };

  const toggleCsv = () => {
    setCsvCheck(false);
  };

  return (
    <Layout>
      <MainContainer
        titlePage="Lista de doações por ponto"
        iconPage={<FiUsers />}
      >
        <PdfModal
          isOpen={modalPdf}
          toggle={togglePdf}
          toggleCsv={toggleCsv}
          csvCheck={csvCheck}
          className="modal-md"
          getListaDoacoes={(startPage: number, endPage: number) =>
            getListaDoacoes(startPage, endPage)
          }
          endPageDefault={Math.ceil(total / itemsPerPage)}
        />
        <Container>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching && <CustomSpinner2 />}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomInput>
                      <select
                        name="municipiosSelect"
                        className={
                          errors.municipiosSelect
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Selecione um município"
                        ref={register({
                          required: true,
                          validate: (value) => value > 0,
                        })}
                        onChange={(e: any) => {
                          setMunicipioSelected(e.currentTarget.value);
                          setValue("pontoSelect", 0);
                          setSelectedPonto(0);
                          first();
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={0}>Selecione um Município</option>
                        {municipios.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.municipiosSelect?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.municipiosSelect?.type === "validate" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label>Ponto</label>
                    <CustomInput>
                      <select
                        name="pontoSelect"
                        className={
                          errors.pontoSelect
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        ref={register({
                          required: true,
                          validate: (value) => pontos.length > 0,
                        })}
                        value={selectedPonto.toLocaleString()}
                        onChange={(e: any) => {
                          setSelectedPonto(e.currentTarget.value);
                          first();
                        }}
                        style={{ background: "white" }}
                      >
                        {pontos.length === 0 ? (
                          <option value={0}>Selecione um Município</option>
                        ) : (
                          <option value={0}>Todos</option>
                        )}

                        {pontos.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.pontoSelect?.type === "validate" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                    </CustomInput>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="dataInicial">Data inicial</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataInicial
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de início"
                        name="dataInicial"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                      />
                      {errors.dataInicial?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataInicial?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="dataFinal">Data final</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataFinal
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de fim"
                        name="dataFinal"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                      />
                      {errors.dataFinal?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataFinal?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                </div>
                <div className="row">
                  <div className="select-doacao">
                    <label>Tipo de doação</label>
                    <CustomInput>
                      <select
                        name="tipoDoacao"
                        className={
                          errors.tipoDoacao
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        ref={register({
                          required: true,
                        })}
                        value={tipoDoacao.toLocaleString()}
                        onChange={(e: any) => {
                          setTipoDoacao(e.currentTarget.value);
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={1}>Pessoas Físicas</option>
                        <option value={2}>Entidades Beneficiárias</option>
                      </select>
                      {errors.tipoDoacao?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="pesquisar">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success"
                      disabled={isSearching ? true : false}
                      onClick={(event) => {
                        if (
                          getValues("dataInicial") === undefined ||
                          getValues("dataFinal") === undefined
                        ) {
                          event.preventDefault();
                          notify("error", "Preencha todos os campos");
                        } else if (
                          getValues("dataInicial") > getValues("dataFinal")
                        ) {
                          event.preventDefault();
                          notify("error", "Data inicial maior que data final");
                        }
                        setSearchedValue("");
                        setOffset(0);
                        setPage(1);
                      }}
                    >
                      Pesquisar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="space-row"></div>
          {/*<div className="searchBar">
            <SearchBar
              placeholder="Resposável ou Código"
              submitHandler={search}
            />
          </div>*/}
          <Table
            fields={fields}
            rows={doacao}
            hasSelection={false}
            hasSelectionAll={false}
          />
          <div>Total de leite Bovino doado: {totalBovino}</div>
          <div>Total de leite Caprino doado: {totalCaprino}</div>
          <div>Total de leite doado: {totalBovino + totalCaprino}</div>

          <div className="CheckBoxContainer">
            <label className="space">
              <input
                type="checkbox"
                name="agregar"
                checked={agruparCheck}
                onChange={() => {
                  setAgruparCheck(!agruparCheck);
                }}
              />
              <span style={{ marginLeft: 5 }}>{`Agrupar por ${
                tipoDoacao == 1 ? "Pessoas Físicas" : "Entidades Beneficiárias"
              }`}</span>
            </label>
          </div>

          <div className="botoes">
            <Button
              onClick={() => {
                if (doacao.length === 0) {
                  return;
                } else if (!downloading) {
                  togglePdf();
                }
              }}
              name={"Baixar PDF"}
              color={downloading || doacao.length === 0 ? "grey" : "blue"}
              iconButtom={<MdPictureAsPdf />}
            />

            <Button
              onClick={() => {
                if (doacao.length === 0) {
                  return;
                } else if (!downloading) {
                  setCsvCheck(true);
                  togglePdf();
                }
              }}
              name={"Baixar CSV"}
              color={downloading || doacao.length === 0 ? "grey" : "blue"}
              iconButtom={<FaFileCsv />}
            />

            {downloading && <CustomSpinner2 />}
          </div>

          <div className="containerFooterTable">
            Visualizando {doacao.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
          {/* Essa parte mostra na tela o total de leite doado com base no filtros 
          Devem ser estados diferentes dos já existentes: totalBovinoRelatorio e totalCaprinoRelatorio
          Esses estados acima sõa aqueles utilizados pelo relatório PDF
           
          <div>Total de leite Bovino doado: {estado que contém a qtd total de leite bov}</div>
          <div>Total de leite Caprino doado: {estado que contém a qtd total de leite cap}</div>
          <div>Total de leite doado: {qtd leite bov + qtd leite cap}</div>
          */}
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RelatorioDoacoes;
