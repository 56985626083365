import React from "react";
import Modal from "../../../../template/components/Modal";
import Table from "../../../../template/components/Table";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../DetalharModal/styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  trocarPonto: () => void;
  selectPonto: (obj: any) => void;
  pontos: any;
  className?: string;
  municipio: string;
  total: number;
}

const fields: string[] = ["Ponto de distribuição", "Entidade", "Vagas"];

const TrocarModal = ({
  isOpen,
  toggle,
  trocarPonto,
  pontos,
  selectPonto,
  municipio,
  total,
  className,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave onClick={trocarPonto}>
          <CustomAiOutlineCheck /> Trocar ponto
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={"Pontos de distribuição no município de " + municipio}
      footerContent={footerButtons()}
      className={className}
    >
      <Table
        fields={fields}
        rows={pontos}
        hasSelectionAll={false}
        hasSelection={true}
        changeOne={selectPonto}
      />
      Visualizando um total de {total} pontos deste município
    </Modal>
  );
};

export default TrocarModal;
