import React, { useCallback, useEffect, useState } from "react";

import { pdf } from "@react-pdf/renderer";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import DocumentPDF from "./components/DocumentPDF";
import { dialogBox } from "../../../template/utils/dialogBox";
import Table from "../../../template/components/Table";
import FileSaver from "file-saver";
//import SearchBar from "../../../template/components/SearchBar";

import { RiPagesLine } from "react-icons/ri";
import { MdPictureAsPdf } from "react-icons/md";

import { CustomSelect } from "../../../template/styles/styles";
import { parseJsonToTableDataList } from "../../../template/utils/parser";

import api from "../../../services/api";

import {
  Container,
  CustomSpinner,
  DateContainer,
  CustomReactSelect,
} from "./styles";
import Pagination from "../../../template/components/Pagination";

import { useForm } from "react-hook-form";
import { PontoInterface } from "../../../template/utils/types";
import { createFilter } from "react-select";
import { FaFileCsv } from "react-icons/fa";
import { json2csv } from "json-2-csv";

const RelatorioEntregasLaticinio = () => {
  const [entregasReport, setEntregasReport] = useState<any[]>([]);
  const [laticinios, setLaticinios] = useState<any[]>([]);
  const [laticinioSelected, setLaticinioSelected] = useState<number>(0);
  const [pontos, setPontos] = useState<PontoInterface[]>([]);
  const [pontoSelected, setPontoSelected] = useState<number>(0);
  const [dataInicial, setDataInicial] = useState<any>();
  const [dataFinal, setDataFinal] = useState<any>();
  const [entregas, setEntregas] = useState<any[]>([]);
  const [totalBovino, setTotalBovino] = useState(0);
  const [totalCaprino, setTotalCaprino] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  //const [searchedValue, setSearch] = useState<string>("");
  const [downloading, setDownloading] = useState<Boolean>(false);
  const [laticiniosOptions, setLaticiniosOptions] = useState<any>([]);
  const [pontosOptions, setPontosOptions] = useState<any>([]);
  const { register, errors, getValues } = useForm();
  const [csvCheck, setCsvCheck] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>({
    value: 0,
    label: "Todos",
  });

  const itemsPerPage = 10;

  //Titulo da tabela
  const fields = [
    "Laticinio",
    "Gestor do Laticínio",
    "Qtd Bovino",
    "Qtd Caprino",
    "Ponto de distribuição",
    "Data",
  ];

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const getLaticinios = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("laticinios/");
      data && setLaticinios(data);
      setLaticinioSelected(data[0].id);
    };
    getLaticinios();
  }, []);

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada = `pontos/?laticinio=${laticinioSelected}`;

        //Retorna todos os pontos cadastrados
        if (laticinioSelected === 0) return;

        const { data } = await api.get(chamada);

        setPontos(data.results);
      } catch (error) {
        notify("error", "Erro no servidor");
      }
    };
    loadPontos();
  }, [laticinioSelected]);

  useEffect(() => {
    const getEntregas = async () => {
      try {
        if (
          dataInicial === undefined ||
          dataFinal === undefined ||
          laticinioSelected === 0
        )
          return;
        let query;
        if (pontoSelected > 0) {
          query = `entregas/?limit=${itemsPerPage}&offset=${offset}&laticinio=${laticinioSelected}&ponto=${pontoSelected}&data_inicial=${dataInicial}&data_final=${dataFinal}`;
        } else {
          query = `entregas/?limit=${itemsPerPage}&offset=${offset}&laticinio=${laticinioSelected}&ponto=&data_inicial=${dataInicial}&data_final=${dataFinal}`;
        }
        //Busca os dados na API
        const { data } = await api.get(query);
        const { count, results } = data;
        //Total de registros
        setTotal(count);
        //Adiciona o resultado ao array de entregas
        setEntregas(
          parseJsonToTableDataList(results, [
            "laticinio",
            "gestor_laticinio",
            "quantidade_leite_bovino",
            "quantidade_leite_caprino",
            "ponto",
            "data",
          ])
        );
        //Total de registros
        setTotal(count);
        //Calcula o total de leite
        calcTotalBovinoCaprino(results);
      } catch (error) {
        notify(
          "error",
          "Erro no Servidor, não foi possível gerar o os dados da tabela"
        );
        console.log(error);
      }
    };
    getEntregas();
  }, [
    offset,
    total,
    laticinioSelected,
    pontoSelected,
    dataInicial,
    dataFinal,
    notify,
  ]);

  function parseEntregasDocument(data: any[]) {
    const tmp = data.map((dt: any) => {
      return {
        Município: dt.municipio.nome,
        "Ponto de Distribuição": dt.ponto,
        Laticínio: dt.laticinio,
        "Gestor do Laticínio": dt.gestor_laticinio,
        "Quantidade leite bovino": dt.quantidade_leite_bovino,
        "Quantidade leite caprino": dt.quantidade_leite_caprino,
        Data: `${dt.data.split("T")[0].split("-")[2]}/${
          dt.data.split("T")[0].split("-")[1]
        }/${dt.data.split("T")[0].split("-")[0]}`,
      };
    });
    return tmp;
  }
  const getEntregasReport = async () => {
    try {
      if (
        dataInicial === undefined ||
        dataFinal === undefined ||
        laticinioSelected === 0
      )
        return;
      let query;
      if (pontoSelected > 0) {
        query = `entregas/?laticinio=${laticinioSelected}&ponto=${pontoSelected}&data_inicial=${dataInicial}&data_final=${dataFinal}`;
      } else {
        query = `entregas/?laticinio=${laticinioSelected}&ponto=&data_inicial=${dataInicial}&data_final=${dataFinal}`;
      }
      //Busca os dados na API
      const { data } = await api.get(query);
      //const { count, results } = data;
      setEntregasReport(data);
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (entregasReport?.length !== 0)
      csvCheck === true ? createCSV() : createPDF();
  }, [entregasReport]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  // const search = useCallback((searchedValue: string) => {
  //   setSearch(searchedValue);
  //   setOffset(0);
  //   setPage(1);
  // }, []);

  async function createPDF() {
    //Cria uma instância do PDF
    const blob = await pdf(
      <DocumentPDF
        data={entregasReport}
        count={entregasReport?.length}
        dateStart={dataInicial}
        dateEnd={dataFinal}
        totalBovino={totalBovino}
        totalCaprino={totalCaprino}
      />
    ).toBlob();
    //Obtem a data atual
    const today = new Date();
    const datenow =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    //Gera o download do PDF
    if (entregas.length > 0)
      FileSaver.saveAs(blob, `relatorio_entregas_por_laticinio_${datenow}.pdf`);
    else {
      notify("warning", "Lista de entregas vazia");
    }
    setDownloading(false);
  }

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        parseEntregasDocument(entregasReport),
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "Relatorio_Entregas_Laticinio.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloading(false);
      setCsvCheck(false);
    }
  };
  //Verifica o total de leite entregue
  function calcTotalBovinoCaprino(results: any) {
    let bovino = 0,
      caprino = 0;
    for (var i = 0; i < results.length; i++) {
      bovino += results[i].quantidade_leite_bovino;
      caprino += results[i].quantidade_leite_caprino;
    }
    setTotalBovino(bovino);
    setTotalCaprino(caprino);
  }

  useEffect(() => {
    const options: any = [];
    laticinios?.map((laticinio) =>
      options.push({ value: laticinio.id, label: laticinio.nome })
    );
    setLaticiniosOptions(options);
  }, [laticinios]);

  useEffect(() => {
    const options: any = [{ value: 0, label: "Todos" }];
    pontos?.map((ponto) =>
      options.push({ value: ponto.id, label: ponto.nome })
    );
    setPontosOptions(options);
  }, [pontos]);

  return (
    <Layout>
      <MainContainer
        titlePage="Relatório de Entregas do Leite por Laticínio"
        iconPage={<RiPagesLine />}
      >
        <Container>
          <div className="row">
            <div className="headerContainer">
              <div className="itemsSelectHeader">
                <div className="inputContainer">
                  <label htmlFor="setLaticinioSelected">Laticínio</label>
                  <CustomReactSelect
                    name="setLaticinioSelected"
                    placeholder="Selecione um latícinio"
                    options={laticiniosOptions}
                    onChange={(e: any) => {
                      setLaticinioSelected(e.value);
                      setSelectedOption({ value: 0, label: "Todos" });
                      setPontoSelected(0);
                      first();
                    }}
                    noOptionsMessage={() => "Nenhum laticínio encontrado"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                </div>
                <span className="space"></span>
                <div className="inputContainer">
                  <label htmlFor="setPontoSelected">Ponto</label>
                  <CustomReactSelect
                    name="setPontoSelected"
                    value={selectedOption}
                    options={pontosOptions}
                    onChange={(e: any) => {
                      setSelectedOption({
                        value: parseInt(e.value),
                        label: e.label,
                      });
                      setPontoSelected(parseInt(e.value));
                      first();
                    }}
                    noOptionsMessage={() => "Nenhum ponto encontrado"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                </div>
                <span className="space"></span>
                <div className="inputContainer">
                  <label htmlFor="dataInicial">Data inicial</label>
                  <DateContainer>
                    <input
                      type="date"
                      className={errors.dataInicial && "error-input"}
                      name="dataInicial"
                      ref={register}
                      onChange={(e) => {
                        setDataInicial(e.currentTarget.value);
                      }}
                      placeholder="Data de início"
                    />
                    {errors.dataInicial && <span>Data inválida</span>}
                    {errors.dataInicial?.type === "required" && (
                      <span className="error-message">Campo obrigatório</span>
                    )}

                    <span className="space"></span>
                  </DateContainer>
                </div>
                <div className="inputContainer">
                  <label htmlFor="dataFinal">Data final</label>
                  <DateContainer>
                    <input
                      type="date"
                      className={errors.dataFinal && "error-input"}
                      name="dataFinal"
                      ref={register({
                        required: true,
                        validate: (value) => value === getValues("dataInicial"),
                      })}
                      onChange={(e) => {
                        setDataFinal(e.currentTarget.value);
                      }}
                      placeholder="Data de fim"
                    />
                    {errors.dataFinal && <span>Data inválida</span>}
                    {errors.dataFinal?.type === "required" && (
                      <span className="error-message">Campo obrigatório</span>
                    )}
                    {errors.dataFinal?.type === "validate" && (
                      <span className="error-message">Inserir data futura</span>
                    )}
                  </DateContainer>
                </div>
              </div>
              {/*<div className="headerSearchBar">
                <SearchBar submitHandler={search} />
              </div>
              */}
            </div>
          </div>
          <Table
            fields={fields}
            rows={entregas}
            hasSelection={false}
            hasSelectionAll={false}
          />
          <div className="containerFooterTable">
            Visualizando {entregas.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
          <div>Total de leite Bovino entregue: {totalBovino}</div>
          <div>Total de leite Caprino entregue: {totalCaprino}</div>
          <div>Total de leite entregue: {totalBovino + totalCaprino}</div>
          <div className="botoes" style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                if (
                  dataInicial === undefined ||
                  dataFinal === undefined ||
                  laticinioSelected === 0
                ) {
                  notify("error", "Preencha todos os campos");
                } else if (dataFinal < dataInicial) {
                  notify("error", "Data inicial maior que data final");
                } else if (!downloading) {
                  setDownloading(true);
                  getEntregasReport();
                }
              }}
              name="Baixar PDF"
              color={downloading ? "grey" : "blue"}
              iconButtom={<MdPictureAsPdf />}
            />

            <Button
              onClick={() => {
                if (
                  dataInicial === undefined ||
                  dataFinal === undefined ||
                  laticinioSelected === 0
                ) {
                  notify("error", "Preencha todos os campos");
                } else if (dataFinal < dataInicial) {
                  notify("error", "Data inicial maior que data final");
                } else if (!downloading) {
                  setDownloading(true);
                  setCsvCheck(true);
                  getEntregasReport();
                }
              }}
              name="Baixar CSV"
              color={downloading ? "grey" : "blue"}
              iconButtom={<FaFileCsv />}
            />

            {downloading && (
              <div>
                <CustomSpinner /> Processando
              </div>
            )}
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RelatorioEntregasLaticinio;
