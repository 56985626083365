import React, { useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { CustomSelect } from "../../../../template/styles/styles";
import { FormContainer } from "./styles";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import InputMask from "react-input-mask";

interface IProps {
  row: any;
  toggle: () => void;
  reload: () => void;
  modified: (b: boolean) => void;
  otherData: any;
}

type Inputs = {
  codFamilia: string;
  responsavel: string;
  situacao: string;
  rendaPerCapita: number;
  rendaMedia: number;
  tipoLeite: string;
  endereco: {
    municipio: {
      nome: string;
    };
    numero: string;
    rua: string;
    bairro: string;
    telefone: string;
    cep: string;
  };
};

const DetalharForm: React.FC<IProps> = (props) => {
  const { row, toggle, reload, modified, otherData } = props;

  const { register, handleSubmit, errors, setValue, formState, control } =
    useForm<Inputs>();

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const priceFormatter = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value);
    return `${prefix}${amount}`;
  }, []);

  const fecharModal = () => {
    toggle();
  };

  useEffect(() => {
    modified(formState.isDirty);
  }, [formState]);

  const onSubmit = (data: any) => {
    const obj = {
      tipo_leite: data.tipoLeite,
    };

    api
      .patch(`/familias/${row.id}/`, obj)
      .then((res) => {
        notify("success", "Alterações realizadas com sucesso");
        reload();
        fecharModal();
      })
      .catch((err) => {
        notify("error", "Occoreu um erro no servidor");
      });
  };

  useEffect(() => {
    if (row && otherData) {
      const { id, responsavel, cod_familia, situacao, renda_percapita } = row;

      setValue("responsavel", responsavel);
      setValue("codFamilia", cod_familia);
      setValue("situacao", situacao);
      setValue("rendaPerCapita", renda_percapita);

      const dadosExtras = otherData.find((obj: any) => obj.id === id);

      setValue("endereco.municipio.nome", dadosExtras.endereco.municipio.nome);
      setValue("endereco.bairro", dadosExtras.endereco.bairro);
      setValue(
        "endereco.numero",
        dadosExtras.endereco.numero ? dadosExtras.endereco.numero : ""
      );
      setValue("endereco.rua", dadosExtras.endereco.rua);
      setValue("endereco.cep", dadosExtras.endereco.cep);
      setValue(
        "endereco.localidade.nome",
        dadosExtras.endereco.localidade.nome
          ? dadosExtras.endereco.localidade.nome
          : ""
      );

      setValue(
        "telefone",
        dadosExtras.responsavel.telefone ? dadosExtras.responsavel.telefone : ""
      );
    }
  }, [row, otherData]);

  return row ? (
    <FormContainer id="detalharForm" onSubmit={handleSubmit(onSubmit)}>
      <label>Responsável</label>
      <input disabled name="responsavel" ref={register} />
      {errors.responsavel && <div>Campo obrigatório</div>}
      <label>Código Familiar</label>
      <input disabled name="codFamilia" ref={register} />
      <label>Situação</label>
      <input name="situacao" disabled ref={register} />
      <label>Renda per capita</label>
      <input disabled name="rendaPerCapita" ref={register} />
      {errors.rendaPerCapita && <div>Campo obrigatório</div>}
      {/* <label>Renda Média</label>
      <input name="rendaMedia" ref={register({ required: true })} />
      {errors.rendaMedia && <div>Campo obrigatório</div>} */}
      <label>Tipo de Leite</label>
      <CustomSelect
        name="tipoLeite"
        ref={register({ required: true })}
        defaultValue={row.tipo_leite}
      >
        <option value="BOVINO">BOVINO</option>
        <option value="CAPRINO">CAPRINO</option>
        <option value="BOVINO/CAPRINO">BOVINO/CAPRINO</option>
      </CustomSelect>
      {errors.tipoLeite && <div>Campo obrigatório</div>}
      <div className="subtitleLocal">
        <h6>Informações de contato e localização</h6>
      </div>
      <div className="row">
        <div className="inputsGroup col-6">
          <label>Município</label>
          <input disabled name="endereco.municipio.nome" ref={register} />
        </div>
        <div className="inputsGroup col-6">
          <label>Bairro</label>
          <input disabled name="endereco.bairro" ref={register} />
        </div>
      </div>
      <div className="row">
        <div className="inputsGroup col-6">
          <label>Logradouro</label>
          <input disabled name="endereco.rua" ref={register} />
        </div>
        <div className="inputsGroup col-6">
          <label>Número</label>
          <input disabled name="endereco.numero" ref={register} />
        </div>
      </div>
      <div className="row">
        <div className="inputsGroup col-6">
          <label>Cep</label>
          <Controller
            disabled
            name="endereco.cep"
            as={InputMask}
            control={control}
            mask="99999-999"
            maskChar=""
          />
        </div>
        <div className="inputsGroup col-6">
          <label>Telefone</label>
          <Controller
            disabled
            as={InputMask}
            control={control}
            name="telefone"
            mask="(99)99999-9999"
            maskChar=""
          />
        </div>
      </div>
      <div className="row">
        <div className="inputsGroup col-12">
          <label>Localidade</label>
          <textarea
            className="inputLocalidade"
            disabled
            name="endereco.localidade.nome"
            ref={register}
          />
        </div>
      </div>
    </FormContainer>
  ) : (
    <div />
  );
};

export default DetalharForm;
