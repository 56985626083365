import { Spinner } from "reactstrap";
import styled from "styled-components";
import arrow from "../../../../template/assets/icons/arrow.png";
import colors from "../../../../template/styles/colors";

export const Container = styled.div`
  .content {
    display: flex;
    justify-content: flext-start;
    align-items: center;
  }

  .title {
    margin-left: 1px;
  }

  .space {
    margin-left: 20px;
  }

  .space-row {
    margin-top: 18px;
  }

  .list {
    list-style-type: none;
    font-size: 14px;
  }

  .list li {
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    align-items: center;
    display: flex;
    margin-top: 5px;
  }

  .listItem {
    height: 20px;
    width: 100%;
  }

  .removeItem {
    color: ${colors.ultraDarkGrey};
    margin-left: 10px;
    :hover {
      text-decoration: none;
      color: ${colors.darkGrey};
    }
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  .error-message {
    color: ${colors.redError};
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: -10px;
    display: block;
  }

  .CustomSelect {
    height: 39px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid #c8ced3;
    /* border: 1px solid${colors.bluePLP}; */
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 35px 8px 8px;
    background: url(${arrow}) 95% / 10px no-repeat;
    background-color: ${colors.light};
  }

  .addColaboradorButton {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 10px 8px 10px;
    height: 39px;
    font-size: 14px;
    vertical-align: middle;
    align-items: center;
    color: white;
    background-color: ${colors.bluePLP};

    :hover {
      text-decoration: none;
      color: white;
      background-color: ${colors.blueOfficial};
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  h6 {
    font-weight: bold;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: 100%;

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input {
    width: 100%;
    height: 38px;
    border: 1px solid #c8ced3;
    border-radius: 4px;
    padding-left: 8px;
  }

  .blueSelect {
    height: 38px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid ${colors.bluePLP};
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 35px 8px 8px;
    background: url(${arrow}) 95% / 10px no-repeat;
    background-color: ${colors.light};
  }
`;

export const CustomSpinner = styled(Spinner)`
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  margin-left: 5px;
`;

// Coloca estilo no componente ReactSelect
export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 39,
    height: 39,
    minWidth: 200,
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
    padding: "0 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: 0,
  }),

  indicatorSeparator: (state: any) => ({
    display: "none",
  }),

  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
  }),
};
