import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import cartaoQRCode from "../../../../template/assets/cartaoQRCode.png";
import { useState } from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// estilos
const styles = StyleSheet.create({
  page: {
    marginLeft: 10,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  body: {
    position: "absolute",
  },
  QRCode: {
    width: 65,
    height: 70,
    marginLeft: 6,
    marginTop: -79,
  },
  view2: {
    position: "absolute",
    marginTop: 174,
  },
  space: {
    marginBottom: 10,
  },
  image: {
    width: 520,
    height: 170,
  },
  municipioText: {
    marginTop: -140,
    marginLeft: 80,
    fontSize: 10,
  },
  pontoText: {
    marginTop: 18,
    marginLeft: 80,
    fontSize: 10,
  },
  responsavelText: {
    marginTop: 17,
    marginLeft: 80,
    fontSize: 10,
  },
  nisText: {
    marginTop: 24,
    marginLeft: 80,
    fontSize: 10,
    color: "#fff",
  },
  codFamiliaText: {
    marginTop: 0,
    marginLeft: 175,
    fontSize: 10,
    color: "#fff",
  },
  tipoLeiteText: {
    marginTop: 18,
    marginLeft: 80,
    fontSize: 8.5,
    color: "#fff",
  },
  criterioText: {
    marginTop: -10,
    marginLeft: 175,
    fontSize: 10,
    color: "#fff",
  },
});

type DataTableValue = {
  [k: string]: string;
};
interface IProps {
  data: DataTableValue[];
  count: number;
  municipio: string;
  pontoNome: string;
  selectedPonto: any;
  pontos: any[];
  totalMunicipio: number;
  startPage: number;
  endPage: number;
}

const DocumentPDF = ({
  data,
  municipio,
  pontoNome,
  selectedPonto,
  pontos,
  count,
  totalMunicipio,
  startPage,
  endPage,
}: IProps) => {
  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${AddLeadingZeros(temp.getUTCDate())}/${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}/${temp.getUTCFullYear()}`;
    return formated;
  };

  return (
    <>
      <Document title="Lista de famílias vinculadas">
        <Page object-fit="fill" size="A4" style={styles.page}>
          {!(selectedPonto == 0) &&
            data.map((familiaPonto) => {
              return (
                <>
                  <Image style={styles.image} source={cartaoQRCode} />
                  <View>
                    <Image
                      style={styles.QRCode}
                      source={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data={"cod_familia": "${familiaPonto.cod_familia}"}`}
                    />
                    <Text style={styles.municipioText}>{municipio}</Text>
                    <Text style={styles.pontoText}>{familiaPonto.ponto}</Text>
                    <Text style={styles.responsavelText}>
                      {familiaPonto.responsavel}
                    </Text>
                    <Text style={styles.nisText}></Text>
                    <Text style={styles.codFamiliaText}>
                      {familiaPonto.cod_familia}
                    </Text>
                    <Text style={styles.tipoLeiteText}>
                      {familiaPonto.tipo_leite}
                    </Text>
                    <Text style={styles.criterioText}>{familiaPonto.tipo}</Text>
                  </View>
                  <View style={styles.space}></View>
                </>
              );
            })}
        </Page>
      </Document>
    </>
  );
};

export default DocumentPDF;
