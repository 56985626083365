import React, { useCallback, useEffect, useState } from "react";
import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import { dialogBox } from "../../../template/utils/dialogBox";
import Table from "../../../template/components/Table";
import { RiPagesLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { CustomInput } from "../../../template/styles/styles";
import {
  parseDate2,
  parseJsonToTableDataList,
} from "../../../template/utils/parser";

import api from "../../../services/api";

import { Container, CustomSpinner } from "./styles";
import Pagination from "../../../template/components/Pagination";
import { PontoInterface, TableData } from "../../../template/utils/types";
import SearchBar from "../../../template/components/SearchBar";
import { MdPictureAsPdf } from "react-icons/md";

const RelatorioPresenca = () => {
  const [distribuicoesTableData, setDistribuicoesTableData] = useState<
    TableData[]
  >([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [pontos, setPontos] = useState<PontoInterface[]>([]);
  const [pontoSelected, setPontoSelected] = useState<number>(-1);
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(-1);
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [municipiosLoaded, setMunicipiosLoaded] = useState(false);
  const [dias, setDias] = useState<string[]>([]);
  const itemsPerPage = 20;
  const { register, handleSubmit, setValue, getValues, errors } =
    useForm<any>();

  //Titulo da tabela
  const [fields, setFields] = useState<string[]>(["Família"]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada = `pontos/?municipio=${municipioSelected}`;

        //Retorna todos os pontos cadastrados
        if (municipioSelected == -1) {
          chamada = `pontos/`;
        }
        const { data } = await api.get(chamada);
        setPontos(data.results);
      } catch (error) {
        notify("error", "Erro no servidor");
      }
    };
    loadPontos();
  }, [municipioSelected]);

  useEffect(() => {
    if (pontos.length > 0 && !municipiosLoaded) {
      const getMunicipios = async () => {
        const { data } = await api.get(`municipios/`);
        setMunicipios(data);
        setMunicipiosLoaded(true);
      };
      getMunicipios();
    }
  }, [pontos, municipiosLoaded]);

  const onSubmit = () => {
    let dataInicial = new Date(getValues("dataInicial"));
    let dataFinal = new Date(getValues("dataFinal"));
    var timeDiff = Math.abs(dataFinal.getTime() - dataInicial.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays < 32) {
      setIsSearching(true);
      loadDistribuicoes();
    } else {
      notify("warning", "O período de busca deve ser menor que 31 dias");
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setIsSearching(false);
  }, [distribuicoesTableData]);

  useEffect(() => {
    if (distribuicoesTableData.length > 0) loadDistribuicoes();
  }, [offset]);

  useEffect(() => {
    setFields(fields.concat(dias));
  }, [dias]);

  const loadDistribuicoes = useCallback(async () => {
    try {
      setFields(["Família"]);
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      //Query
      let queryTablePaginada = `distribuicoes/relatorio-presenca/?limit=${itemsPerPage}&offset=${offset}&ponto=${
        pontoSelected > -1 ? pontoSelected : ""
      }&data_inicial=${dataInicial}&data_final=${dataFinal}&search=${searchedValue}&page=${page}&ordering=Família`;

      //Busca os dados na API
      const { data } = await api.get(queryTablePaginada);

      //Checa se houve distribuições
      if (Object.keys(data.results.presenca[0].Dia).length > 0) {
        setDias(Object.keys(data.results.presenca[0].Dia));
        let diasToTable = Object.keys(data.results.presenca[0].Dia);
        let objetosTransformados: any = data.results.presenca.map(
          (dist: any) => {
            let objetoTransformado: any = {};

            for (let chavePrincipal in dist) {
              if (chavePrincipal === "Dia") {
                for (let chaveAninhada in dist[chavePrincipal]) {
                  objetoTransformado[chaveAninhada] =
                    dist[chavePrincipal][chaveAninhada];
                }
              } else {
                objetoTransformado[chavePrincipal] = dist[chavePrincipal];
              }
            }
            return objetoTransformado;
          }
        );
        setDistribuicoesTableData(
          parseJsonToTableDataList(objetosTransformados, [
            "Família",
            ...diasToTable,
          ])
        );
        setTotal(data.count);
      } else {
        notify("warning", "Sem distuibuições");
        setIsSearching(false);
      }
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
      setIsSearching(false);
    }
  }, [offset, searchedValue, municipioSelected, pontoSelected, notify]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearchedValue(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const handleDownload = async () => {
    try {
      let dataInicial = getValues("dataInicial");
      let dataFinal = getValues("dataFinal");
      //Query
      let queryTablePaginada = `distribuicoes/relatorio-presenca/pdf/?ponto=${pontoSelected}&data_inicial=${dataInicial}&data_final=${dataFinal}&search=${searchedValue}&ordering=Família`;

      //Busca os dados na API
      //const {data} = await api.get(queryTablePaginada);
      await api
        .get(
          queryTablePaginada,
          { responseType: "blob" } // !!!
        )
        .then((response) => {
          window.open(URL.createObjectURL(response.data));
        });
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  return (
    <Layout>
      <MainContainer
        titlePage="Relatório de Presença por Ponto"
        iconPage={<RiPagesLine />}
      >
        <Container>
          <div className="card mb-3">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching && <CustomSpinner />}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomInput>
                      <select
                        className={
                          errors.municipio
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder={"Selecione um município"}
                        name="municipio"
                        defaultValue={municipioSelected}
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setMunicipioSelected(e.currentTarget.value);
                          setPontoSelected(-1);
                          setValue("ponto", -1);
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {municipios.map((item) => (
                          <option value={parseInt(item.id)}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.municipio && (
                        <span className="error-message">
                          Selecione um município
                        </span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label>Ponto</label>
                    <CustomInput>
                      <select
                        className={
                          errors.ponto
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        name="ponto"
                        defaultValue={pontoSelected}
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setPontoSelected(parseInt(e.currentTarget.value));
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {pontos.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.ponto && (
                        <span className="error-message">
                          Selecione um ponto
                        </span>
                      )}
                    </CustomInput>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="dataInicial">Data inicial</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataInicial
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de início"
                        name="dataInicial"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataInicio(e.currentTarget.value);
                        }}
                      />
                      {errors.dataInicial?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataInicial?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="dataFinal">Data final</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataFinal
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de fim"
                        name="dataFinal"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataFim(e.currentTarget.value);
                        }}
                      />
                      {errors.dataFinal?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataFinal?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isSearching ? true : false}
                    onClick={(event) => {
                      if (
                        getValues("dataInicial") === undefined ||
                        getValues("dataFinal") === undefined
                      ) {
                        event.preventDefault();
                        notify("error", "Preencha todos os campos");
                      } else if (
                        getValues("dataInicial") > getValues("dataFinal")
                      ) {
                        event.preventDefault();
                        notify("error", "Data inicial maior que data final");
                      }
                      setSearchedValue("");
                      setOffset(0);
                      setPage(1);
                    }}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="searchBar mb-2">
            <SearchBar placeholder="Responsável" submitHandler={search} />
          </div>
          <Table
            fields={fields}
            rows={distribuicoesTableData}
            hasSelection={false}
            hasSelectionAll={false}
          />

          {total != 0 && (
            <div className="containerFooterTable">
              Visualizando {distribuicoesTableData.length} de um total de{" "}
              {total} famílias
              <Pagination
                itemCount={total}
                itemsPerPage={itemsPerPage}
                selectedPage={page}
                handlePageChange={changePage}
                handleNextPage={next}
                handlePreviousPage={previous}
                handleLastPage={last}
                handleFirstPage={first}
                maxPages={5}
              />
            </div>
          )}
          <Button
            onClick={() => {
              if (distribuicoesTableData.length === 0) {
                return;
              } else {
                handleDownload();
              }
            }}
            name="Baixar PDF"
            color={distribuicoesTableData.length === 0 ? "grey" : "blue"}
            iconButtom={<MdPictureAsPdf />}
          />
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RelatorioPresenca;
