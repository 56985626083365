import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container, customStyles } from "./../../styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import api from "../../../../../services/api";
import { CNPJMask, CPFMask } from "../../../../../template/utils/masks";
import {
  parseCNPJ,
  parseCPF,
  parseDate2,
  parseTelefone,
} from "../../../../../template/utils/parser";
import Select, { createFilter } from "react-select";
import { EntidadeBeneficiariaInterface } from "../../../../../template/utils/types";

interface IProps {
  row: any;
  toggle: () => void;
  loadEntidadeBeneficiaria: () => void;
}

const DetalharForm: React.FC<IProps> = (props) => {
  const { row } = props;
  const { handleSubmit, register, setValue, errors, control, setError } =
    useForm<EntidadeBeneficiariaInterface>({ mode: "onSubmit" });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>();

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
    setSelectedOption(
      options.find(
        (item: any) => props.row.endereco.municipio.id === item.value
      )
    );
  }, [municipios]);

  function validarEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSubmit = async (data: any) => {
    data.endereco.numero = data.endereco.numero.replace(/\D/g, "");
    data.endereco.municipio = selectedOption.value;
    data.cnpj
      ? (data.cnpj = data.cnpj.replace(/[./-]/g, "").trim())
      : (data.cnpj = null);
    if (data.telefone) {
      data.telefone = data.telefone.replace(/\D/g, "");
    } else data.telefone = "";
    if (data.responsavel.pessoa.telefone) {
      data.responsavel.telefone = data.responsavel.pessoa.telefone.replace(
        /\D/g,
        ""
      );
    } else data.responsavel.telefone = "";
    const entidade: EntidadeBeneficiariaInterface = data;
    entidade.responsavel.documento.numero =
      entidade.responsavel?.documento?.numero.replace(/[./-]/g, "").trim();
    entidade.responsavel.documento.tipo = 1;
    // Edita as informações do gestor
    api
      .patch(`/entidades_beneficiarias/${row.id}/`, entidade)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Entidade Beneficiária editada com sucesso!`);
        // Atualiza a tabela
        props.loadEntidadeBeneficiaria();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.cnpj) {
          setError("cnpj", {
            type: "manual",
            message: error.response.data.cnpj[0],
          });
        } else if (error.response.data.cpf) {
          setError("responsavel.documento.numero", {
            type: "manual",
            message: error.response.data.cpf[0],
          });
        } else if (error.response.data.numero) {
          setError("responsavel.documento.numero", {
            type: "manual",
            message: error.response.data.numero[0],
          });
        } else {
          notify("error", `Ocorreu um erro na edição, verifique o formulário`);
        }
      });
  };

  return row ? (
    <Container>
      <FormContainer id="detalharForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="razao_social">Razão social</label>
              <input
                className={
                  errors.razao_social
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                defaultValue={props.row.razao_social}
                name="razao_social"
                ref={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              {errors.razao_social?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.razao_social?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 100
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="telefone">Telefone (opcional)</label>
              <input
                type="text"
                className={
                  errors.telefone ? "CustomInput error-input" : "CustomInput"
                }
                name="telefone"
                ref={register({
                  required: false,
                  minLength: 13,
                })}
                defaultValue={parseTelefone(props.row.telefone)}
                onChange={(e: any) => {
                  e.currentTarget.value = parseTelefone(e.currentTarget.value);
                }}
              />
              {errors.telefone?.type === "minLength" && (
                <span className="error-message">Mínimo 10 dígitos</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="cnpj">CNPJ (opcional)</label>
              <Controller
                className={
                  errors.cnpj ? "CustomInput error-input" : "CustomInput"
                }
                name="cnpj"
                as={InputMask}
                control={control}
                mask={CNPJMask.mask}
                maskChar=""
                defaultValue={props.row.cnpj ? parseCNPJ(props.row?.cnpj) : ""}
                placeholder="99.999.999/9999-99"
                rules={{
                  required: false,
                  minLength: CNPJMask.maxLength,
                }}
              />
              {errors.cnpj?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 14 dígitos
                </span>
              )}
              {errors.cnpj && (
                <span className="error-message">{errors.cnpj.message}</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className={
                  errors.email ? "CustomInput error-input" : "CustomInput"
                }
                defaultValue={props.row.email}
                name="email"
                ref={register({
                  required: true,
                  validate: (value) => validarEmail(value) === true,
                })}
              />
              {errors.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.email?.type === "validate" && (
                <span className="error-message">Inserir email válido</span>
              )}
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </CustomInput>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.cep">CEP</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                maskChar=""
                defaultValue={props.row.endereco.cep}
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">Deve conter 8 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                type="text"
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.rua"
                defaultValue={props.row.endereco.rua}
                ref={register({ required: true })}
              />
              {errors.endereco?.rua?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.numero">Número</label>
              <input
                type="text"
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.numero"
                ref={register()}
                defaultValue={
                  props.row.endereco.numero === null
                    ? "s/n"
                    : props.row.endereco.numero
                }
                onChange={(e) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                type="text"
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.bairro"
                defaultValue={props.row.endereco.bairro}
                ref={register({ required: true })}
              />
              {errors.endereco?.bairro?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.localidade.nome">Localidade</label>
              <input
                type="text"
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.localidade.nome"
                defaultValue={props.row.endereco.localidade.nome}
                ref={register({ required: true })}
              />
              {errors.endereco?.localidade?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <div className="label">
              <label htmlFor="endereco.municipio">Município</label>
              <Controller
                name="endereco.municipio"
                control={control}
                render={({ onChange, value, name, ref }) => (
                  <Select
                    styles={customStyles}
                    menuPlacement="top"
                    options={municipiosOptions}
                    value={selectedOption}
                    onChange={(val) => {
                      setSelectedOption(val);
                      onChange(val);
                    }}
                    noOptionsMessage={() => "Nenhum município encontrado"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                )}
              />
              {errors.endereco?.municipio?.id && (
                <span className="error-message">Selecione um município</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <h6>Responsável</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="responsavel.pessoa.nome">Nome</label>
              <input
                type="text"
                className={
                  errors.responsavel?.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="responsavel.pessoa.nome"
                defaultValue={props.row.responsavel.pessoa.nome}
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.responsavel?.pessoa?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.responsavel?.pessoa?.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres.
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="responsavel.pessoa.data_nascimento">
                Nascimento
              </label>
              <input
                type="date"
                className={
                  errors.responsavel?.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="responsavel.pessoa.data_nascimento"
                defaultValue={props.row.responsavel.pessoa.data_nascimento}
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
              />
              {errors.responsavel?.pessoa?.data_nascimento?.type ===
                "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.responsavel?.pessoa?.data_nascimento?.type ===
                "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="responsavel.pessoa.telefone">
                Telefone (opcional)
              </label>
              <input
                type="text"
                className={
                  errors.responsavel?.pessoa?.telefone
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="responsavel.pessoa.telefone"
                defaultValue={parseTelefone(
                  props.row.responsavel.pessoa?.telefone
                )}
                ref={register({
                  required: false,
                  minLength: 13,
                })}
                onChange={(e: any) => {
                  e.currentTarget.value = parseTelefone(e.currentTarget.value);
                }}
              />
              {errors.responsavel?.pessoa?.telefone?.type === "minLength" && (
                <span className="error-message">Mínimo 10 dígitos</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="responsavel.documento.numero">CPF</label>
              <Controller
                className={
                  errors.responsavel?.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="responsavel.documento.numero"
                as={InputMask}
                control={control}
                mask={CPFMask.mask}
                maskChar=""
                defaultValue={parseCPF(
                  props.row.responsavel?.documento?.numero
                )}
                rules={{
                  required: true,
                  minLength: CPFMask.maxLength,
                }}
              />
              {errors.responsavel?.documento?.numero?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 11 dígitos
                </span>
              )}
              {errors.responsavel?.documento?.numero?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.responsavel?.documento?.numero && (
                <span className="error-message">
                  {errors.responsavel.documento.numero?.message}
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="responsavel.email">Email</label>
              <input
                type="email"
                className={
                  errors.responsavel?.email
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                defaultValue={props.row.responsavel.email}
                name="responsavel.email"
                ref={register({
                  required: true,
                  validate: (value) => validarEmail(value) === true,
                })}
              />
              {errors.responsavel?.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.responsavel?.email?.type === "validate" && (
                <span className="error-message">Inserir email válido</span>
              )}
            </CustomInput>
          </div>
        </div>
      </FormContainer>
    </Container>
  ) : (
    <div />
  );
};

export default DetalharForm;
