import React, { useState, useEffect } from "react";

import Modal from "../../../../template/components/Modal";

import DetalharForm from "../DetalharForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  reload: () => void;
  otherData: any;
}

const DetalharModal = ({ row, isOpen, toggle, reload, otherData }: IProps) => {
  const [wasModified, setWasModified] = useState<boolean>(false);

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        {wasModified && (
          <ButtonSave type="submit" form="detalharForm">
            <CustomAiOutlineCheck /> Salvar
          </ButtonSave>
        )}
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle="Informações da família"
      footerContent={footerButtons()}
    >
      {/* {infoFamilia()} */}
      <DetalharForm
        row={row}
        otherData={otherData}
        toggle={toggle}
        reload={reload}
        modified={(b: boolean) => setWasModified(b)}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default DetalharModal;
