import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container, customStyles } from "./../../styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import api from "../../../../../services/api";
import { CNPJMask, CPFMask } from "../../../../../template/utils/masks";
import Select, { createFilter } from "react-select";
import { EntidadeParceiraInterface } from "../../../../../template/utils/types";
import {
  parseDate2,
  parseTelefone,
} from "../../../../../template/utils/parser";

interface IProps {
  toggle: () => void;
  loadEntidadeCooperada: () => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, errors, control, setError, setValue } =
    useForm({ mode: "onSubmit" });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [organizacaoSelected, setOrganizacaoSelected] = useState<number>(0);
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [prefeituraOptions, setPrefeituraOptions] = useState<any>([]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [];
    const prefeituras: any = [];
    municipios?.map((municipio) => {
      options.push({ value: municipio.id, label: municipio.nome });
      prefeituras.push({
        value: municipio.id,
        label: `Prefeitura Municipal de ${municipio.nome}`,
      });
    });
    setPrefeituraOptions(prefeituras);
    setMunicipiosOptions(options);
  }, [municipios]);

  function validarEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSubmit = async (data: any) => {
    if (organizacaoSelected == 0) {
      data.tipo = "OSC";
      data.cnpj
        ? (data.cnpj = data.cnpj.replace(/[./-]/g, "").trim())
        : (data.cnpj = null);
      data.endereco.municipio = data.endereco.municipio.value;
      data.municipio = data.endereco.municipio;
      data.responsavel.endereco.municipio =
        data.responsavel.endereco.municipio.value;
      data.responsavel.endereco.numero =
        data.responsavel.endereco.numero.replace(/\D/g, "");
      data.endereco.numero = data.endereco.numero.replace(/\D/g, "");
    } else {
      data.tipo = "PREFEITURA";
      delete data.endereco;
      data.cnpj = null;
      data.municipio = data.razao_social.value;
      data.razao_social = data.razao_social.label;
    }

    if (data.telefone) {
      data.telefone = data.telefone.replace(/\D/g, "");
    } else data.telefone = "";

    if (data.responsavel.pessoa.telefone) {
      data.responsavel.pessoa.telefone =
        data.responsavel.pessoa.telefone.replace(/\D/g, "");
    } else data.responsavel.pessoa.telefone = "";
    const entidade: EntidadeParceiraInterface = data;
    // Cadastra as informações da entidade
    api
      .post(`/entidades_cooperadas/`, entidade)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Entidade Parceira cadastrada com sucesso!`);
        // Atualiza a tabela
        props.loadEntidadeCooperada();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.numero) {
          setError("responsavel.documento.numero", {
            type: "manual",
            message: error.response.data.numero[0],
          });
        } else if (error.response.data.municipio) {
          setError("razao_social", {
            type: "manual",
            message: "Prefeitura já cadastrada",
          });
        } else {
          notify(
            "error",
            `Ocorreu um erro no cadastro, verifique o formulário`
          );
        }
      });
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <label htmlFor="tipo">Organização</label>
            <CustomInput>
              <select
                placeholder={"Selecione uma organização"}
                name="tipo"
                ref={register({
                  required: true,
                })}
                value={organizacaoSelected}
                onChange={(e: any) => {
                  setOrganizacaoSelected(e.currentTarget.value);
                }}
                style={{ background: "white" }}
              >
                <option value={0}>OSC - Organização da Sociedade Civil</option>
                <option value={1}>Prefeitura</option>
              </select>
              {errors.tipo && (
                <span className="error-message">Selecione uma organização</span>
              )}
            </CustomInput>
          </div>
        </div>

        {organizacaoSelected == 1 ? (
          //Formulário para Prefeitura

          <>
            <div className="row">
              <div className="col-sm">
                <div className="label">
                  <label htmlFor="razao_social">Razão Social</label>
                  <Controller
                    name="razao_social"
                    placeholder={"Selecione uma prefeitura"}
                    rules={{ required: true }}
                    control={control}
                    className={errors.razao_social ? "error-input" : ""}
                    as={Select}
                    menuPlacement="bottom"
                    options={prefeituraOptions}
                    styles={customStyles}
                    noOptionsMessage={() => "Nenhuma prefeitura encontrada"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  {errors.razao_social?.type === "required" && (
                    <span className="error-message">
                      Selecione uma prefeitura
                    </span>
                  )}
                  {errors.razao_social && (
                    <span className="error-message">
                      {errors.razao_social.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="telefone"
                    placeholder="(99)99999-9999"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.telefone?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.telefone?.type === "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.email ? "CustomInput error-input" : "CustomInput"
                    }
                    placeholder="exemplo@email.com"
                    name="email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h6>Pessoa de referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.nome">Nome</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.nome"
                    placeholder="Nome completo da pessoa"
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.pessoa?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.nome?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.data_nascimento">
                    Nascimento
                  </label>
                  <input
                    type="date"
                    className={
                      errors.responsavel?.pessoa?.data_nascimento
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.data_nascimento"
                    ref={register({
                      required: true,
                      validate: (value) => value <= parseDate2(new Date()),
                    })}
                  />
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "validate" && (
                    <span className="error-message">Inserir data passada</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.cargo">Cargo/Função</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.cargo
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.cargo"
                    placeholder="Ex: Presidente, Coordenador..."
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.cargo?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.cargo?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.telefone"
                    placeholder="(99)99999-9999"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.responsavel?.email
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="exemplo@email.com"
                    name="responsavel.email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.responsavel?.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                </CustomInput>
              </div>
            </div>
          </>
        ) : (
          //Formulário para OSC

          <>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="razao_social">Razão social</label>
                  <input
                    className={
                      errors.razao_social
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="Razão social"
                    name="razao_social"
                    ref={register({
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  {errors.razao_social?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.razao_social?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 100
                      caracteres
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="telefone"
                    placeholder="(99)99999-9999"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.telefone?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.telefone?.type === "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="cnpj">CNPJ (opcional)</label>
                  <Controller
                    className={
                      errors.cnpj ? "CustomInput error-input" : "CustomInput"
                    }
                    name="cnpj"
                    as={InputMask}
                    control={control}
                    mask={CNPJMask.mask}
                    maskChar=""
                    placeholder="99.999.999/9999-99"
                    rules={{
                      required: false,
                      minLength: CNPJMask.maxLength,
                    }}
                  />
                  {errors.cnpj?.type === "minLength" && (
                    <span className="error-message">
                      Campo deve conter 14 dígitos
                    </span>
                  )}
                  {errors.cnpj && (
                    <span className="error-message">{errors.cnpj.message}</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.email ? "CustomInput error-input" : "CustomInput"
                    }
                    placeholder="exemplo@email.com"
                    name="email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h6>Endereço</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.cep">CEP</label>
                  <Controller
                    className={
                      errors.endereco?.cep
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    as={InputMask}
                    name="endereco.cep"
                    control={control}
                    mask="99999-999"
                    maskChar=""
                    placeholder="99999-999"
                    rules={{
                      required: true,
                      minLength: 9,
                    }}
                  />
                  {errors.endereco?.cep?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.endereco?.cep?.type === "minLength" && (
                    <span className="error-message">Deve conter 8 dígitos</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.rua">Logradouro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.rua
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.rua"
                    ref={register({ required: true })}
                    placeholder={"Av Maria Lacerda"}
                  />
                  {errors.endereco?.rua?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.numero">Número</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.numero
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.numero"
                    ref={register()}
                    defaultValue="s/n"
                    onChange={(e) =>
                      (e.target.value = e.target.value.toLowerCase())
                    }
                  />
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.bairro">Bairro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.bairro
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.bairro"
                    ref={register({ required: true })}
                    placeholder={"Centro"}
                  />
                  {errors.endereco?.bairro?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.localidade.nome">Localidade</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.localidade
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.localidade.nome"
                    ref={register({ required: true })}
                    placeholder={"Comunidade Maria"}
                  />
                  {errors.endereco?.localidade?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <div className="label">
                  <label htmlFor="endereco.municipio">Município</label>
                  <Controller
                    name="endereco.municipio"
                    placeholder={"Selecione um município"}
                    rules={{ required: true }}
                    control={control}
                    className={errors.endereco?.municipio ? "error-input" : ""}
                    as={Select}
                    menuPlacement="top"
                    options={municipiosOptions}
                    styles={customStyles}
                    noOptionsMessage={() => "Nenhum município encontrado"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  {errors.endereco?.municipio && (
                    <span className="error-message">
                      Selecione um município
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h6>Pessoa de referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.nome">Nome</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.nome"
                    placeholder="Nome completo da pessoa"
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.pessoa?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.nome?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.data_nascimento">
                    Nascimento
                  </label>
                  <input
                    type="date"
                    className={
                      errors.responsavel?.pessoa?.data_nascimento
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.data_nascimento"
                    ref={register({
                      required: true,
                      validate: (value) => value <= parseDate2(new Date()),
                    })}
                  />
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "validate" && (
                    <span className="error-message">Inserir data passada</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.cargo">Cargo/Função</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.cargo
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.cargo"
                    placeholder="Ex: Presidente, Coordenador..."
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.cargo?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.cargo?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.telefone"
                    placeholder="(99)99999-9999"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.responsavel?.email
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="exemplo@email.com"
                    name="responsavel.email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.responsavel?.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <h6>Endereço Pessoa de Referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.cep">CEP</label>
                  <Controller
                    className={
                      errors.responsavel?.endereco?.cep
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    as={InputMask}
                    name="responsavel.endereco.cep"
                    control={control}
                    mask="99999-999"
                    maskChar=""
                    placeholder="99999-999"
                    rules={{
                      required: true,
                      minLength: 9,
                    }}
                  />
                  {errors.responsavel?.endereco?.cep?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.endereco?.cep?.type === "minLength" && (
                    <span className="error-message">Deve conter 8 dígitos</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.rua">Logradouro</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.rua
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.rua"
                    ref={register({ required: true })}
                    placeholder={"Av Maria Lacerda"}
                  />
                  {errors.responsavel?.endereco?.rua?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.numero">Número</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.numero
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.numero"
                    ref={register()}
                    defaultValue="s/n"
                    onChange={(e) =>
                      (e.target.value = e.target.value.toLowerCase())
                    }
                  />
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.bairro">Bairro</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.bairro
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.bairro"
                    ref={register({ required: true })}
                    placeholder={"Centro"}
                  />
                  {errors.responsavel?.endereco?.bairro?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.localidade.nome">
                    Localidade
                  </label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.localidade
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.localidade.nome"
                    ref={register({ required: true })}
                    placeholder={"Comunidade Maria"}
                  />
                  {errors.responsavel?.endereco?.localidade?.nome?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <div className="label">
                  <label htmlFor="responsavel.endereco.municipio">
                    Município
                  </label>
                  <Controller
                    name="responsavel.endereco.municipio"
                    placeholder={"Selecione um município"}
                    rules={{ required: true }}
                    control={control}
                    className={
                      errors.responsavel?.endereco?.municipio
                        ? "error-input"
                        : ""
                    }
                    as={Select}
                    menuPlacement="top"
                    options={municipiosOptions}
                    styles={customStyles}
                    noOptionsMessage={() => "Nenhum município encontrado"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  {errors.responsavel?.endereco?.municipio && (
                    <span className="error-message">
                      Selecione um município
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
