import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import Spinner from "../../../../../assets/icons/spinner.svg";
import api from "../../../../../services/api";
import Modal from "../../../../../template/components/Modal";
import { CustomInput } from "../../../../../template/styles/styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { parseCPF, parseDate2 } from "../../../../../template/utils/parser";
import { GestorLaticinioInterface } from "../../../../../template/utils/types";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../../../Laticinios/components/CadastrarModal/styles";
import { Laticinio } from "../../Laticinio";
import { FormContainer, customStyles } from "../../styles";
import { ErrorMessage, SaveButtonContainer, SpinnerContainer } from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  loadGestoresLaticinios: () => void;
}

const GestorLaticinio: React.FC<IProps> = (props) => {
  const { register, getValues, errors, setValue, handleSubmit } =
    useForm<GestorLaticinioInterface>({ mode: "onBlur" });
  const [isValidatingCPF, setIsValidatingCPF] = useState(false);
  const [gestorSelected, setGestorSelected] = useState<number>(-1);
  const [editarGestor, setEditarGestor] = useState<Boolean>(false);
  const [cpfGestor, setCpfGestor] = useState<any>("");
  const [telefoneGestor, setTelefoneGestor] = useState<any>("");
  const [gestorAtual, setGestorAtual] =
    useState<GestorLaticinioInterface>(Object);
  const [gestoresLaticinios, setGestoresLaticinios] = useState<
    GestorLaticinioInterface[]
  >([]);
  const [selectedOptionLaticinio, setSelectedOptionLaticinio] =
    useState<Option | null>(null);
  const [laticinios, setLaticinios] = useState<Laticinio[]>([]);
  const [laticiniosOptions, setLaticiniosOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const onSubmit = (data: any) => {
    setIsLoading(true);

    if (selectedOptionLaticinio === null) {
      notify("error", `Laticínio não selecionado`);
      setIsLoading(false);
      return;
    }

    const gestorLaticinio = {
      ...data,
      tipo_usuario: 3,
      laticinio: selectedOptionLaticinio.value,
      documento: {
        numero: data.documento.numero.replace(/\D/g, ""),
        tipo: 1,
      },
    };

    api
      .post(`/gestores_laticinio/`, gestorLaticinio)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Gestor Laticínio cadastrado com sucesso!`);
        //Fecha o modal
        setIsLoading(false);
        props.toggle();
        props.loadGestoresLaticinios();
      })
      .catch(function (error) {
        if (error.response.data?.usuario?.username) {
          notify("error", `Usuário informado já existe`);
        } else if (error.response.data.numero) {
          notify("error", `CPF de gestor latícinio já cadastrado`);
        } else if (error.response.data.cpf) {
          notify("error", `${error.response.data.cpf[0]}`);
        } else if (error.response.data.gestores) {
          notify("error", `${error.response.data.gestores}`);
        } else {
          notify("Error", `Gestor Laticínio não pôde ser cadastrado!`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    (async function () {
      //Carrega os laticínios da API
      const { data } = await api.get<Laticinio[]>("laticinios/");
      setLaticinios(data);
    })();
  }, []);

  type Option = {
    value: number;
    label: string;
  };

  useEffect(() => {
    const options: Option[] = [];
    laticinios?.map((lat) => options.push({ value: lat.id, label: lat.nome }));
    setLaticiniosOptions(options);
  }, [laticinios]);

  useEffect(() => {
    if (gestorSelected !== -1) detailToUpdate();
  }, [gestorSelected]);

  function handleCPF(cpf: any) {
    setCpfGestor(
      cpf
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    );
  }

  function handleTelefone(tel: any) {
    setTelefoneGestor(
      tel
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "($1)$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1")
    );
  }

  function validarEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //Preenche os campos do formulário do gestor laticínio com as informações do estado de gestorAtual
  const toggleFormState = () => {
    setCpfGestor(parseCPF(gestorAtual?.documento?.numero));
    setTelefoneGestor(gestorAtual?.pessoa?.telefone);
    setValue("pessoa.nome", gestorAtual?.pessoa?.nome, {
      shouldDirty: true,
    });
    setValue("pessoa.data_nascimento", gestorAtual?.pessoa?.data_nascimento, {
      shouldDirty: true,
    });
    setValue("pessoa.telefone", gestorAtual?.pessoa?.telefone, {
      shouldDirty: true,
    });
    setValue("usuario.username", gestorAtual?.documento?.numero, {
      shouldDirty: true,
    });
    setValue("usuario.email", gestorAtual?.usuario?.email, {
      shouldDirty: true,
    });
    setValue("usuario.password", gestorAtual?.usuario?.password, {
      shouldDirty: true,
    });
    setValue("usuario.check_password", gestorAtual?.usuario?.check_password, {
      shouldDirty: true,
    });
    setValue("documento.numero", gestorAtual?.documento?.numero, {
      shouldDirty: true,
    });
  };
  const validarCPF = async (data: any, tipo: any) => {
    setIsLoading(true);
    setIsValidatingCPF(true);
    let dadoExistente = false;
    let cpf = {
      numero: data.replace(/[.-]/g, "").trim(),
      tipo: 1,
    };
    for (let i = 0; i < gestoresLaticinios.length; i++) {
      if (
        gestoresLaticinios[i].documento.numero.replace(/[.-]/g, "").trim() ===
          cpf.numero &&
        i !== gestorSelected
      ) {
        dadoExistente = true;
        break;
      }
    }
    let dado = await api.post(`documentos/valida/`, cpf);
    if (tipo === 1) {
      setIsLoading(false);
      setIsValidatingCPF(false);
      return dado.data.valido;
    } else if (dadoExistente) {
      setIsLoading(false);
      setIsValidatingCPF(false);
      return false;
    } else {
      setIsLoading(false);
      setIsValidatingCPF(false);
      return !dado.data.existente;
    }
  };

  function detailToUpdate() {
    setEditarGestor(true);
    toggleFormState();
  }

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={props.toggle} disabled={isLoading}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave type="submit" form="cadastrarForm" disabled={isLoading}>
          <SaveButtonContainer>
            {isLoading ? (
              <SpinnerContainer height="auto">
                <img src={Spinner} alt="carregando" height={15} width={15} />
              </SpinnerContainer>
            ) : (
              <CustomAiOutlineCheck />
            )}

            <span>Salvar</span>
          </SaveButtonContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => {
        if (!isLoading) props.toggle();
      }}
      fixed={true}
      modalTitle={`Cadastrar novo Gestor Laticínio`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.nome">Nome</label>
              <input
                type="text"
                className={
                  errors.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.nome"
                placeholder="Ex: João da Silva"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
                onChange={(e) =>
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      nome: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.pessoa?.nome?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.pessoa?.nome?.type === "maxLength" && (
                <ErrorMessage className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres.
                </ErrorMessage>
              )}
            </CustomInput>
          </div>

          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.username">Nome de usuário</label>
              <input
                type="text"
                className={
                  errors.usuario?.username
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="usuario.username"
                placeholder="Ex: João"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
                onChange={(e) =>
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      nome: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.usuario?.username?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.usuario?.username?.type === "maxLength" && (
                <ErrorMessage>
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres.
                </ErrorMessage>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.email">Email</label>
              <input
                type="email"
                className={
                  errors.usuario?.email
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="usuario.email"
                placeholder="exemplo@email.com"
                ref={register({
                  required: true,
                  validate: (value) => validarEmail(value) === true,
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      email: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.usuario?.email?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.usuario?.email?.type === "validate" && (
                <ErrorMessage>Inserir email válido</ErrorMessage>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.telefone">Telefone (Opcional)</label>
              <input
                type="text"
                className={
                  errors.pessoa?.telefone
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.telefone"
                autoComplete="on"
                placeholder="(99)99999-9999"
                ref={register({
                  required: false,
                  minLength: 14,
                })}
                value={telefoneGestor}
                onChange={(e: any) => {
                  handleTelefone(e.currentTarget.value);
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      telefone: e.currentTarget.value,
                    },
                  });
                }}
              />
              {errors.pessoa?.telefone?.type === "minLength" && (
                <ErrorMessage>
                  Se preenchido, campo deve conter 11 dígitos
                </ErrorMessage>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.password">Senha</label>
              <input
                type="password"
                className={
                  errors.usuario?.password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="usuario.password"
                autoComplete="new-password"
                placeholder="Mínimo 6 dígitos"
                ref={register({
                  required: true,
                  minLength: 6,
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      password: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.usuario?.password?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.usuario?.password?.type === "minLength" && (
                <ErrorMessage>
                  A senha deve ter no mínimo 6 dígitos
                </ErrorMessage>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="usuario.check_password">Repetir senha</label>
              <input
                type="password"
                autoComplete="new-password"
                className={
                  errors.usuario?.check_password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                minLength={6}
                name="usuario.check_password"
                placeholder="Mínimo 6 dígitos"
                ref={register({
                  required: true,
                  validate: (value) => value === getValues("usuario.password"),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    usuario: {
                      ...gestorAtual.usuario,
                      check_password: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.usuario?.check_password?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.usuario?.check_password?.type === "validate" && (
                <ErrorMessage>
                  A senha de confirmação não é igual à senha digitada
                </ErrorMessage>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="documento.numero">CPF</label>
              <input
                type="text"
                className={
                  errors.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="documento.numero"
                placeholder="999.999.999-99"
                ref={register({
                  required: true,
                  minLength: 14,
                  validate: {
                    valido: (value) => validarCPF(value, 1),
                    unico: (value) => validarCPF(value, 2),
                  },
                })}
                value={cpfGestor}
                onChange={(e: any) => {
                  handleCPF(e.currentTarget.value);
                  setGestorAtual({
                    ...gestorAtual,
                    documento: {
                      ...gestorAtual.documento,
                      numero: e.currentTarget.value,
                    },
                  });
                }}
              />
              {isValidatingCPF && (
                <SaveButtonContainer justifyContent="flex-start">
                  <img src={Spinner} alt="carregando" height={15} width={15} />
                  <span>Validando CPF</span>
                </SaveButtonContainer>
              )}
              {errors.documento?.numero?.type === "required" &&
                !isValidatingCPF && (
                  <ErrorMessage>Campo obrigatório</ErrorMessage>
                )}
              {errors.documento?.numero?.type === "minLength" &&
                !isValidatingCPF && (
                  <ErrorMessage>Deve conter 11 dígitos</ErrorMessage>
                )}
              {errors.documento?.numero?.type === "valido" &&
                !isValidatingCPF && <ErrorMessage>CPF inválido</ErrorMessage>}
              {errors.documento?.numero?.type === "unico" &&
                !isValidatingCPF && (
                  <ErrorMessage>CPF já cadastrado</ErrorMessage>
                )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_expiracao">Data de Expiração</label>
              <input
                type="date"
                className={
                  errors.data_expiracao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="data_expiracao"
                ref={register({
                  required: true,
                  validate: (value) => value > parseDate2(new Date()),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    data_expiracao: e.currentTarget.value,
                  })
                }
              />
              {errors.data_expiracao?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.data_expiracao?.type === "validate" && (
                <ErrorMessage>Inserir data futura</ErrorMessage>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="laticinio">Laticínio</label>
              <Select
                placeholder="Selecione"
                styles={customStyles}
                className={errors.laticinio ? "error-input" : ""}
                defaultValue={{
                  value: 0,
                  label: "Vincular um laticínio ao Gestor Laticínio",
                }}
                options={laticiniosOptions}
                value={selectedOptionLaticinio}
                onChange={(val) => {
                  setSelectedOptionLaticinio(val);
                }}
                noOptionsMessage={() => "Nenhum laticínio encontrado"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              {errors.laticinio && (
                <ErrorMessage>Selecione um laticínio</ErrorMessage>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.data_nascimento">Nascimento</label>
              <input
                type="date"
                className={
                  errors.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
                onChange={(e: any) =>
                  setGestorAtual({
                    ...gestorAtual,
                    pessoa: {
                      ...gestorAtual.pessoa,
                      data_nascimento: e.currentTarget.value,
                    },
                  })
                }
              />
              {errors.pessoa?.data_nascimento?.type === "required" && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
              {errors.pessoa?.data_nascimento?.type === "validate" && (
                <ErrorMessage>Inserir data passada</ErrorMessage>
              )}
            </CustomInput>
          </div>
        </div>
      </FormContainer>
    </Modal>
  );
};

export default GestorLaticinio;
