import React from "react";

import Modal from "../../../../../template/components/Modal";

import DetalharForm from "../DetalharForm";

import { ButtonClose, CustomAiOutlineClose } from "./styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadProdutores: () => void;
}

const DetalharModal = ({ row, isOpen, toggle, loadProdutores }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
      </React.Fragment>
    );
  };

  return (
    row && (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        fixed={true}
        modalTitle={`Visualizando informações do produtor ${row.pessoa.nome}`}
        footerContent={footerButtons()}
        className="modal-lg"
      >
        <DetalharForm
          row={row}
          toggle={toggle}
          loadProdutores={loadProdutores}
        />
      </Modal>
    )
  );
};

export default DetalharModal;
