import styled from "styled-components";
import colors from "../../../../../template/styles/colors";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }
`;

export const PlusButton = styled.button`
  font-size: 35px;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  border: none;
  display: flex;
  color: ${colors.bluePLP};
  background-color: transparent;
  :hover {
    color: ${colors.blueOfficial};
  }
`;

export const AddFormButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px 5px 10px;
  height: 39px;
  font-size: 14px;
  vertical-align: middle;
  align-items: center;
  color: white;
  background-color: ${colors.bluePLP};

  :focus {
    outline: 0;
  }

  :hover {
    text-decoration: none;
    color: ${colors.blueOfficial};
  }
`;

export const SaveFormButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  width: 90px;
  border-radius: 8px;
  padding: 0 10px;
  min-height: 39px;
  font-size: 14px;
  vertical-align: middle;
  align-items: center;
  color: white;
  background-color: ${colors.greenOfficial};

  :focus {
    outline: 0;
  }

  :hover {
    text-decoration: none;
    color: ${colors.lightGrey};
  }
`;

export const RemoveButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  width: 90px;
  border-radius: 8px;
  margin-right: 10px;
  padding: 0 5px;
  min-height: 39px;
  font-size: 14px;
  vertical-align: middle;
  align-items: center;
  color: ${colors.light};
  background-color: ${colors.redError};
  :focus {
    outline: 0;
  }
  :hover {
    text-decoration: none;
    color: ${colors.ultraDarkGrey};
  }
`;

export const AddGestor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 15px;
  margin-bottom: 5px;

  .rowScroll {
    width: 100%;
    height: fit-content;
    max-height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.darkGrey};
      border-radius: 6px;
    }
  }

  .scrollItem {
    margin-top: 15px;
    margin: 10px 0 0 10px;
    padding-top: 13px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 4px;
    list-style: none;
  }

  .remove {
    margin-left: 85%;
    margin-bottom: 10px;
  }
`;
