import React from "react";

import Modal from "../../../../template/components/Modal";

import {
  ButtonSave,
  ButtonClose,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  qtdFamilias: number;
  restaurarFamilia: () => void;
}

const RestaurarModal = ({
  isOpen,
  toggle,
  qtdFamilias,
  restaurarFamilia,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave onClick={restaurarFamilia}>
          <CustomAiOutlineCheck /> Restaurar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle="Restaurar Situação"
      footerContent={footerButtons()}
    >
      <>
        {qtdFamilias > 1 ? (
          <div>
            Deseja restaurar as situações das {qtdFamilias} famílias
            selecionadas?
          </div>
        ) : (
          <div>Deseja restaurar a situação da família selecionada?</div>
        )}
      </>
    </Modal>
  );
};

export default RestaurarModal;
