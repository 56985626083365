import React, { useCallback, useEffect, useState } from "react";
import api from "../../../services/api";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import { GoPlus } from "react-icons/go";
import { Container, CustomReactSelect } from "./styles";
import { dialogBox } from "../../../template/utils/dialogBox";
import { GestorEstadualInterface } from "../../../template/utils/types";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Pagination from "../../../template/components/Pagination";
import { FaRegBuilding } from "react-icons/fa";
import DetalharModal from "./components/DetalharModal";
import CadastrarModal from "./components/CadastrarModal";
import { FiUsers } from "react-icons/fi";
import { CustomSpinner } from "../../Relatorios/DistribuicoesPontos/styles";

const EntidadesParceiras = () => {
  const [entidadeParceira, setEntidadeParceira] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [entidadeParceiraEditar, setEntidadeParceiraEditar] =
    useState<GestorEstadualInterface>(Object);
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(-1);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>({
    value: -1,
    label: "Todos os municípios",
  });
  const itemsPerPage = 20;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [{ label: "Todos os municípios", value: -1 }];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setSelectOptions(options);
  }, [municipios]);

  // Cabeçalho da tabela
  const fields = [
    "Razão social",
    "CNPJ",
    "Email",
    "Telefone",
    "Responsável",
    "Ação",
  ];

  const loadEntidadeCooperada = useCallback(async () => {
    //Carrega os pontos da API de Acari
    try {
      const { data } = await api.get(
        `entidades_cooperadas/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}
        &municipio=${
          municipioSelected > -1 ? municipioSelected : ""
        }&ordering=-updated_at`
      );
      const { results, count } = data;
      setEntidadeParceira(
        parseJsonToTableDataList(parseEntidadeCooperada(results), [
          "razao_social",
          "cnpj",
          "email",
          "telefone",
          "responsavel",
        ])
      );
      setTotal(count);

      //Verifica se não há entidades
      if (count === 0) {
        setIsSearching(false);
        notify("warning", "Sem entidades cadastradas");
        return;
      }
    } catch (error) {
      notify("error", "Erro no servidor");
    }
  }, [offset, searchedValue, municipioSelected, notify]);

  useEffect(() => {
    loadEntidadeCooperada();
  }, [loadEntidadeCooperada]);

  // Parse Entidades
  function parseEntidadeCooperada(entidadeCooperada: any[]) {
    let tmp = [];
    for (var i = 0; i < entidadeCooperada.length; i++) {
      const object = {
        id: entidadeCooperada[i].id,
        razao_social: entidadeCooperada[i].razao_social,
        cnpj: entidadeCooperada[i].cnpj,
        email: entidadeCooperada[i].email,
        telefone: entidadeCooperada[i].telefone,
        responsavel: entidadeCooperada[i].nome_responsavel,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  useEffect(() => {
    setIsSearching(false);
  }, [entidadeParceira]);

  //Paginação

  // Aumenta o total quando um gestor é criado
  /*   const increaseTotal = useCallback(() => {
    let newTotal = total + 1;
    setTotal(newTotal);
  }, [total]); */

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`entidades_cooperadas/${row.id}/`);
    setEntidadeParceiraEditar(data);
    toggleDetalhar();
  };

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  return (
    <Layout>
      <MainContainer titlePage="Entidades Parceiras" iconPage={<FiUsers />}>
        <Container>
          {modalDetalhar ? (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              row={entidadeParceiraEditar}
              loadEntidadeCooperada={loadEntidadeCooperada}
            />
          ) : (
            ""
          )}
          {modalCadastrar ? (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              loadEntidadeCooperada={loadEntidadeCooperada}
            />
          ) : (
            ""
          )}
          <div className="row2">
            <div>
              <Button
                color="green"
                name="Cadastrar Entidade"
                iconButtom={<GoPlus />}
                onClick={() => toggleCadastrar()}
              />
            </div>
          </div>
          <span className="space"></span>
          <div className="row pl-0">
            <div className="d-flex">
              <CustomReactSelect
                name="municipiosSelect"
                className="space"
                value={selectedOption}
                options={selectOptions}
                onChange={(e: any) => {
                  setIsSearching(true);
                  setSelectedOption({
                    value: parseInt(e.value),
                    label: e.label,
                  });
                  setMunicipioSelected(e.value);
                  first();
                }}
                noOptionsMessage={() => "Nenhum município encontrado"}
              />
              {isSearching && <CustomSpinner />}
            </div>
            <div className="searchBar space">
              <SearchBar placeholder="Razão social" submitHandler={search} />
            </div>
          </div>
          <Table
            fields={fields}
            rows={entidadeParceira}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[{ name: "     Editar", action: detalhar }]}
          />
          <div className="containerFooterTable">
            Visualizando {entidadeParceira.length} de um total de {total}{" "}
            registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};
export default EntidadesParceiras;
