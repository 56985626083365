import React, { useCallback, useEffect, useState } from "react";
import api from "../../../services/api";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import { GoPlus } from "react-icons/go";
import {
  Container,
  CustomIconDesabilitar,
  CustomReactSelect,
  CustomIconHabilitar,
} from "./styles";
import { dialogBox } from "../../../template/utils/dialogBox";
import { LaticinioInterface } from "../../../template/utils/types";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Pagination from "../../../template/components/Pagination";
import { FaRegBuilding } from "react-icons/fa";
import DetalharModal from "./components/DetalharModal";
import CadastrarModal from "./components/CadastrarModal";
import AtivoModal from "./components/AtivoModal";

const Laticinios = () => {
  const [laticinio, setLaticinio] = useState<any[]>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [laticinioEditar, setLaticinioEditar] =
    useState<LaticinioInterface>(Object);
  const itemsPerPage = 20;
  const [isLoadingDetalhar, setIsLoadingDetalhar] = useState(false);
  const [modalAtivo, setmodalAtivo] = useState(false);
  const [status, setStatus] = useState(true);
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  // Cabeçalho da tabela
  const fields = ["Nome", "CNPJ", "Responsável", "Ação"];

  const loadLaticinios = useCallback(async () => {
    //Carrega os laticínios
    try {
      const { data } = await api.get(
        `laticinios/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ativo=${status}&ordering=-updated_at`
      );
      const { results, count } = data;
      setLaticinio(
        parseJsonToTableDataList(parseLaticinios(results), [
          "nome",
          "cnpj",
          "responsavel",
        ])
      );
      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
  }, [offset, searchedValue, status, notify]);

  const detalharAtivo = async (row: any) => {
    setIsLoadingDetalhar(true);
    toggleDesabilitar();
    const { data } = await api.get(`/laticinios/${row.id}/`);
    setLaticinioEditar(data);
    setIsLoadingDetalhar(false);
  };
  const toggleDesabilitar = () => {
    setmodalAtivo(!modalAtivo);
  };

  useEffect(() => {
    loadLaticinios();
  }, [loadLaticinios]);

  // Parse Laticinios
  function parseLaticinios(laticinios: any[]) {
    let tmp = [];
    for (var i = 0; i < laticinios.length; i++) {
      let responsavelNome;
      //Verifica se já existe funcionário para poder mostrar laticínios que não possuem um ainda.
      if (laticinios[i].responsavel == null) {
        responsavelNome = "";
      } else {
        responsavelNome = laticinios[i].responsavel.nome;
      }
      const object = {
        id: laticinios[i].id,
        nome: laticinios[i].nome,
        cnpj: laticinios[i].cnpj,
        responsavel: responsavelNome,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  //Paginação

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[./-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`laticinios/${row.id}/`);
    setLaticinioEditar(data);
    toggleDetalhar();
  };

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };
  const handleStatus = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === "select-option" && selectedOption) {
      const value = selectedOption.value;
      setStatus(value);
    }
  };
  return (
    <Layout>
      <MainContainer titlePage="Laticínios" iconPage={<FaRegBuilding />}>
        <Container>
          {modalDetalhar ? (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              laticinioSelecionado={laticinioEditar}
              loadLaticinios={loadLaticinios}
            />
          ) : (
            ""
          )}
          {modalCadastrar ? (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              loadLaticinios={loadLaticinios}
            />
          ) : (
            ""
          )}
          {modalAtivo && (
            <AtivoModal
              isOpen={modalAtivo}
              toggle={toggleDesabilitar}
              row={laticinioEditar}
              loadLaticinios={loadLaticinios}
              setIsLoading={setIsLoadingDetalhar}
              isLoading={isLoadingDetalhar}
            />
          )}

          <div className="row">
            <div>
              <Button
                color="green"
                name="Cadastrar Laticínio"
                iconButtom={<GoPlus />}
                onClick={() => toggleCadastrar()}
              />
            </div>
            <span className="space"></span>
            <div>
              <CustomReactSelect
                name="status"
                className="select space"
                defaultValue={{ value: true, label: "Ativos" }}
                options={[
                  { value: true, label: "Ativos" },
                  { value: false, label: "Inativos" },
                ]}
                onChange={handleStatus}
              />
            </div>

            <div className="searchBar space">
              <SearchBar placeholder="Nome ou CNPJ" submitHandler={search} />
            </div>
          </div>
          <Table
            fields={fields}
            rows={laticinio}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[
              { name: "     Editar", action: detalhar },

              {
                name: status ? "Desativar" : "Ativar",
                action: detalharAtivo,
                iconBtn: status ? (
                  <CustomIconDesabilitar />
                ) : (
                  <CustomIconHabilitar />
                ),
              },
            ]}
          />
          <div className="containerFooterTable">
            Visualizando {laticinio.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Laticinios;
