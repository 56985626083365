import React from "react";
import brasaoRN from "../../template/assets/Brasão_do_Rio_Grande_do_Norte.png";
import Layout from "../../template/components/Layout";
import { BrasaoRN } from "../../template/components/MainContainer/styles";

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <BrasaoRN src={brasaoRN} />
    </Layout>
  );
};

export default Dashboard;
