import React from "react";

import Modal from "../../../../../template/components/Modal";
import CadastrarForm from "../CadastrarForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  loadLaticinios: () => void;
}

const CadastrarModal = ({ isOpen, toggle, loadLaticinios }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave type="submit" form="cadastrarForm">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={`Cadastrar novo Laticínio`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <CadastrarForm toggle={toggle} loadLaticinios={loadLaticinios} />
    </Modal>
  );
};

export default CadastrarModal;
