import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Vincular from "./pages/Vincular";
import Restaurar from "./pages/Restaurar";
import RelatorioEntregasLaticinio from "./pages/Relatorios/EntregasLaticinio";
import Pontos from "./pages/Gerenciar/Pontos";
import Familias from "./pages/Gerenciar/Familias";
import EntidadesBeneficiarias from "./pages/Gerenciar/EntidadesBeneficiarias";
import EntidadesParceiras from "./pages/Gerenciar/EntidadesParceiras";
import Usuarios from "./pages/Gerenciar/Usuarios";

import Login from "./template/components/Login";

import { isAuthenticated } from "./services/auth";
import Laticinios from "./pages/Gerenciar/Laticinios";
import RelatorioDistribuicoesPontos from "./pages/Relatorios/DistribuicoesPontos";
import FamiliasVinculadas from "./pages/FamiliasVinculadas";
import RelatorioDoacoes from "./pages/Relatorios/Doacoes";
import RelatorioPresenca from "./pages/Relatorios/Presenca";

import Produtores from "./pages/Gerenciar/Produtores";
import ProdutoresPendentes from "./pages/Gerenciar/ProdutoresPendentes";
import ProdutoresCadastrar from "./pages/Gerenciar/ProdutoresCadastro";
import GestorLaticinio from "./pages/Gerenciar/GestorLaticinios";

interface Iprops {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: Iprops) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
//<Login logoName="logotest1" initialPagePath="/dashboard" />
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Login
              logoName="logotest1"
              initialPagePath="/dashboard"
              subtitleModulo="Gestor Estadual"
            />
          )}
        />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/familias_espera" component={Vincular} />
        <PrivateRoute path="/familias_inaptas" component={Restaurar} />
        <PrivateRoute
          path="/familias_vinculadas"
          component={FamiliasVinculadas}
        />
        <PrivateRoute
          path="/relatorio_distribuicoes_ponto"
          component={RelatorioDistribuicoesPontos}
        />
        <PrivateRoute path="/relatorio_doacoes" component={RelatorioDoacoes} />
        <PrivateRoute
          path="/relatorio_presenca"
          component={RelatorioPresenca}
        />
        <PrivateRoute
          path="/relatorio_entregas_laticinio"
          component={RelatorioEntregasLaticinio}
        />
        <PrivateRoute path="/gerenciar_pontos" component={Pontos} />
        <PrivateRoute path="/buscar_familias" component={Familias} />
        <PrivateRoute
          path="/gerenciar_entidades_beneficiarias"
          component={EntidadesBeneficiarias}
        />
        <PrivateRoute
          path="/gerenciar_entidades_parceiras"
          component={EntidadesParceiras}
        />
        <PrivateRoute path="/gerenciar_usuarios" component={Usuarios} />
        <PrivateRoute path="/gestor_laticinios" component={GestorLaticinio} />
        <PrivateRoute path="/gerenciar_laticinios" component={Laticinios} />
        <PrivateRoute path="/listar_produtores" component={Produtores} />
        <PrivateRoute
          path="/cadastrar_produtores"
          component={ProdutoresCadastrar}
        />

        <PrivateRoute
          path="/produtores_pendentes"
          component={ProdutoresPendentes}
        />
        <Route path="*" component={() => <h1>Página não encontrada</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
