import React, { useCallback, useEffect, useState } from "react";
import api from "../../../services/api";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import { GoPlus } from "react-icons/go";
import { Container, CustomReactSelect } from "./styles";
import { dialogBox } from "../../../template/utils/dialogBox";
import { UsuarioGeralInterface } from "../../../template/utils/types";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Pagination from "../../../template/components/Pagination";
import { FaRegBuilding } from "react-icons/fa";
import DetalharModal from "./components/DetalharModal";
import CadastrarModal from "./components/CadastrarModal";
import { FiUsers } from "react-icons/fi";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [papel, setPapel] = useState<Number>(0);
  const [opcaoExpiracao, setOpcaoExpiracao] = useState<Number>(0);
  const [selectOptions1, setSelectOptions1] = useState<any>([]);
  const [selectOptions2, setSelectOptions2] = useState<any>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [usuarioEditar, setUsuarioEditar] =
    useState<UsuarioGeralInterface>(Object);
  const itemsPerPage = 20;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  // Cabeçalho da tabela
  const fields = ["Nome", "CPF", "Telefone", "E-mail", "Expiração", "Ação"];

  const loadUsuarios = useCallback(async () => {
    //Carrega os pontos da API de Acari
    try {
      let papelEscolhido = "";
      if (papel === 0) {
        papelEscolhido = "has_role";
      } else if (papel === 1) {
        papelEscolhido = "is_gestor_estadual";
      } else if (papel === 2) {
        papelEscolhido = "is_gestor_local";
      } else {
        papelEscolhido = "is_colaborador_ponto";
      }

      let expiracaoEscolhida = "";
      if (opcaoExpiracao === 0) {
        expiracaoEscolhida = "todos";
      } else if (opcaoExpiracao === 1) {
        expiracaoEscolhida = "expirados";
      } else {
        expiracaoEscolhida = "nao_expirados";
      }
      const { data } = await api.get(
        `usuarios/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&expiracao=${expiracaoEscolhida}&${papelEscolhido}=true&is_not_gestor_laticinio=true&ordering=-updated_at`
      );
      const { results, count } = data;
      setUsuarios(
        parseJsonToTableDataList(parseUsuarios(results), [
          "nome",
          "cpf",
          "telefone",
          "email",
          "expiracao",
        ])
      );
      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
  }, [offset, searchedValue, papel, opcaoExpiracao, notify]);

  useEffect(() => {
    loadUsuarios();
  }, [loadUsuarios]);

  useEffect(() => {
    const options1: any = [
      { label: "Todos", value: 0 },
      { label: "Gestor estadual", value: 1 },
      { label: "Gestor local", value: 2 },
      { label: "Colaborador do ponto", value: 3 },
    ];
    setSelectOptions1(options1);

    const options2: any = [
      { label: "Todos", value: 0 },
      { label: "Expirados", value: 1 },
      { label: "Não expirados", value: 2 },
    ];
    setSelectOptions2(options2);
  }, []);

  // Parse Gestores
  function parseUsuarios(usuarios: any[]) {
    let tmp = [];
    for (var i = 0; i < usuarios.length; i++) {
      const object = {
        id: usuarios[i].id_user,
        nome: usuarios[i]?.pessoa.nome.toUpperCase(),
        telefone: usuarios[i].pessoa.telefone
          ? usuarios[i].pessoa.telefone
          : "NÃO CADASTRADO",
        email: usuarios[i]?.email,
        cpf: usuarios[i]?.documento.numero,
        expiracao: GetFormatedDate(usuarios[i]?.data_expiracao),
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  const GetFormatedDate = (date: Date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  //Paginação

  // Aumenta o total quando um gestor é criado
  /*   const increaseTotal = useCallback(() => {
    let newTotal = total + 1;
    setTotal(newTotal);
  }, [total]); */

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`usuarios/${row.id}/`);
    setUsuarioEditar(data);
    toggleDetalhar();
  };

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  return (
    <Layout>
      <MainContainer titlePage="Usuários" iconPage={<FiUsers />}>
        <Container>
          {modalDetalhar && (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              row={usuarioEditar}
              loadUsuarios={loadUsuarios}
            />
          )}
          {modalCadastrar ? (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              loadUsuarios={loadUsuarios}
            />
          ) : (
            ""
          )}
          <div className="row">
            <div>
              <Button
                color="green"
                name="Cadastrar Usuário"
                iconButtom={<GoPlus />}
                onClick={() => toggleCadastrar()}
              />
            </div>
            <CustomReactSelect
              name="papelSelecionado"
              className="space"
              options={selectOptions1}
              defaultValue={{ label: "Todos", value: 0 }}
              onChange={(e: any) => {
                setPapel(e.value);
                first();
              }}
              noOptionsMessage={() => "Nenhum papel encontrado"}
            />
            <CustomReactSelect
              name="expiracao"
              className="space"
              options={selectOptions2}
              defaultValue={{ label: "Todos", value: 0 }}
              onChange={(e: any) => {
                setOpcaoExpiracao(e.value);
                first();
              }}
              noOptionsMessage={() => "Nenhum papel encontrado"}
            />
            <div className="searchBar space">
              <SearchBar placeholder="Nome ou CPF" submitHandler={search} />
            </div>
          </div>
          <Table
            fields={fields}
            rows={usuarios}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[{ name: "     Editar", action: detalhar }]}
          />
          <div className="containerFooterTable">
            Visualizando {usuarios.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Usuarios;
