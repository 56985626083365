import React, { useCallback, useEffect, useState } from "react";

import { FiUsers } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import Spinner from "../../../assets/icons/spinner.svg";
import api from "../../../services/api";
import Button from "../../../template/components/Button";
import Layout from "../../../template/components/Layout";
import MainContainer from "../../../template/components/MainContainer";
import Pagination from "../../../template/components/Pagination";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import { dialogBox } from "../../../template/utils/dialogBox";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import { GestorDeLaticinio } from "./Laticinio";
import CadastrarForm from "./components/CadastrarForm";
import { Container, ContainerFooterTable, SpinnerContainer } from "./styles";

type Pagination<T> = {
  count: number;
  next: string;
  previous: string;
  results: T[];
};

const GestorLaticinio: React.FC = () => {
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [gestores, setGestores] = useState<GestorDeLaticinio[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const itemsPerPage = 20;

  const parseGestores = (results: GestorDeLaticinio[]) => {
    return results.map((gestor) => ({
      id: gestor.id,
      nome: gestor.pessoa.nome,
      cpf: gestor.documento.numero,
      laticinio: gestor.laticinio.nome,
    }));
  };

  const loadGestoresLaticinios = useCallback(async (): Promise<void> => {
    //Carrega os laticínios
    setIsLoading(true);
    try {
      const { data } = await api.get<Pagination<GestorDeLaticinio>>(
        `gestores_laticinio/?limit=${itemsPerPage}&page=${page}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
      );
      const { results, count } = data;
      setGestores(
        parseJsonToTableDataList(parseGestores(results), [
          "nome",
          "cpf",
          "laticinio",
        ])
      );
      setTotal(count);
      setFields(["Nome", "CPF", "Laticínio"]);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
    setIsLoading(false);
  }, [page, offset, searchedValue, notify]);

  useEffect(() => {
    loadGestoresLaticinios();
  }, [loadGestoresLaticinios]);

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer titlePage="Gestores Laticínios" iconPage={<FiUsers />}>
        <CadastrarForm
          loadGestoresLaticinios={loadGestoresLaticinios}
          isOpen={modalCadastrar}
          toggle={toggleCadastrar}
        />
        <Container>
          <div className="row">
            <div>
              <Button
                color="green"
                name="Cadastrar Gestor Laticínio"
                iconButtom={<GoPlus />}
                onClick={toggleCadastrar}
              />
            </div>
            <div className="searchBar space">
              <SearchBar placeholder="Nome ou CPF" submitHandler={search} />
            </div>
          </div>
        </Container>
        {isLoading && (
          <SpinnerContainer>
            <img src={Spinner} alt="carregando" height={50} width={50} />
          </SpinnerContainer>
        )}
        {!isLoading && (
          <>
            <Table
              fields={fields}
              rows={gestores}
              hasSelection={false}
              hasSelectionAll={false}
              // functions={[{ name: "     Editar", action: detalhar }]}
            />
            <ContainerFooterTable>
              Visualizando {gestores.length} de um total de {total} registros
              <div className=""></div>
              <Pagination
                itemCount={total}
                itemsPerPage={itemsPerPage}
                selectedPage={page}
                handlePageChange={changePage}
                handleNextPage={next}
                handlePreviousPage={previous}
                handleLastPage={last}
                handleFirstPage={first}
                maxPages={5}
              />
            </ContainerFooterTable>{" "}
          </>
        )}
      </MainContainer>
    </Layout>
  );
};

export default GestorLaticinio;
