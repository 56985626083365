import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../../../../../template/styles/styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { Container } from "../../styles";
import { FormContainer } from "./style";

interface IProps {
  row: any;
  toggle: () => void;
  vincularProdutor: (row: any, data: any) => void;
}

const VincularForm: React.FC<IProps> = (props) => {
  const { register, handleSubmit, errors } = useForm<any>();

  const { row } = props;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const onSubmit = (data: any) => {
    props.vincularProdutor(props.row, data);
    console.log(props.row, data);
    props.toggle();
  };

  return (
    <Container>
      <FormContainer id="confirmarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-sm">
          <CustomInput>
            <label>Situação</label>
            <select
              name="status"
              className={
                errors.status ? "CustomInput error-input" : "CustomInput"
              }
              defaultValue={row.status}
              ref={register({
                required: true,
                validate: (value) => value > 0,
              })}
              style={{ background: "white" }}
            >
              <option value={0}>Selecione a situação</option>
              <option value={2}>Ativo</option>
              <option value={4}>Indeferido</option>
            </select>
            {errors.status?.type === "required" && (
              <span>Campo obrigatório</span>
            )}
            {errors.status?.type === "validate" && (
              <span>Campo obrigatório</span>
            )}
          </CustomInput>
        </div>
      </FormContainer>
    </Container>
  );
};

export default VincularForm;
