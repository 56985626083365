import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

import brasaoRN from "../../../../template/assets/logo/logo_estado.png";
import { parseData } from "../../../../template/utils/parser";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    marginTop: 5,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

type DataTableValue = {
  [k: string]: string;
};
interface IProps {
  data: DataTableValue[];
  count: number;
  municipio: string;
  totalListaGerada: number;
  startPage: number;
  endPage: number;
}

const DocumentPDF = ({
  data,
  municipio,
  count,
  totalListaGerada,
  startPage,
  endPage,
}: IProps) => {
  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${AddLeadingZeros(temp.getUTCDate())}/${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}/${temp.getUTCFullYear()}`;
    return formated;
  };

  return (
    <>
      <Document title="Lista de famílias inaptas">
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.image} source={brasaoRN} />
            <View style={styles.headerTextSection}>
              <Text style={styles.headerText}>
                GOVERNO DO ESTADO DO RIO GRANDE DO NORTE
              </Text>
              <Text style={styles.headerText}>
                SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
                SOCIAL - SETHAS
              </Text>
              <Text style={styles.headerText}>PROGRAMA DO LEITE POTIGUAR</Text>
            </View>
            <View style={styles.titleTextSection}>
              <Text style={styles.titleText}>
                Lista de famílias inaptas do município de {municipio} referentes
                a paginação {startPage} até {endPage}
              </Text>
            </View>
          </View>
          <Table data={[...data]}>
            <TableHeader>
              <TableCell style={styles.cell}>Responsável</TableCell>
              <TableCell style={styles.cell}>Código familiar</TableCell>
              <TableCell style={styles.cell}>Município</TableCell>
              <TableCell style={styles.cell}>Renda</TableCell>
              <TableCell style={styles.cell}>Justificativa</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.responsavel}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.cod_familia}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.municipio}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.renda}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.observacao}
              />
            </TableBody>
          </Table>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <Table data={[3]}>
            <TableHeader>
              <TableCell style={styles.cell}>
                Total de famílias geradas no PDF
              </TableCell>
              <TableCell style={styles.cell}>
                Total de famílias no município
              </TableCell>
            </TableHeader>
            <TableBody>
              <TableCell style={styles.cell}>{totalListaGerada}</TableCell>
              <TableCell style={styles.cell}>{count}</TableCell>
            </TableBody>
          </Table>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.currentDate}>
            <Text style={styles.MealsCountText}>
              Documento gerado em {parseData(new Date().toUTCString())}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default DocumentPDF;
