const colors = {
  yellowOfficial: "#FFC107",
  greenOfficial: "#5BB46C",
  greenLightOfficial: "#82D492",
  brownOfficial: "#C8844B",
  blueOfficial: "#1CA6E0",
  bluePLP: "#0E4F9F",
  ultraLightGrey: "#F0F3F5",
  ultraDarkGrey: "#2F353A",
  darkGrey: "#9DA5B1",
  mediumGrey: "#C8CED3",
  mediumDarkGrey: "#63676B",
  lightGrey: "#E4E7EA",
  light: "#FFFFFF",
  backgroundBody: "#E4E5E6",
  redError: "#FF4646",
};

export default colors;
