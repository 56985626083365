import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { Container } from "../../styles";
import { FormContainer } from "./style";

interface IProps {
  row: any;
  toggle: () => void;
  desabilitarProdutor: (row: any, data: any) => Promise<void>;
  setIsLoading: (value: boolean) => void;
}

type Inputs = {
  responsavel: string;
  situacao: string;
  observacao: string;
};

const DesabilitarForm: React.FC<IProps> = (props) => {
  const { handleSubmit } = useForm<Inputs>();

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const onSubmit = async (data: any) => {
    try {
      props.setIsLoading(true);
      await props.desabilitarProdutor(props.row, data);
      props.toggle();
    } catch (error) {
      notify(
        "error",
        "Houve um erro ao desabilitar o produtor. Tente novamente mais tarde!"
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <Container>
      <FormContainer id="desabilitarForm" onSubmit={handleSubmit(onSubmit)}>
        <h5>Deseja desabilitar este produtor?</h5>
      </FormContainer>
    </Container>
  );
};

export default DesabilitarForm;
