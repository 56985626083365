import styled from "styled-components";
import colors from "../../../../styles/colors";

export const NotificationItem = styled.li`
    color: #374C96;
    font-size: 14px;
    text-align: left;
    padding: 1em 1.5em;
    border-bottom: 1px solid #E1E1E1;

    span{
      font-weight:700;
    }
    p{
    margin: 0}
    :hover{
      background-color: ${colors.lightGrey};
      cursor:pointer;
    }

 
`