import React, { useCallback, useEffect, useState } from "react";
import api from "../../../services/api";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import { Container, CustomReactSelect } from "./styles";
import { dialogBox } from "../../../template/utils/dialogBox";
import { ProdutorInterface } from "../../../template/utils/types";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Pagination from "../../../template/components/Pagination";
import DetalharModal from "./components/DetalharModal";
import { FiUsers } from "react-icons/fi";
import { Controller, useForm } from "react-hook-form";
import { createFilter } from "react-select";
import { CustomSpinner2 } from "../../Vincular/styles";

const Produtores = () => {
  //Produtores
  const [produtores, setProdutores] = useState<any[]>([]);
  const [produtorEditar, setProdutorEditar] =
    useState<ProdutorInterface>(Object);
  //Municípios
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioOptions, setMunicipioOptions] = useState<any>([]);
  const [municipioSelected, setMunicipioSelected] = useState<selectType>({
    name: "Todos",
    value: 0,
  });

  const [modalDetalhar, setmodalDetalhar] = useState(false);
  //filtros
  const [ufOptions, setUfOptions] = useState<any>([]);
  const [tipoLeiteOptions, setTipoLeiteOptions] = useState<any>([]);
  const [certificadoOptions, setCertificadoOptions] = useState<any>([]);
  const [certificado, setCertificado] = useState<number>();
  const [ufSelected, setUfSelected] = useState<selectType>({
    name: "RN",
    value: 20,
  });
  const [muncipioSelected, setMuncipioSelected] = useState<selectType>({
    name: "Todos",
    value: 0,
  });
  const [tipoLeiteSelected, setTipoLeiteSelected] = useState<selectType>({
    name: "Todos",
    value: 0,
  });
  const [municipioSelecionado, setMunicipioSelecionado] = useState<number>();
  const [ufSelecionada, setUfSelecionada] = useState<number>();
  const [tipoLeiteSelecionado, setTipoLeiteSelecionado] = useState<number>();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { handleSubmit, control, errors } = useForm<any>();
  const [searchedValue, setSearchedValue] = useState<string>("");

  const itemsPerPage = 20;
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searching, setSearching] = useState<boolean>(false);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  type selectType = {
    name: string;
    value: number;
  };

  const fields = [
    "Nome/Razão Social",
    "CPF/CPNJ",
    "Município",
    "Tipo de leite",
    "EMATER",
    "Situação",
    "Ação",
  ];

  const loadProdutores = useCallback(
    async (
      ufSelecionada = 0,
      municipioSelecionado = 0,
      tipoLeiteSelecionado = 0
    ) => {
      try {
        if (
          ufSelecionada === 20 &&
          tipoLeiteSelecionado &&
          municipioSelecionado
        ) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${municipioSelecionado}&status=2&tipo_leite=${tipoLeiteSelecionado}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        } else if (ufSelecionada != 20 && tipoLeiteSelecionado) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${""}&status=2&tipo_leite=${tipoLeiteSelecionado}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        } else if (
          ufSelecionada != 20 &&
          ufSelecionada > 0 &&
          tipoLeiteSelecionado === 0
        ) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${""}&status=2&tipo_leite=${""}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        } else if (
          ufSelecionada === 20 &&
          tipoLeiteSelecionado === 0 &&
          municipioSelecionado === 0
        ) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${""}&status=2&tipo_leite=${""}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        } else if (
          ufSelecionada === 20 &&
          tipoLeiteSelecionado &&
          municipioSelecionado === 0
        ) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${""}&status=2&tipo_leite=${tipoLeiteSelecionado}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        } else if (
          ufSelecionada === 20 &&
          tipoLeiteSelecionado === 0 &&
          municipioSelecionado
        ) {
          const { data } = await api.get(
            `produtores/?uf=${ufSelecionada}&endereco__municipio=${municipioSelecionado}&status=2&tipo_leite=${""}&certificado_emater=${certificado}&limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ordering=-updated_at`
          );
          const { results, count } = data;
          setProdutores(
            parseJsonToTableDataList(parseProdutores(results), [
              "nome",
              "cpf",
              "municipio",
              "tipo_leite",
              "cert_EMATER",
              "status",
            ])
          );
          setTotal(count);
          if (data.count === 0) {
            notify("warning", "Nenhum produtor encontrado");
          }
          setIsSearching(false);
        }
      } catch (error) {
        setIsSearching(false);
        notify("error", "Erro no servidor");
      }
    },
    [offset, ufSelected, tipoLeiteSelected, certificado, searchedValue, notify]
  );

  useEffect(() => {
    if (ufSelected.value === 20) {
      loadProdutores(
        ufSelected.value,
        municipioSelected.value,
        tipoLeiteSelected.value
      );
    } else {
      loadProdutores(ufSelected.value, 0, tipoLeiteSelected.value);
    }
  }, [offset, searchedValue]);

  function parseProdutores(produtores: any[]) {
    let tmp = [];
    for (var i = 0; i < produtores.length; i++) {
      //situação pendente
      if (produtores[i].status == 1) {
        if (produtores[i]?.tipo_leite == 1) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 2) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 3) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino e Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        }
      }
      //situação ativo
      else if (produtores[i].status == 2) {
        if (produtores[i]?.tipo_leite == 1) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 2) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 3) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino e Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Ativo",
          };
          tmp.push(object);
        }
      }
      //situação desabilitado
      else if (produtores[i].status == 3) {
        if (produtores[i]?.tipo_leite == 1) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Desabilitado",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 2) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Desabilitado",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 3) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino e Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Desabilitado",
          };
          tmp.push(object);
        }
      }
      //situação recusado
      else if (produtores[i].status == 4) {
        if (produtores[i]?.tipo_leite == 1) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Recusado",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 2) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Recusado",
          };
          tmp.push(object);
        } else if (produtores[i]?.tipo_leite == 3) {
          const object = {
            id: produtores[i].id,
            nome: produtores[i]?.pessoa.nome.toUpperCase(),
            cpf: produtores[i]?.documento,
            municipio: produtores[i]?.municipio,
            tipo_leite: "Bovino e Caprino",
            cert_EMATER: produtores[i]?.certificado_emater,
            status: "Recusado",
          };
          tmp.push(object);
        }
      }
    }
    return tmp.sort();
  }

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  const toggleDetalhar = () => {
    setmodalDetalhar(!modalDetalhar);
  };

  const detalhar = async (row: any) => {
    const { data } = await api.get(`produtores/${row.id}/`);
    setProdutorEditar(data);
    toggleDetalhar();
  };

  useEffect(() => {
    const options: any = [{ label: "Todos", value: 0 }];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipioOptions(options);
  }, [municipios]);

  useEffect(() => {
    const UFs: any = [
      { label: "AC", value: 1 },
      { label: "AL", value: 2 },
      { label: "AP", value: 3 },
      { label: "AM", value: 4 },
      { label: "BA", value: 5 },
      { label: "CE", value: 6 },
      { label: "DF", value: 7 },
      { label: "ES", value: 8 },
      { label: "GO", value: 9 },
      { label: "MA", value: 10 },
      { label: "MT", value: 11 },
      { label: "MS", value: 12 },
      { label: "MG", value: 13 },
      { label: "PA", value: 14 },
      { label: "PB", value: 15 },
      { label: "PR", value: 16 },
      { label: "PE", value: 17 },
      { label: "PI", value: 18 },
      { label: "RJ", value: 19 },
      { label: "RN", value: 20 },
      { label: "RS", value: 21 },
      { label: "RO", value: 22 },
      { label: "RR", value: 23 },
      { label: "SC", value: 24 },
      { label: "SP", value: 25 },
      { label: "SE", value: 26 },
      { label: "TO", value: 27 },
    ];
    setUfOptions(UFs);

    const tipoLeite: any = [
      { label: "Todos", value: 0 },
      { label: "CAPRINO", value: 1 },
      { label: "BOVINO", value: 2 },
      { label: "BOVINO/CAPRINO", value: 3 },
    ];
    setTipoLeiteOptions(tipoLeite);

    const certificadoEMATER: any = [
      { label: "Não", value: 0 },
      { label: "Sim", value: 1 },
    ];
    setCertificadoOptions(certificadoEMATER);
  }, []);

  const onSubmit = (data: any) => {
    setIsSearching(true);
    const { filtro_municipioSelect, filtro_tipoLeiteSelect } = data;
    // Se quando pesquisar tiver valor preenchido no campo de pesquisa para os resultados do filtro, resetar campo de pesquisas dos resultados
    setUfSelecionada(ufSelected.value);
    setMunicipioSelecionado(municipioSelected.value);
    setTipoLeiteSelecionado(tipoLeiteSelected.value);
    if (searchedValue) {
      setSearchedValue("");
    } else {
      // Se página for igual a 1 apenas executar a chamada sem resetar paginação para a primeira página
      if (page === 1) {
        if (ufSelected.value === 20) {
          loadProdutores(
            ufSelected.value,
            municipioSelected.value,
            tipoLeiteSelected.value
          );
        } else {
          loadProdutores(ufSelected.value, 0, tipoLeiteSelected.value);
        }
      } else {
        first();
      }
    }
  };

  useEffect(() => {
    if (ufSelected.value === 20) {
      loadProdutores(ufSelecionada, municipioSelecionado, tipoLeiteSelecionado);
    } else {
      loadProdutores(ufSelected.value, 0, tipoLeiteSelecionado);
    }
  }, [offset, searchedValue]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearchedValue(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer titlePage="Listagem de Produtores" iconPage={<FiUsers />}>
        <Container>
          {modalDetalhar && (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              row={produtorEditar}
              loadProdutores={loadProdutores}
            />
          )}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching && <CustomSpinner2 />}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>UF</label>
                    <CustomReactSelect
                      name="ufSelect"
                      className="select"
                      placeholder="RN"
                      options={ufOptions}
                      onChange={(e: any) => {
                        setUfSelected({
                          name: e.label,
                          value: parseInt(e.value),
                        });
                        first();
                      }}
                      noOptionsMessage={() => "Nenhuma UF encontrada"}
                    />
                  </div>

                  {ufSelected.value === 20 && (
                    <div className="form-group col">
                      <label>Município</label>
                      <CustomReactSelect
                        name="municipioSelect"
                        className="select"
                        placeholder="Todos"
                        options={municipioOptions}
                        onChange={(e: any) => {
                          setMunicipioSelected({
                            name: e.label,
                            value: parseInt(e.value),
                          });
                          first();
                        }}
                        noOptionsMessage={() => "Nenhum município encontrado"}
                      />
                    </div>
                  )}
                  <div className="form-group col">
                    <label>Tipo de leite</label>
                    <CustomReactSelect
                      name="tipoLeiteSelect"
                      className="select"
                      placeholder="Todos"
                      options={tipoLeiteOptions}
                      onChange={(e: any) => {
                        setTipoLeiteSelected({
                          name: e.label,
                          value: parseInt(e.value),
                        });
                        first();
                      }}
                      noOptionsMessage={() => "Nenhum tipo de leite encontrado"}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isSearching ? true : false}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>
          {(produtores.length > 0 || searchedValue) && (
            <div className="local-search mt-4">
              <SearchBar placeholder="Nome ou CPF" submitHandler={search} />
            </div>
          )}
          <span className="space"></span>
          {produtores && (
            <Table
              fields={fields}
              rows={produtores}
              hasSelection={false}
              hasSelectionAll={false}
              functions={[{ name: "     Detalhar", action: detalhar }]}
            />
          )}
          <div className="containerFooterTable">
            Visualizando {produtores.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Produtores;
