import styled from "styled-components";
import { Spinner } from "reactstrap";

export const MainObservacao = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  background: #6c757d;
  border-radius: 10px;
`;

export const InfoObservacao = styled.span`
  color: white;
  font-weight: bold;
  font-size: 13px;
`;

export const CustomSpinner2 = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
`;
