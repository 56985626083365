import React, { useState, useEffect } from "react";
import Button from "../Button";
import { GoBell } from "react-icons/go";
import {
  Container,
  Badge, Notifications, NotificationNoItem
} from "./styles";
import api from "../../../services/api";
import NotificationItem from "./components/NotificationItem";

interface IProps {
  gestor_estadual: boolean;
}

const Notification = ({ gestor_estadual }: IProps) => {
  const [show, setShow] = useState(false);
  const [alertas, setAlertas] = useState<any | null>(null)

  useEffect(() => {
    if (gestor_estadual) loadAlertas()
  }, []);

  const loadAlertas = async () => {
    try {
      const { data } = await api.get(`alertas/`);
      setAlertas(data.colaboradores)

    } catch (error) {
      console.error("Erro ao carregar o usuário:", error);
    }
  };
  const toggleDetalhar = () => {
    setShow(!show);
  };
  return (
    <>
      {gestor_estadual &&
        <Container >
          <div className="content-notifications" >
            <Button
              name={""}
              color="no-color"
              iconButtom={<GoBell />}
              onClick={() => {
                toggleDetalhar();
              }}
            />

            {show &&
              <Notifications>
                {alertas && alertas.length != 0 ?
                  alertas.map((alerta: any) => (
                    <NotificationItem
                      usuario={alerta.pessoa.nome}
                      id={alerta.usuario.id}
                      load={loadAlertas} />
                  )) :
                  <NotificationNoItem>
                    <p className="no-notifications"> Não há novas notificações no momento. </p>
                  </NotificationNoItem>
                }
              </Notifications>
            }
          </div>

          {alertas && alertas.length != 0 &&
            <Badge />
          }
        </Container>
      }
    </>
  );

};

export default Notification;
