import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container, customStyles } from "./../../styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import api from "../../../../../services/api";
import { CNPJMask, CPFMask } from "../../../../../template/utils/masks";
import {
  parseCNPJ,
  parseCPF,
  parseDate2,
  parseTelefone,
} from "../../../../../template/utils/parser";
import Select, { createFilter } from "react-select";
import { EntidadeParceiraInterface } from "../../../../../template/utils/types";

interface IProps {
  row: any;
  toggle: () => void;
  loadEntidadeCooperada: () => void;
}

const DetalharForm: React.FC<IProps> = (props) => {
  const { row } = props;
  const { handleSubmit, register, setValue, errors, control, setError } =
    useForm<EntidadeParceiraInterface>({ mode: "onSubmit" });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [prefeituraOptions, setPrefeituraOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedOptionResp, setSelectedOptionResp] = useState<any>();

  // Para tornar os campos dos formulários para apenas leitura
  const isReadOnly = true;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [];
    const prefeituras: any = [];
    municipios?.map((municipio) => {
      options.push({ value: municipio.id, label: municipio.nome });
      prefeituras.push({
        value: municipio.id,
        label: `Prefeitura Municipal de ${municipio.nome}`,
      });
    });
    setMunicipiosOptions(options);
    setPrefeituraOptions(prefeituras);
    if (props.row.tipo == "OSC") {
      setSelectedOption(
        options.find(
          (item: any) => props.row.endereco.municipio.id === item.value
        )
      );
    }
  }, [municipios]);

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
    if (props.row.tipo == "OSC") {
      setSelectedOptionResp(
        options.find(
          (item: any) =>
            props.row.responsavel.endereco.municipio.id === item.value
        )
      );
    }
  }, [municipios]);

  function validarEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSubmit = async (data: any) => {
    const entidade: EntidadeParceiraInterface = data;
    if (row.tipo == "OSC") {
      data.cnpj
        ? (data.cnpj = data.cnpj.replace(/[./-]/g, "").trim())
        : (data.cnpj = null);
      data.endereco.municipio = selectedOption.value;
      data.endereco.numero = data.endereco.numero.replace(/\D/g, "");
      data.responsavel.endereco.municipio = selectedOptionResp.value;

      if (data.responsavel.pessoa.telefone) {
        data.responsavel.pessoa.telefone =
          data.responsavel.pessoa.telefone.replace(/\D/g, "");
      } else data.responsavel.pessoa.telefone = "";

      entidade.responsavel.endereco.numero =
        entidade.responsavel.endereco.numero?.replace(/\D/g, "");
    } else if (row.tipo == "PREFEITURA") {
      if (data.responsavel.pessoa.telefone) {
        data.responsavel.pessoa.telefone =
          data.responsavel.pessoa.telefone.replace(/\D/g, "");
      } else data.responsavel.pessoa.telefone = "";
    }

    if (data.telefone) {
      data.telefone = data.telefone.replace(/\D/g, "");
    } else data.telefone = "";

    // Edita as informações do gestor
    api
      .patch(`/entidades_cooperadas/${row.id}/`, entidade)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Entidade Parceira editada com sucesso!`);
        // Atualiza a tabela
        props.loadEntidadeCooperada();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.cnpj) {
          setError("cnpj", {
            type: "manual",
            message: error.response.data.cnpj,
          });
        } else if (error.response.data.municipio) {
          setError("razao_social", {
            type: "manual",
            message: "Prefeitura já cadastrada",
          });
        } else {
          notify("error", `Ocorreu um erro na edição, verifique o formulário`);
        }
      });
  };

  return (
    <Container>
      <FormContainer id="detalharForm" onSubmit={handleSubmit(onSubmit)}>
        {row && row.tipo == "OSC" ? (
          //Formulário para OSC
          <>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="razao_social">Razão social</label>
                  <input
                    className={
                      errors.razao_social
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={props.row.razao_social}
                    name="razao_social"
                    ref={register({
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  {errors.razao_social?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.razao_social?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 100
                      caracteres
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={parseTelefone(props.row.telefone)}
                    name="telefone"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.telefone?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.telefone?.type === "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="cnpj">CNPJ (opcional)</label>
                  <Controller
                    className={
                      errors.cnpj ? "CustomInput error-input" : "CustomInput"
                    }
                    name="cnpj"
                    as={InputMask}
                    control={control}
                    mask={CNPJMask.mask}
                    maskChar=""
                    defaultValue={
                      props.row.cnpj ? parseCNPJ(props.row?.cnpj) : ""
                    }
                    placeholder="99.999.999/9999-99"
                    rules={{
                      required: false,
                      minLength: CNPJMask.maxLength,
                    }}
                  />
                  {errors.cnpj?.type === "minLength" && (
                    <span className="error-message">
                      Campo deve conter 14 dígitos
                    </span>
                  )}
                  {errors.cnpj && (
                    <span className="error-message">{errors.cnpj.message}</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.email ? "CustomInput error-input" : "CustomInput"
                    }
                    defaultValue={props.row.email}
                    name="email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <h6>Endereço</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.cep">CEP</label>
                  <Controller
                    className={
                      errors.endereco?.cep
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    as={InputMask}
                    name="endereco.cep"
                    control={control}
                    mask="99999-999"
                    maskChar=""
                    defaultValue={props.row.endereco.cep}
                    rules={{
                      required: true,
                      minLength: 9,
                    }}
                  />
                  {errors.endereco?.cep?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.endereco?.cep?.type === "minLength" && (
                    <span className="error-message">Deve conter 8 dígitos</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.rua">Logradouro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.rua
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.rua"
                    defaultValue={props.row.endereco.rua}
                    ref={register({ required: true })}
                  />
                  {errors.endereco?.rua?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.numero">Número</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.numero
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.numero"
                    ref={register()}
                    defaultValue={
                      props.row.endereco.numero === null
                        ? "s/n"
                        : props.row.endereco.numero
                    }
                    onChange={(e) =>
                      (e.target.value = e.target.value.toLowerCase())
                    }
                  />
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.bairro">Bairro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.bairro
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.bairro"
                    defaultValue={props.row.endereco.bairro}
                    ref={register({ required: true })}
                  />
                  {errors.endereco?.bairro?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.localidade.nome">Localidade</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.localidade
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="endereco.localidade.nome"
                    defaultValue={props.row.endereco.localidade.nome}
                    ref={register({ required: true })}
                  />
                  {errors.endereco?.localidade?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <div className="label">
                  <label htmlFor="endereco.municipio">Município</label>
                  <Controller
                    name="endereco.municipio"
                    control={control}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        styles={customStyles}
                        menuPlacement="top"
                        options={municipiosOptions}
                        value={selectedOption}
                        onChange={(val) => {
                          setSelectedOption(val);
                          onChange(val);
                        }}
                        noOptionsMessage={() => "Nenhum município encontrado"}
                        filterOption={createFilter({ ignoreAccents: false })}
                      />
                    )}
                  />
                  {errors.endereco?.municipio?.id && (
                    <span className="error-message">
                      Selecione um município
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <h6>Pessoa de referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.nome">Nome</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.nome"
                    defaultValue={props.row.responsavel.pessoa.nome}
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.pessoa?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.nome?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.cargo">Cargo/Função</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.cargo
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.cargo"
                    defaultValue={props.row.responsavel.cargo}
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.cargo?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.cargo?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.data_nascimento">
                    Nascimento
                  </label>
                  <input
                    type="date"
                    className={
                      errors.responsavel?.pessoa?.data_nascimento
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.data_nascimento"
                    defaultValue={props.row.responsavel.pessoa.data_nascimento}
                    ref={register({
                      required: true,
                      validate: (value) => value <= parseDate2(new Date()),
                    })}
                  />
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "validate" && (
                    <span className="error-message">Inserir data passada</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.telefone"
                    defaultValue={parseTelefone(
                      props.row.responsavel.pessoa?.telefone
                    )}
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.responsavel?.email
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={props.row.responsavel.email}
                    name="responsavel.email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.responsavel?.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                </CustomInput>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <h6>Endereço Pessoa de referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.cep">CEP</label>
                  <Controller
                    className={
                      errors.responsavel?.endereco?.cep
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    as={InputMask}
                    name="responsavel.endereco.cep"
                    control={control}
                    mask="99999-999"
                    maskChar=""
                    defaultValue={props.row.responsavel.endereco.cep}
                    rules={{
                      required: true,
                      minLength: 9,
                    }}
                  />
                  {errors.responsavel?.endereco?.cep?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.endereco?.cep?.type === "minLength" && (
                    <span className="error-message">Deve conter 8 dígitos</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.rua">Logradouro</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.rua
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.rua"
                    defaultValue={props.row.responsavel.endereco.rua}
                    ref={register({ required: true })}
                  />
                  {errors.responsavel?.endereco?.rua?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.numero">Número</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.numero
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.numero"
                    ref={register()}
                    defaultValue={
                      props.row.responsavel.endereco.numero === null
                        ? "s/n"
                        : props.row.responsavel.endereco.numero
                    }
                    onChange={(e) =>
                      (e.target.value = e.target.value.toLowerCase())
                    }
                  />
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.bairro">Bairro</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.bairro
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.bairro"
                    defaultValue={props.row.responsavel.endereco.bairro}
                    ref={register({ required: true })}
                  />
                  {errors.responsavel?.endereco?.bairro?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.endereco.localidade.nome">
                    Localidade
                  </label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.endereco?.localidade
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.endereco.localidade.nome"
                    defaultValue={
                      props.row.responsavel.endereco.localidade.nome
                    }
                    ref={register({ required: true })}
                  />
                  {errors.responsavel?.endereco?.localidade?.nome?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <div className="label">
                  <label htmlFor="responsavel.endereco.municipio">
                    Município
                  </label>
                  <Controller
                    name="responsavel.endereco.municipio"
                    control={control}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        styles={customStyles}
                        menuPlacement="top"
                        options={municipiosOptions}
                        value={selectedOptionResp}
                        onChange={(val) => {
                          setSelectedOptionResp(val);
                          onChange(val);
                        }}
                        noOptionsMessage={() => "Nenhum município encontrado"}
                        filterOption={createFilter({ ignoreAccents: false })}
                      />
                    )}
                  />
                  {errors.responsavel?.endereco?.municipio?.id && (
                    <span className="error-message">
                      Selecione um município
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : row.tipo == "PREFEITURA" ? (
          //Formulário para prefeitura
          <>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="razao_social">Razão Social</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    defaultValue={props.row.razao_social}
                    name="razao_social"
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={parseTelefone(props.row.telefone)}
                    name="telefone"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.telefone?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.telefone?.type === "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="cnpj">CNPJ</label>
                  <Controller
                    className={
                      errors.cnpj ? "CustomInput error-input" : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="cnpj"
                    as={InputMask}
                    control={control}
                    mask={CNPJMask.mask}
                    maskChar=""
                    defaultValue={
                      props.row.cnpj ? parseCNPJ(props.row?.cnpj) : ""
                    }
                    placeholder="99.999.999/9999-99"
                    rules={{
                      required: true,
                      minLength: CNPJMask.maxLength,
                    }}
                  />
                  {errors.cnpj?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.cnpj?.type === "minLength" && (
                    <span className="error-message">
                      Campo deve conter 14 dígitos
                    </span>
                  )}
                  {errors.cnpj && (
                    <span className="error-message">{errors.cnpj.message}</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.email ? "CustomInput error-input" : "CustomInput"
                    }
                    defaultValue={props.row.email}
                    name="email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <h6>Pessoa de referência</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.nome">Nome</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.nome"
                    defaultValue={props.row.responsavel.pessoa.nome}
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.pessoa?.nome?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.nome?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.cargo">Cargo/Função</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.cargo
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.cargo"
                    defaultValue={props.row.responsavel.cargo}
                    ref={register({
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.responsavel?.cargo?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.cargo?.type === "maxLength" && (
                    <span className="error-message">
                      Certifique-se de que esse campo não tenha mais que 60
                      caracteres.
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.data_nascimento">
                    Nascimento
                  </label>
                  <input
                    type="date"
                    className={
                      errors.responsavel?.pessoa?.data_nascimento
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.data_nascimento"
                    defaultValue={props.row.responsavel.pessoa.data_nascimento}
                    ref={register({
                      required: true,
                      validate: (value) => value <= parseDate2(new Date()),
                    })}
                  />
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.data_nascimento?.type ===
                    "validate" && (
                    <span className="error-message">Inserir data passada</span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.pessoa.telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.responsavel?.pessoa?.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="responsavel.pessoa.telefone"
                    defaultValue={parseTelefone(
                      props.row.responsavel.pessoa?.telefone
                    )}
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.pessoa?.telefone?.type ===
                    "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="responsavel.email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.responsavel?.email
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={props.row.responsavel.email}
                    name="responsavel.email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.responsavel?.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.responsavel?.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                </CustomInput>
              </div>
            </div>
          </>
        ) : (
          //Formulário para Entidade tipo SETHAS
          <>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="razao_social">Razão social</label>
                  <input
                    className={
                      errors.razao_social
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    defaultValue={props.row.razao_social}
                    name="razao_social"
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="telefone">Telefone</label>
                  <input
                    type="text"
                    className={
                      errors.telefone
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    defaultValue={parseTelefone(props.row.telefone)}
                    name="telefone"
                    ref={register({
                      required: true,
                      minLength: 13,
                    })}
                    onChange={(e: any) => {
                      e.currentTarget.value = parseTelefone(
                        e.currentTarget.value
                      );
                    }}
                  />
                  {errors.telefone?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.telefone?.type === "minLength" && (
                    <span className="error-message">Mínimo 10 dígitos</span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="cnpj">CNPJ</label>
                  <Controller
                    className={
                      errors.cnpj ? "CustomInput error-input" : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="cnpj"
                    as={InputMask}
                    control={control}
                    mask={CNPJMask.mask}
                    maskChar=""
                    defaultValue={
                      props.row.cnpj ? parseCNPJ(props.row?.cnpj) : ""
                    }
                    placeholder="99.999.999/9999-99"
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={
                      errors.email ? "CustomInput error-input" : "CustomInput"
                    }
                    defaultValue={props.row.email}
                    name="email"
                    ref={register({
                      required: true,
                      validate: (value) => validarEmail(value) === true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errors.email?.type === "validate" && (
                    <span className="error-message">Inserir email válido</span>
                  )}
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <h6>Endereço</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.cep">CEP</label>
                  <Controller
                    className={
                      errors.endereco?.cep
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    as={InputMask}
                    name="endereco.cep"
                    control={control}
                    mask="99999-999"
                    maskChar=""
                    defaultValue={props.row.endereco.cep}
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.rua">Logradouro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.rua
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="endereco.rua"
                    defaultValue={props.row.endereco.rua}
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.numero">Número</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.numero
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="endereco.numero"
                    ref={register()}
                    defaultValue={
                      props.row.endereco.numero === null
                        ? "s/n"
                        : props.row.endereco.numero
                    }
                    onChange={(e) =>
                      (e.target.value = e.target.value.toLowerCase())
                    }
                  />
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.bairro">Bairro</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.bairro
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="endereco.bairro"
                    defaultValue={props.row.endereco.bairro}
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.localidade.nome">Localidade</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.localidade
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="endereco.localidade.nome"
                    defaultValue={props.row.endereco.localidade.nome}
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.municipio">Município</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.municipio
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled={isReadOnly}
                    name="endereco.municipio"
                    defaultValue={props.row.endereco.municipio.nome}
                  />
                </CustomInput>
              </div>
            </div>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default DetalharForm;
