import React from "react";
import { FormContainer } from "./styles";
import { useForm } from "react-hook-form";

interface IProps {
  addAll: (quant: number) => void;
  total: number;
}

interface IForm {
  quantidade: number;
}

const AlertForm = ({ addAll, total }: IProps) => {
  const { register, handleSubmit, errors } = useForm<IForm>();

  const submit = (data: IForm) => {
    const { quantidade } = data;
    addAll(quantidade);
  };

  return (
    <>
      <FormContainer id="alertForm" onSubmit={handleSubmit(submit)}>
        <label>Quantidade de famílias a serem vinculadas</label>
        <input
          className={`form-control ${errors.quantidade ? "error-input" : ""}`}
          type="number"
          name="quantidade"
          ref={register({ required: true, min: 1, max: 500 })}
          defaultValue={total <= 500 ? total : 500}
        />
        {errors.quantidade?.type === "required" && (
          <span className="error-span">Campo obrigatório</span>
        )}
        {errors.quantidade?.type === "min" && (
          <span className="error-span">Informe um valor maior que 0</span>
        )}
        {errors.quantidade?.type === "max" && (
          <span className="error-span">
            Informe um valor menor ou igual a 500
          </span>
        )}
      </FormContainer>
    </>
  );
};

export default AlertForm;
