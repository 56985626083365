import api from "../../services/api";
import { getToken, parseJwt } from "../../services/auth";


const currentUser = async () => {
    try {
        const userId = () => {
            const data = parseJwt(getToken() || "");
            const user_id = data.user_id;
            return user_id;
          };
      let user = userId()
      const { data } = await api.get(`usuarios/${user}`);
      const usuario = data;
      if (usuario) {
        return usuario;

      } else {
        console.error("Usuário não encontrado.");
        return null;
      }
    } catch (error) {
      console.error("Erro ao carregar o usuário:", error);
    }
  };

export default currentUser;