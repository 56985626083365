import React from "react";

import Modal from "../../../../../template/components/Modal";

import DetalharForm from "../DetalharForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadEntidadeCooperada: () => void;
}

const DetalharModal = ({
  row,
  isOpen,
  toggle,
  loadEntidadeCooperada,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave type="submit" form="detalharForm">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={`Editar informações de ${row.razao_social}`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <DetalharForm
        row={row}
        toggle={toggle}
        loadEntidadeCooperada={loadEntidadeCooperada}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default DetalharModal;
