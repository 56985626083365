import { Spinner } from "reactstrap";
import styled from "styled-components";
import colors from "../../../template/styles/colors";
import Select from "react-select";

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
  }
  .rowButtonDownload {
    display: flex;
    justify-content: start;
    padding: 0, 15px, 0, 0;
    align-items: center;
  }
  .space {
    margin-left: 0px;
    font-size: 14px;
  }
  .customTable {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  table {
    margin-top: 10px;
  }
  .botoes {
    display: flex;
    align-items: center;
    column-count: 3;
    column-gap: 15px;
  }
  .bottom-buttons {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 12px 0;
  }
  .local-search form:nth-child(1) {
    width: 250px;
  }
  label {
    margin: 0;
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  h6 {
    font-weight: bolder;
    margin: 0;
  }

  .filterForm span {
    color: red;
    font-weight: bold;
    margin-top: 5px;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  .CheckBoxContainer {
    display: flex;
    margin-top: 40px;
    height: 39px;
    align-items: center;
  }

  .searchBar {
    margin-top: 25px;
    padding-right: 5px;
  }
`;

export const CustomSpinner = styled(Spinner)`
  font-size: 12px;
  margin-left: 20px;
  margin-right: 5px;
`;

export const CustomSpinner2 = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
`;

export const CustomReactSelect = styled(Select)`
  min-width: 200px;
`;
