import React, { useState, useEffect } from "react";
import { UsuarioGeralInterface } from "../../utils/types";
import Button from "../Button";
import { GoPerson } from "react-icons/go";
import EditModal from "./EditModal";

import {
  Container
} from "./styles";
import currentUser from "../../utils/currentUser";

const CurrentUser = () => {
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [usuario, setUsuario] = useState<UsuarioGeralInterface | null>(null);

  useEffect(() => {
    async function loadCurrentUser() {
      const usuarioInfo = await currentUser();
      setUsuario(usuarioInfo);
    }
    loadCurrentUser();
  }, []);

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };


  return (
      <Container>
        {usuario !== undefined && 
        usuario !== null && 
        usuario.pessoa !== undefined? (
          <div >
            <Button
              name={usuario.pessoa.nome}
              color="ffffff"
              iconButtom={<GoPerson/>}
              onClick={() => {
                toggleDetalhar();
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
           {modalDetalhar && (
            <EditModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              row={usuario}
              loadUsuarios={() => {}}
            />
          )}

      </Container>
     );
    };

export default CurrentUser;
