import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";

// import {CancelButton} from './styles'
import { FormContainer } from "./styles";
import { CustomInput } from "../../../../template/styles/styles";
import { Container } from "../../styles";
import { dialogBox } from "../../../../template/utils/dialogBox";
import { parseDate2 } from "../../../../template/utils/parser";

import api from "../../../../services/api";

import {
  FamiliaInterface,
  ParticipanteConsumidorReadInterface,
  ParticipanteConsumidorWriteInterface,
} from "../../../../template/utils/types";
import Button from "../../../../template/components/Button";
import { GoPlus } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TiMinus } from "react-icons/ti";
import { GoSearch } from "react-icons/go";

interface IProps {
  toggle: () => void;
  // loadFamilias: () => void;
  familiaSelecionada: any;
}
const DetalharForm: React.FC<IProps> = (props) => {
  const [participantes, setParticipantes] = useState<
    ParticipanteConsumidorReadInterface[]
  >([]);
  const [participante, setParticipante] = useState<any>(Object);
  const [situacao, setSituacao] = useState<any[]>([]);
  const [tipoParticipante, setTipoParticipante] = useState<any[]>([]);
  const { handleSubmit, register, errors, control, setError } = useForm({
    mode: "onChange",
  });

  const {
    register: registerParticipante,
    errors: errorsParticipante,
    handleSubmit: handleSubmitParticipante,
    control: controlParticipante,
    setError: setParticipanteError,
    clearErrors: clearParticipanteErrors,
    getValues,
    setValue: setValueParticipante,
    reset: resetParticipante,
  } = useForm({
    mode: "onBlur",
  });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [editarParticipante, setEditarParticipante] = useState<boolean>(false);
  const [telefoneParticipante, setTelefoneParticipante] = useState<any>();
  const [isVisualizar, setIsVisualizar] = useState<boolean>(false);

  // Para tornar os campos dos formulários para apenas leitura
  const isReadOnly = true;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const validarDataDeNascimento = () => {
    if (getValues("data_nascimento") && getValues("tipo_participante")) {
      const data_nasc = getValues("data_nascimento");
      const get_participante_tipo = tipoParticipante.find(
        (elem) => elem.id === parseInt(getValues("tipo_participante"))
      );
      // Data : 2020-10-10
      const get_ano_nasc = parseInt(data_nasc.slice(0, 4));
      const get_mes_nasc = parseInt(data_nasc.slice(5, 7));
      const get_dia_nasc = parseInt(data_nasc.slice(8, 10));
      const obj_error = {
        type: "manual",
        message: "Incompatível com o tipo participante",
      };

      const idade = calcularIdade(get_ano_nasc, get_mes_nasc, get_dia_nasc);
      if (
        get_participante_tipo.tipo === "CRIANÇA" &&
        (idade < 1 || idade > 7)
      ) {
        setParticipanteError("data_incompativel", obj_error);
      } else if (get_participante_tipo.tipo === "IDOSO" && idade < 60) {
        setParticipanteError("data_incompativel", obj_error);
      } else {
        // Limpar erro do campo quando submitar com sucesso
        clearParticipanteErrors("data_incompativel");
      }
    }
  };

  const calcularIdade = (ano: number, mes: number, dia: number) => {
    const hoje = new Date();
    let idade = hoje.getFullYear() - ano;
    const hoje_mes = hoje.getMonth() + 1;
    // Checar se o mês de aniversário do participante ainda não chegou
    if (hoje_mes < mes || (mes === hoje_mes && hoje.getDate() < dia)) {
      idade--;
    }
    return idade;
  };

  useEffect(() => {
    const loadMunicipios = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    const loadSituacoes = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("situacoes/");
      data && setSituacao(data);
    };
    const loadTipoParticipante = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("tipo_participante_consumidor/");
      data && setTipoParticipante(data);
    };
    loadMunicipios();
    loadSituacoes();
    loadTipoParticipante();
  }, []);

  const resetForm = () => {
    setValueParticipante("numero", "");
    resetParticipante();
    setParticipante({});
    setTelefoneParticipante("");
  };

  const priceFormatter = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value / 100);
    return `${prefix}${amount}`;
  }, []);

  const priceUnformatter = useCallback(
    (value: any) => value.toString().replace(/[R$ ]/g, "").replace(",", "."),
    []
  );
  function handleTelefone(tel: any) {
    setTelefoneParticipante(
      tel
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "($1)$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1")
    );
  }

  // Obtem os participantes consumidores vinculado a familia
  const loadParticipantes = useCallback(async () => {
    const { data } = await api.get(
      `participantes_consumidores/?familia=${props.familiaSelecionada.id}`
    );
    data && setParticipantes(data);
  }, [props.familiaSelecionada.id]);

  useEffect(() => {
    const loadMunicipios = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    const loadSituacoes = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("situacoes/");
      data && setSituacao(data);
    };
    loadMunicipios();
    loadSituacoes();
    loadParticipantes();
  }, [loadParticipantes]);

  const onSubmitParticipante = (data: any) => {
    if (errors.data_incompativel) return;

    const {
      nome,
      data_nascimento,
      apelido,
      numero,
      tipo_participante,
      habilitado,
    } = data;

    //JSON para criar um novo Participante Consumidor para essa familia
    if (participante.pessoa?.telefone?.length > 14) {
      participante.pessoa.telefone = participante.pessoa.telefone.substring(
        0,
        participante.pessoa.telefone.length - 1
      );
    }
    const participante_consumidor: ParticipanteConsumidorWriteInterface = {
      pessoa: {
        nome,
        data_nascimento,
        apelido,
        telefone: participante.pessoa ? participante.pessoa.telefone : "",
      },
      documento: {
        tipo: 2,
        numero: numero.replace(/[.-]/g, "").trim(),
      },
      tipo_participante_consumidor: parseInt(tipo_participante),
      habilitado,
      familia: props.familiaSelecionada.id,
    };
    api
      .post(`/participantes_consumidores/`, participante_consumidor)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify(
          "success",
          `Participante consumidor vinculado a Família com sucesso!`
        );
        // Atualiza a tabela de Pariticpantes da família
        loadParticipantes();
        resetForm();
      })
      .catch(function (error) {
        notify("error", `Verifique o formulário de Participantes`);

        if (error.response?.data?.pessoa?.nome) {
          setParticipanteError("nome", {
            type: "manual",
            message: error.response.data.pessoa.nome,
          });
        } else if (error.response?.data?.pessoa?.apelido) {
          setParticipanteError("apelido", {
            type: "manual",
            message: error.response.data.pessoa.apelido,
          });
        } else if (error.response?.data?.numero) {
          notify("error", `NIS já cadastrado ou em branco.`);
        } else if (error.response.data.nis) {
          notify("error", `${error.response.data.nis[0]}`);
        }
      });
  };

  const onSubmit = async (data: any) => {
    // Edita as informações da familia
    const familia: FamiliaInterface = {
      cod_familia: data.codigo_familia,
      responsavel: {
        nome: data.nome,
        data_nascimento: data.data_nascimento,
        apelido: data.apelido,
        telefone: data.telefone,
      },
      situacao: data.situacao,
      renda_percapita: (data.renda_percapita = Number(
        priceUnformatter(data.renda_percapita)
      )),
      renda_media: (data.renda_media = Number(
        priceUnformatter(data.renda_media)
      )),
      tipo_leite: data.tipo_leite,
      endereco: {
        cep: data.endereco.cep,
        rua: data.endereco.rua,
        numero: data.endereco.numero.replace(/\D/g, ""),
        bairro: data.endereco.bairro,
        localidade: {
          nome: data.endereco.localidade,
        },
        municipio: data.endereco.municipio,
      },
    };
    api
      .put(`/familias/${props.familiaSelecionada.id}/`, familia)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Familia editada com sucesso!`);
        // Atualiza a tabela
        // props.loadFamilias();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.responsavel.nome) {
          setError("nome", {
            type: "manual",
            message: error.response.data.responsavel.nome,
          });
        }
        if (error.response.data.responsavel.apelido) {
          setError("apelido", {
            type: "manual",
            message: error.response.data.responsavel.apelido,
          });
        }
        notify("error", `Ocorreu um erro no cadastro,verifique o formulário`);
      });
  };

  const LoadEditDataParticipante = (item: any) => {
    const { pessoa, documento, tipo_participante_consumidor, habilitado } =
      item;

    setValueParticipante("nome", pessoa.nome);
    setValueParticipante("numero", documento.numero);
    setValueParticipante("data_nascimento", pessoa.data_nascimento);
    setValueParticipante("apelido", pessoa.apelido);
    setValueParticipante("tipo_participante", tipo_participante_consumidor.id);
    setValueParticipante("habilitado", habilitado ? "1" : "0");
  };

  const handleDeleteParticipante = async (item: any) => {
    const new_arr = participantes.filter((elem) => elem.id !== item.id);
    api
      .delete(`/participantes_consumidores/${item.id}`)
      .then(() => {
        notify("success", `Participante Consumidor excluído com sucesso!`);
        setParticipantes(new_arr);
        // Só reseta o form na exclusão se os dados do participante a ser excluido estiverem carregados no form
        if (participante.id === item.id) {
          resetForm();
          setEditarParticipante(false);
        }
      })
      .catch(() => {
        notify("error", `Ocorreu um erro ao excluir o participante`);
      });
  };

  const handleEditaParticipante = async (data: any) => {
    if (errors.data_incompativel) return;

    const {
      nome,
      data_nascimento,
      apelido,
      numero,
      tipo_participante,
      habilitado,
    } = data;

    if (participante.pessoa?.telefone?.length > 14) {
      participante.pessoa.telefone = participante.pessoa.telefone.substring(
        0,
        participante.pessoa.telefone.length - 1
      );
    }
    const participanteWrite: ParticipanteConsumidorWriteInterface = {
      pessoa: {
        nome,
        data_nascimento,
        apelido,
        telefone: participante.pessoa ? participante.pessoa.telefone : "",
      },
      documento: {
        tipo: 2,
        numero: numero.replace(/[.-]/g, "").trim(),
      },
      tipo_participante_consumidor: parseInt(tipo_participante),
      habilitado,
      familia: props.familiaSelecionada.id,
    };
    api
      .patch(
        `/participantes_consumidores/${participante.id}/`,
        participanteWrite
      )
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Participante Consumidor editado com sucesso!`);
        // Atualiza a tabela
        loadParticipantes();
        resetForm();
        setEditarParticipante(false);
      })
      .catch(function (error) {
        notify("error", `Verifique o formulário de Participantes`);

        if (error.response?.data?.pessoa?.nome) {
          setParticipanteError("nome", {
            type: "manual",
            message: error.response.data.pessoa.nome,
          });
        } else if (error.response?.data?.pessoa?.apelido) {
          setParticipanteError("apelido", {
            type: "manual",
            message: error.response.data.pessoa.apelido,
          });
        } else if (error.response?.data?.numero) {
          notify("error", `NIS já cadastrado ou em branco.`);
        } else if (error.response.data.nis) {
          notify("error", `${error.response.data.nis[0]}`);
        }
      });
  };
  return (
    <Container>
      <FormContainer
        id="cadastrarFormFamilia"
        onSubmit={handleSubmit(onSubmit)}
        key={2}
      >
        <div className="row">
          <div className="col-sm">
            <h6>Responsável</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="nome">Nome</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.nome ? "CustomInput error-input" : "CustomInput"
                }
                name="nome"
                defaultValue={props.familiaSelecionada.responsavel.nome}
                ref={register({ required: true })}
                placeholder={"Maria Aparecida da Silva"}
              />
              {errors.nome && (
                <span className="error-message">
                  {errors.nome.message || "Campo Obrigatorio"}
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_nascimento">Nascimento</label>
              <input
                type="date"
                readOnly={isReadOnly}
                className={
                  errors.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="01/01/2001"
                name="data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
                defaultValue={
                  props.familiaSelecionada.responsavel.data_nascimento
                }
              />
              {errors.data_nascimento && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="telefone">Telefone (Opcional)</label>
              <Controller
                readOnly={isReadOnly}
                className={
                  errors.telefone ? "CustomInput error-input" : "CustomInput"
                }
                as={InputMask}
                name="telefone"
                control={control}
                mask="(99)99999-9999"
                maskChar=""
                defaultValue={props.familiaSelecionada.responsavel.telefone}
                placeholder="(99)99999-9999"
                rules={{
                  required: false,
                  minLength: 14,
                }}
              />
              {errors.telefone?.type === "minLength" && (
                <span className="error-message">
                  Se preenchido, campo deve conter 11 dígitos
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <h6>Dados da Família</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="codigo_familia">Código da Familia</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.codigo_familia
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="codigo_familia"
                minLength={11}
                maxLength={11}
                ref={register({ required: true })}
                placeholder={"123213213"}
                defaultValue={props.familiaSelecionada.cod_familia}
              />
              {errors.codigo_familia && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="renda_percapita">Renda Per Capita</label>
              <Controller
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.renda_percapita
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="renda_percapita"
                defaultValue={() =>
                  props.familiaSelecionada.renda_percapita?.toLocaleString(
                    "pt-BR",
                    {
                      minimumIntegerDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )
                }
                as={NumberFormat}
                control={control}
                displayType="input"
                inputMode="numeric"
                format={priceFormatter}
                isAllowed={(values: any) => {
                  const intVal = "".padStart(3, "9");
                  const decVal = "".padStart(0, "9");
                  const maxVal = parseFloat(`${intVal}.${decVal}`);
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue / 100 <= maxVal;
                }}
                rules={{ required: true }}
                placeholder={"R$ 100,00"}
              />
              {errors.renda_percapita && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="ponto">Ponto</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.nome ? "CustomInput error-input" : "CustomInput"
                }
                name="ponto"
                defaultValue={
                  props.familiaSelecionada.ponto
                    ? props.familiaSelecionada.ponto.nome
                    : "Não Definido"
                }
              />
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <label htmlFor="tipo_leite">Tipo de leite</label>
            <select
              name="tipo_leite"
              disabled={isReadOnly}
              className={
                errors.tipo_leite ? "CustomSelect error-input" : "CustomSelect"
              }
              defaultValue={props.familiaSelecionada.tipo_leite}
              ref={register({ required: true })}
            >
              <option value="">Selecionar</option>
              <option value="BOVINO">BOVINO</option>
              <option value="CAPRINO">CAPRINO</option>
              <option value="BOVINO/CAPRINO">BOVINO/CAPRINO</option>
            </select>
            {errors.tipo_leite && (
              <span className="error-message">Selecione um tipo de leite</span>
            )}
          </div>
          <div className="col-sm">
            <label htmlFor="situacao">Situação</label>
            <select
              name="situacao"
              disabled={isReadOnly}
              className={
                errors.situacao ? "CustomSelect error-input" : "CustomSelect"
              }
              ref={register({ required: true })}
            >
              {situacao.map((item: any) =>
                props.familiaSelecionada.situacao === item.situacao ? (
                  <option value={item.id} key={item.id} selected>
                    {item.situacao}
                  </option>
                ) : (
                  <option value={item.id} key={item.id}>
                    {item.situacao}
                  </option>
                )
              )}
            </select>
            {errors.situacao && (
              <span className="error-message">Selecione uma situação</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <CustomInput>
              <label htmlFor="endereco.cep">Cep</label>
              <Controller
                readOnly={isReadOnly}
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                defaultValue={props.familiaSelecionada.endereco.cep}
                placeholder="ex: 59999-999"
                maskChar=""
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">Deve conter 8 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.rua"
                ref={register({ required: true })}
                placeholder={"Av Maria Lacerda"}
                defaultValue={props.familiaSelecionada.endereco?.rua}
              />
              {errors.endereco?.rua && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm-2">
            <CustomInput>
              <label htmlFor="endereco.numero">Número</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.numero"
                ref={register()}
                defaultValue={
                  props.familiaSelecionada.endereco.numero === null
                    ? "s/n"
                    : props.familiaSelecionada.endereco.numero
                }
                onChange={(e) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.bairro"
                ref={register({ required: true })}
                placeholder={"Centro"}
                defaultValue={props.familiaSelecionada.endereco.bairro}
              />
              {errors.endereco?.bairro && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm-5">
            <CustomInput>
              <label htmlFor="endereco.localidade">Localidade</label>
              <input
                type="text"
                readOnly={isReadOnly}
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.localidade"
                ref={register({ required: true })}
                placeholder={"Comunidade Maria"}
                defaultValue={props.familiaSelecionada.endereco.localidade.nome}
              />
              {errors.endereco?.localidade && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <label htmlFor="endereco.municipio">Município</label>
            <select
              name="endereco.municipio"
              disabled={isReadOnly}
              className={
                errors.endereco?.municipio
                  ? "CustomSelect error-input"
                  : "CustomSelect"
              }
              defaultValue={props.familiaSelecionada.endereco.municipio.id}
              ref={register({ required: true })}
            >
              <option value={props.familiaSelecionada.endereco.municipio.id}>
                {props.familiaSelecionada.endereco.municipio.nome}
              </option>
              {municipios.map((item) => (
                <option value={parseInt(item.id)}>{item.nome}</option>
              ))}
            </select>
            {errors.endereco?.municipio && (
              <span className="error-message">Selecione um município</span>
            )}
          </div>
        </div>
      </FormContainer>
      <FormContainer
        id="cadastrarFormParticipante"
        onSubmit={
          editarParticipante
            ? handleSubmitParticipante(handleEditaParticipante)
            : handleSubmitParticipante(onSubmitParticipante)
        }
        key={2}
      >
        {/*Quando em readOnly o formulário dos participantes consumidores 
        só aparece quando o usuário clicar para visualizar os dados de um participante */}
        {(!isReadOnly || isVisualizar) && (
          <>
            <div className="row">
              <div className="col-sm">
                <h6>Dados do participante consumidor</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="nome">Nome</label>
                  <input
                    type="text"
                    readOnly={isReadOnly}
                    className={
                      errorsParticipante.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="nome"
                    ref={registerParticipante({ required: true })}
                    placeholder={"Maria Aparecida Gomes"}
                  />
                  {errorsParticipante.nome && (
                    <span className="error-message">
                      {errorsParticipante.nome.message || "Campo obrigatório"}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label htmlFor="tipo_participante">Tipo de Participante</label>
                <select
                  name="tipo_participante"
                  disabled={isReadOnly}
                  className={
                    errorsParticipante.tipo_participante
                      ? "CustomSelect error-input"
                      : "CustomSelect"
                  }
                  ref={registerParticipante({ required: true })}
                >
                  <option value="">Selecione</option>
                  {tipoParticipante.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.tipo}
                    </option>
                  ))}
                </select>
                {errorsParticipante.tipo_participante && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </div>
              <div className="col-sm-4">
                <CustomInput>
                  <label htmlFor="data_nascimento">Nascimento</label>
                  <input
                    type="date"
                    readOnly={isReadOnly}
                    className={
                      errorsParticipante.data_nascimento ||
                      errorsParticipante.data_incompativel
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="data_nascimento"
                    ref={registerParticipante({
                      required: true,
                      validate: (value) => value <= parseDate2(new Date()),
                    })}
                    placeholder={"01/01/2001"}
                  />
                  {errorsParticipante.data_nascimento?.type === "required" && (
                    <span className="error-message">Campo obrigatório</span>
                  )}
                  {errorsParticipante.data_nascimento?.type === "validate" && (
                    <span className="error-message">Inserir data passada</span>
                  )}
                  {errorsParticipante.data_incompativel && (
                    <span className="error-message">
                      {errorsParticipante.data_incompativel.message}
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm-4">
                <CustomInput>
                  <label htmlFor="documento">NIS</label>
                  <Controller
                    type="text"
                    readOnly={isReadOnly}
                    name="numero"
                    control={controlParticipante}
                    as={InputMask}
                    mask="999.99999.99-9"
                    className={
                      errorsParticipante.nis
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder={"682.15520.18-0"}
                    defaultValue={""}
                  />
                  {errorsParticipante.nis && (
                    <span className="error-message">
                      {errorsParticipante.nis.message}
                    </span>
                  )}
                </CustomInput>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <CustomInput>
                  <label htmlFor="telefone">Telefone (Opcional)</label>
                  <Controller
                    name="participante.telefone"
                    control={controlParticipante}
                    rules={{
                      minLength: 14,
                    }}
                    render={() => (
                      <input
                        name="telefone"
                        type="text"
                        readOnly={isReadOnly}
                        placeholder="(99)99999-9999"
                        className={
                          errorsParticipante.telefone
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        value={telefoneParticipante ? telefoneParticipante : ""}
                        minLength={14}
                        onChange={(e) => {
                          handleTelefone(e.currentTarget.value);
                          setParticipante({
                            ...participante,
                            pessoa: {
                              ...participante.pessoa,
                              telefone: e.currentTarget.value,
                            },
                          });
                        }}
                      />
                    )}
                  />
                  {errorsParticipante.telefone?.type === "minLength" && (
                    <span className="error-message">
                      Se preenchido, campo deve ter 11 dígitos
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="apelido">Apelido (Opcional)</label>
                  <input
                    type="text"
                    readOnly={isReadOnly}
                    className={
                      errorsParticipante.apelido
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    name="apelido"
                    ref={registerParticipante()}
                    placeholder={"Mari"}
                  />
                  {errorsParticipante.apelido && (
                    <span className="error-message">
                      {errorsParticipante.apelido.message}
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="col-sm-2">
                <label htmlFor="habilitado">Habilitado</label>
                <select
                  name="habilitado"
                  disabled={isReadOnly}
                  className={
                    errorsParticipante.habilitado
                      ? "CustomSelect error-input"
                      : "CustomSelect"
                  }
                  ref={registerParticipante({ required: true })}
                  defaultValue={"1"}
                >
                  <option value="">Selecione</option>
                  <option value="0">NÃO</option>
                  <option value="1">SIM</option>
                </select>
                {errorsParticipante.habilitado && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          {/* <div className="col-sm d-flex justify-content-between">      
              <Button
               onClick={() => validarDataDeNascimento()}
               color="green"
               name={
                 editarParticipante
                   ? "Editar Participante"
                   : "Cadastrar Participante"
               }
               iconButtom={editarParticipante ? <FaRegEdit /> : <GoPlus />}
             />
             {editarParticipante ? (
               <Button
                 onClick={() => {
                   setEditarParticipante(false);
                   resetForm();
                 }}
                 color="grey"
                 name={"Cancelar edição"}
                 iconButtom={<MdCancel />}
               />
             ) : (
               ""
             )}
            </div> */}
          {isReadOnly && isVisualizar && (
            <div className="col-sm d-flex justify-content-end">
              <Button
                onClick={() => {
                  setIsVisualizar(false);
                  resetForm();
                }}
                color="grey"
                name={"Esconder dados"}
                iconButtom={<MdCancel />}
              />
            </div>
          )}
        </div>
        <div className="col-sm">
          <hr />
          <strong>Lista de Participantes da família</strong>
          {participantes.map((item: ParticipanteConsumidorReadInterface) => (
            <ul className="list">
              <li
                key={item.id}
                className={
                  item.habilitado ? "" : "statusParticipanteDesabilitado"
                }
              >
                <span>{item.pessoa.nome}</span>
                <div className="acaoParticipante">
                  <button
                    type="button"
                    className="editarParticipante"
                    onClick={async () => {
                      if (isReadOnly) {
                        await setIsVisualizar(true);
                      } else {
                        clearParticipanteErrors();
                        setEditarParticipante(true);
                      }
                      setTelefoneParticipante(item.pessoa.telefone);
                      setParticipante(item);
                      LoadEditDataParticipante(item);
                    }}
                  >
                    <GoSearch />{" "}
                    <span className="TextActionButtons">Visualizar</span>
                  </button>
                  {/* <button
                    type="button"
                    className="excluirParticipante"
                    onClick={() => handleDeleteParticipante(item)}
                  >
                    <TiMinus />
                    <span className="TextActionButtons">Remover</span>
                  </button> */}
                </div>
              </li>
            </ul>
          ))}
        </div>
      </FormContainer>
    </Container>
  );
};
export default DetalharForm;
