import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container } from "./../../styles";
import api from "../../../../../services/api";
import { CPFMask, CNPJMask } from "../../../../../template/utils/masks";
import { parseDate2 } from "../../../../../template/utils/parser";
import { ProdutorInterface } from "../../../../../template/utils/types";

interface IProps {
  row: ProdutorInterface;
  toggle: () => void;
  loadProdutores: () => void;
}
const DetalharForm: React.FC<IProps> = (props) => {
  const { register, errors, control } = useForm({ mode: "onSubmit" });

  const { row } = props;

  if (row.certificado_emater == true) {
    var certEMATER = 1;
  } else var certEMATER = 2;

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [certificadoEmater, setCertificadoEmater] = useState<number>(0);
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
  }, [municipios]);

  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${temp.getUTCFullYear()}-${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}-${AddLeadingZeros(temp.getUTCDate())}`;
    return formated;
  };

  return (
    row && (
      <Container>
        <FormContainer id="detalharForm">
          <div className="row">
            <div className="col-sm">
              <h6>Dados do Produtor</h6>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.nome">Nome/Razão Social</label>
                <input
                  className={
                    errors.pessoa?.nome
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.pessoa?.nome}
                  name="pessoa.nome"
                  ref={register({
                    required: true,
                    maxLength: 60,
                  })}
                />
                {errors.pessoa?.nome?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.pessoa?.nome?.type === "maxLength" && (
                  <span className="error-message">
                    Certifique-se de que esse campo não tenha mais que 60
                    caracteres
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.data_nascimento">Nascimento</label>
                <input
                  type="date"
                  className={
                    errors.pessoa?.data_nascimento
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={
                    row &&
                    row.pessoa &&
                    row.pessoa.data_nascimento &&
                    GetFormatedDate(row.pessoa.data_nascimento)
                  }
                  name="pessoa.data_nascimento"
                  ref={register({
                    required: true,
                    validate: (value) => value <= parseDate2(new Date()),
                  })}
                />
                {errors.pessoa?.data_nascimento?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.pessoa?.data_nascimento?.type === "validate" && (
                  <span className="error-message">Inserir data passada</span>
                )}
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="documento">CPF</label>
                <Controller
                  className={"CustomInput"}
                  disabled
                  name="documento"
                  defaultValue={row.documento}
                  as={InputMask}
                  control={control}
                  mask={CPFMask.mask}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="cnpj">CNPJ (Opcional)</label>
                <Controller
                  className={"CustomInput"}
                  disabled
                  defaultValue={row.cnpj ? row.cnpj : "Não Cadastrado"}
                  name="cnpj.numero"
                  as={InputMask}
                  control={control}
                  mask={CNPJMask.mask}
                />
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.telefone">Telefone (Opcional)</label>
                <Controller
                  className={
                    errors.pessoa?.telefone
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  as={InputMask}
                  defaultValue={row.pessoa?.telefone}
                  name="pessoa.telefone"
                  control={control}
                  mask="(99)99999-9999"
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="email">Email (Opcional)</label>
                <input
                  type="email"
                  className={"CustomInput"}
                  disabled
                  defaultValue={row.email ? row.email : "Não Cadastrado"}
                  name="email"
                  ref={register({
                    required: true,
                  })}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="producao_media_de_leite">Média de leite</label>
                <input
                  type="producao_media_de_leite"
                  className={
                    errors.producao_media_de_leite
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.producao_media_de_leite}
                  name="producao_media_de_leite"
                />
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label>Tipo de Leite</label>
                <select
                  name="tipo_leite"
                  className={
                    errors.tipo_leite
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.tipo_leite}
                  ref={register({
                    required: true,
                    validate: (value) => value > 0,
                  })}
                  style={{ background: "white" }}
                >
                  <option value={0}>Selecione o tipo de leite</option>
                  <option value={1}>Caprino</option>
                  <option value={2}>Bovino</option>
                  <option value={3}>Bovino/Caprino</option>
                </select>
              </CustomInput>
            </div>
            <div className="col-sm">
              <label htmlFor="certificado_emater">
                Produtor Certificado EMATER
              </label>
              <CustomInput>
                <select
                  name="certificado_emater"
                  className={
                    errors.certificado_emater
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={certEMATER}
                  style={{ background: "white" }}
                >
                  <option value={0}>Selecione sim ou não</option>
                  <option value={1}>Sim</option>
                  <option value={2}>Não</option>
                </select>
              </CustomInput>
            </div>
            {certificadoEmater == 1 && (
              <>
                <div className="col-sm">
                  <CustomInput>
                    <label htmlFor="dap">
                      DAP-declaração aptidão ao PRONAF
                    </label>
                    <input
                      type="file"
                      className={
                        errors.dap ? "CustomInput error-input" : "CustomInput"
                      }
                      disabled
                      name="dap"
                      ref={register({
                        required: false,
                      })}
                    />
                  </CustomInput>
                </div>
              </>
            )}
          </div>
          <hr />
          <div className="row">
            <div className="col-sm">
              <h6>Endereço</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <CustomInput>
                <label>UF</label>
                <select
                  name="uf"
                  className={
                    errors.uf ? "CustomInput error-input" : "CustomInput"
                  }
                  defaultValue={row.uf}
                  disabled
                  ref={register({
                    required: true,
                    validate: (value) => value > 0,
                  })}
                  style={{ background: "white" }}
                >
                  <option value={0}>Selecione uma UF</option>
                  <option value={1}>AC</option>
                  <option value={2}>AL</option>
                  <option value={3}>AP</option>
                  <option value={4}>AM</option>
                  <option value={5}>BA</option>
                  <option value={6}>CE</option>
                  <option value={7}>DF</option>
                  <option value={8}>ES</option>
                  <option value={9}>GO</option>
                  <option value={10}>MA</option>
                  <option value={11}>MT</option>
                  <option value={12}>MS</option>
                  <option value={13}>MG</option>
                  <option value={14}>PA</option>
                  <option value={15}>PB</option>
                  <option value={16}>PR</option>
                  <option value={17}>PE</option>
                  <option value={18}>PI</option>
                  <option value={19}>RJ</option>
                  <option value={20}>RN</option>
                  <option value={21}>RS</option>
                  <option value={22}>RO</option>
                  <option value={23}>RR</option>
                  <option value={24}>SC</option>
                  <option value={25}>SP</option>
                  <option value={26}>SE</option>
                  <option value={27}>TO</option>
                </select>
                {errors.uf?.type === "required" && (
                  <span>Campo obrigatório</span>
                )}
                {errors.uf?.type === "validate" && (
                  <span>Campo obrigatório</span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="endereco.cep">CEP</label>
                <Controller
                  className={"CustomInput"}
                  disabled
                  as={InputMask}
                  name="endereco.cep"
                  control={control}
                  mask="99999-999"
                  defaultValue={row.endereco?.cep}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="endereco.rua">Logradouro</label>
                <input
                  type="text"
                  className={
                    errors.endereco?.rua
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.endereco?.rua}
                  name="endereco.rua"
                />
                {errors.endereco?.rua?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="endereco.numero">Número</label>
                <input
                  type="text"
                  className={
                    errors.endereco?.numero
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  name="endereco.numero"
                  defaultValue={row.endereco?.numero}
                  ref={register()}
                  onChange={(e) =>
                    (e.target.value = e.target.value.toLowerCase())
                  }
                />
              </CustomInput>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="endereco.bairro">Bairro</label>
                <input
                  type="text"
                  className={
                    errors.endereco?.bairro
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  name="endereco.bairro"
                  defaultValue={row.endereco?.bairro}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="endereco.localidade.nome">Localidade</label>
                <input
                  type="text"
                  className={
                    errors.endereco?.localidade
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  name="endereco.localidade.nome"
                  defaultValue={row.endereco?.localidade?.nome}
                />
              </CustomInput>
            </div>
            {row.uf === 20 && (
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="endereco.municipio.nome">Município</label>
                  <input
                    type="text"
                    className={
                      errors.endereco?.municipio?.nome
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    disabled
                    name="endereco.municipio.nome"
                    defaultValue={row.endereco?.municipio?.nome}
                  />
                </CustomInput>
              </div>
            )}
          </div>
          <hr />
          <div className="row">
            <div className="col-sm">
              <h6>Dados bancários</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={"codigo_banco"}>Código</label>
                <input
                  type="number"
                  className={
                    errors.codigo_banco
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.codigo_banco}
                  name="codigo_banco"
                  ref={register({
                    required: true,
                    validate: (value) => value <= 32767,
                  })}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={"nome_banco"}>Banco</label>
                <input
                  type="text"
                  className={
                    errors.nome_banco
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.nome_banco}
                  name="nome_banco"
                  ref={register({
                    required: true,
                    maxLength: 20,
                  })}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={"agencia_banco"}>Agência</label>
                <input
                  type="text"
                  className={
                    errors.agencia_banco
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.agencia_banco}
                  name="agencia_banco"
                  ref={register({ required: true })}
                />
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor={"conta_banco"}>Conta</label>
                <input
                  type="text"
                  className={
                    errors.conta_banco
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  disabled
                  defaultValue={row.conta_banco}
                  name="conta_banco"
                  ref={register({ required: true })}
                />
              </CustomInput>
              <input
                type="hidden"
                name="laticinios"
                ref={register()}
                defaultValue=""
              />
            </div>
          </div>
        </FormContainer>
      </Container>
    )
  );
};

export default DetalharForm;
