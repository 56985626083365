import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";

import { FormContainer } from "./styles";
import { CustomInput } from "../../../../../template/styles/styles";
import { Container } from "../../styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { parseDate2 } from "../../../../../template/utils/parser";

import api from "../../../../../services/api";
import { FamiliaInterface } from "../../../../../template/utils/types";

interface IProps {
  toggle: () => void;
  loadFamilias: () => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, errors, control, setError } = useForm({
    mode: "onSubmit",
  });

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [situacao, setSituacao] = useState<any[]>([]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const loadMunicipios = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    const loadSituacoes = async () => {
      //Carrega os laticínios da API
      const { data } = await api.get("situacoes/");
      data && setSituacao(data);
    };
    loadSituacoes();
    loadMunicipios();
  }, []);

  const priceFormatter = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value / 100);
    return `${prefix}${amount}`;
  }, []);

  const priceUnformatter = useCallback(
    (value: any) => value.toString().replace(/[R$ ]/g, "").replace(",", "."),
    []
  );

  const onSubmit = async (data: any) => {
    // informações da familia
    const familia: FamiliaInterface = {
      cod_familia: data.codigo_familia,
      responsavel: {
        nome: data.nome,
        data_nascimento: data.data_nascimento,
        apelido: data.apelido,
        telefone: data.telefone,
      },
      situacao: data.situacao,
      renda_percapita: (data.renda_percapita = Number(
        priceUnformatter(data.renda_percapita)
      )),
      renda_media: (data.renda_media = Number(
        priceUnformatter(data.renda_media)
      )),
      tipo_leite: data.tipo_leite,
      endereco: {
        cep: data.endereco.cep,
        rua: data.endereco.rua,
        numero: data.endereco.numero.replace(/\D/g, ""),
        bairro: data.endereco.bairro,
        localidade: {
          nome: data.endereco.localidade,
        },
        municipio: data.endereco.municipio,
      },
    };
    api
      .post(`/familias/`, familia)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Familia cadastrada com sucesso!`);
        // Atualiza a tabela
        props.loadFamilias();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.responsavel?.nome) {
          setError("nome", {
            type: "manual",
            message: error.response.data.responsavel.nome,
          });
        }
        if (error.response.data.responsavel?.apelido) {
          setError("apelido", {
            type: "manual",
            message: error.response.data.responsavel.apelido,
          });
        }

        if (error.response.status === 500) {
          notify("error", `Ocorreu um erro no cadastro,verifique o formulário`);
        } else {
          notify(
            "error",
            `Ocorreu um erro no cadastro,verifique o formulário.`
          );
        }
      });
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <h6>Responsável</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="nome">Nome</label>
              <input
                type="text"
                className={
                  errors.nome ? "CustomInput error-input" : "CustomInput"
                }
                name="nome"
                ref={register({ required: true })}
                placeholder={"Maria Aparecida da Silva"}
              />
              {errors.nome && (
                <span className="error-message">
                  {errors.nome.message || "Campo Obrigatorio"}
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="apelido">Apelido (Opcional)</label>
              <input
                type="text"
                className={
                  errors.apelido ? "CustomInput error-input" : "CustomInput"
                }
                name="apelido"
                ref={register()}
                placeholder={"Mari"}
              />
              {errors.apelido && (
                <span className="error-message">
                  {errors.apelido.message || "Campo obrigatório"}
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_nascimento">Nascimento</label>
              <input
                type="date"
                className={
                  errors.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="01/01/2001"
                name="data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
              />
              {errors.data_nascimento?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="telefone">Telefone (Opcional)</label>
              <Controller
                className={
                  errors.telefone ? "CustomInput error-input" : "CustomInput"
                }
                as={InputMask}
                name="telefone"
                control={control}
                mask="(99)99999-9999"
                maskChar=""
                placeholder="(99)99999-9999"
                rules={{
                  required: false,
                  minLength: 14,
                }}
              />
              {errors.telefone?.type === "minLength" && (
                <span className="error-message">
                  Se preenchido, campo deve conter 11 dígitos
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <h6>Dados da Família</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="codigo_familia">Código da Familia</label>
              <input
                type="text"
                className={
                  errors.codigo_familia
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="codigo_familia"
                minLength={11}
                maxLength={11}
                ref={register({ required: true })}
                placeholder={"123213213"}
              />
              {errors.codigo_familia && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="tipo_leite">Tipo de leite</label>
              <select
                style={{ width: "100%" }}
                name="tipo_leite"
                className={
                  errors.tipo_leite
                    ? "CustomSelect error-input"
                    : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                <option value="">Selecionar</option>
                <option value="BOVINO">BOVINO</option>
                <option value="CAPRINO">CAPRINO</option>
                <option value="BOVINO/CAPRINO">BOVINO/CAPRINO</option>
              </select>
              {errors.tipo_leite && (
                <span className="error-message">
                  Selecione um tipo de leite
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="renda_percapita">Renda Per Capita</label>
              <Controller
                type="text"
                className={
                  errors.renda_percapita
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="renda_percapita"
                placeholder={"R$ 100,00"}
                rules={{ required: true }}
                as={NumberFormat}
                control={control}
                displayType="input"
                inputMode="numeric"
                defaultValue=""
                format={priceFormatter}
                isAllowed={(values: any) => {
                  const intVal = "".padStart(3, "9");
                  const decVal = "".padStart(0, "9");
                  const maxVal = parseFloat(`${intVal}.${decVal}`);
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue / 100 <= maxVal;
                }}
              />
              {errors.renda_percapita && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="renda_media">Renda média</label>
              <Controller
                type="text"
                className={
                  errors.renda_media ? "CustomInput error-input" : "CustomInput"
                }
                name="renda_media"
                placeholder={"R$ 100,00"}
                rules={{ required: true }}
                as={NumberFormat}
                control={control}
                displayType="input"
                inputMode="numeric"
                defaultValue=""
                format={priceFormatter}
                isAllowed={(values: any) => {
                  const intVal = "".padStart(3, "9");
                  const decVal = "".padStart(0, "9");
                  const maxVal = parseFloat(`${intVal}.${decVal}`);
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue / 100 <= maxVal;
                }}
              />
              {errors.renda_media && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <CustomInput>
              <label htmlFor="endereco.cep">Cep</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                maskChar=""
                placeholder="99999-999"
                rules={{
                  minLength: 9,
                  required: true,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">Deve conter 8 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                type="text"
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.rua"
                ref={register({ required: true })}
                placeholder={"Av Maria Lacerda"}
              />
              {errors.endereco?.rua && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm-2">
            <CustomInput>
              <label htmlFor="endereco.numero">Número</label>
              <input
                type="text"
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.numero"
                ref={register()}
                defaultValue="s/n"
                onChange={(e) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
              {errors.endereco?.numero && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                type="text"
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.bairro"
                ref={register({ required: true })}
                placeholder={"Centro"}
              />
              {errors.endereco?.bairro && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm-5">
            <CustomInput>
              <label htmlFor="endereco.localidade">Localidade</label>
              <input
                type="text"
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.localidade"
                ref={register({ required: true })}
                placeholder={"Comunidade Maria"}
              />
              {errors.endereco?.localidade && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.municipio">Município</label>
              <select
                name="endereco.municipio"
                className={
                  errors.endereco?.municipio
                    ? "CustomSelect error-input"
                    : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                <option value="">Selecione um município</option>
                {municipios.map((item) => (
                  <option value={parseInt(item.id)}>{item.nome}</option>
                ))}
              </select>
              {errors.endereco?.municipio && (
                <span className="error-message">Selecione um município</span>
              )}
            </CustomInput>
          </div>
        </div>
      </FormContainer>
    </Container>
  );
};
export default CadastrarForm;
