import React from "react";
import Modal from "../../../../template/components/Modal";
import { FamiliaReadInterface } from "../../../../template/utils/types";
import DetalharForm from "../DetalharForm";
import { ButtonClose, CustomAiOutlineClose } from "./styles";

interface IProps {
  familiaSelecionada: FamiliaReadInterface;
  isOpen: boolean;
  toggle: () => void;
}

const DetalharModal = ({ familiaSelecionada, isOpen, toggle }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        {/*
        <ButtonSave type="submit" form="cadastrarFormFamilia">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
        */}
      </React.Fragment>
    );
  };

  return familiaSelecionada ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={"Informações da familia"}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <DetalharForm familiaSelecionada={familiaSelecionada} toggle={toggle} />
    </Modal>
  ) : (
    <div />
  );
};

export default DetalharModal;
