import styled from "styled-components";
import colors from "../../../../../template/styles/colors";

export const ErrorMessage = styled.div`
  color: ${colors.redError};
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
`;

export const SaveForm = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ISpinnerContainer {
  height?: string;
}

export const SpinnerContainer = styled.div<ISpinnerContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props?.height || "200px"};
`;

interface ISaveButtonContainer {
  justifyContent?: string;
}

export const SaveButtonContainer = styled.div<ISaveButtonContainer>`
  display: flex;
  justify-content: ${(props) => props?.justifyContent || "center"};
  align-items: center;
  gap: 4px;
`;
