import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Select, { createFilter } from "react-select";
import api from "../../../../../services/api";
import { parseJwt } from "../../../../../services/auth";
import { CustomInput } from "../../../../../template/styles/styles";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { CNPJMask, CPFMask } from "../../../../../template/utils/masks";
import {
  parseDate2,
  parseTelefone,
} from "../../../../../template/utils/parser";
import { Container, customStyles } from "./../../styles";
import { FormContainer } from "./styles";
import { UFs } from "./utils/UFs";
import { tiposDeLeite } from "./utils/tiposDeLeite";
import { Laticinio } from "../../Laticinio";

const adicionarDadosNoFormData = (
  obj: any,
  formdata: FormData,
  prefixo?: string
) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      const keyWithPrefix = prefixo ? `${prefixo}.${key}` : key;
      formdata.append(keyWithPrefix, obj[key]);
    }
  }
};

interface IProps {
  toggle: () => void;
  loadProdutores: () => void;
  setIsLoading: (value: boolean) => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, errors, control, setError } = useForm({
    mode: "onSubmit",
  });

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [certificadoEmater, setCertificadoEmater] = useState<number>();
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [laticiniosGestor, setLaticiniosGestor] = useState<any[]>([]);
  const [ufSelected, setUfSelected] = useState<number>();
  const [laticiniosOptions, setLaticiniosOptions] = useState<Option[]>([]);
  const [selectedOptionLaticinio, setSelectedOptionLaticinio] =
    useState<Option | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [laticinios, setLaticinios] = useState<Laticinio[]>([]);

  useEffect(() => {
    const loadMunicipios = async () => {
      const { data } = await api.get("municipios/");
      data && setMunicipios(data);
    };
    loadMunicipios();
  }, []);

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
  }, [municipios]);

  useEffect(() => {
    (async function () {
      //Carrega os laticínios da API
      const { data } = await api.get<Laticinio[]>("laticinios/");
      setLaticinios(data);
    })();
  }, []);

  type Option = {
    value: number;
    label: string;
  };
  useEffect(() => {
    const options: Option[] = [];
    laticinios?.map((lat) => options.push({ value: lat.id, label: lat.nome }));
    setLaticiniosOptions(options);
  }, [laticinios]);

  const decodeJWT = () => {
    var token: any;
    token = localStorage.getItem("accessToken");

    if (token) {
      let aux = "";
      aux = token;
      return aux;
    }
    return "";
  };

  const onSubmit = async (data: any) => {
    if (certificadoEmater === 1 && data?.dap.length === 0) {
      notify("error", `Por favor, inserir DAP`);
      setError("dap", {
        type: "manual",
        message: "Por favor, inserir DAP",
      });
    }
    setIsLoading(true);
    if (selectedOptionLaticinio === null) {
      notify("error", `Laticínio não selecionado`);
      setIsLoading(false);
      return;
    }

    if (data.certificado_emater === 1) {
      data.certificado_emater = true;
    } else {
      data.certificado_emater = false;
      data.dap = null;
    }
    data.laticinios = laticiniosGestor;
    data.endereco.numero = data.endereco.numero.replace(/\D/g, "");
    data.endereco.municipio
      ? (data.endereco.municipio = data.endereco.municipio.value)
      : (data.endereco.municipio = null);
    data.documento.numero = data.documento.numero.replace(/[.-]/g, "").trim();
    data.cnpj
      ? (data.cnpj = data.cnpj.replace(/[./-]/g, "").trim())
      : (data.cnpj = null);
    data.documento.tipo = 1;

    const produtorFormData = new FormData();
    // produtorFormData.append("dap", data.dap);

    const produtor: any = {
      agencia_banco: data.agencia_banco.replace(/[.-]/g, "").trim(),
      certificado_emater: data.certificado_emater,
      codigo_banco: data.codigo_banco,
      conta_banco: data.conta_banco.replace(/[.-]/g, "").trim(),
      dap: data?.dap ? data?.dap[0] : null,
      cnpj: data.cnpj,
      email: data.email,
      nome_banco: data.nome_banco,
      producao_media_de_leite: data.producao_media_de_leite,
      telefone: data.telefone.replace(/\D/g, ""),
      tipo_leite: data.tipo_leite,
      uf: ufSelected!,
      laticinios: selectedOptionLaticinio?.value,
      status: 1,
    };
    console.log(data);
    console.log(data.laticinios);
    console.log(selectedOptionLaticinio);

    if (ufSelected !== 20) {
      delete data.endereco.municipio;
    }

    const localidade = data.endereco.localidade.nome;
    delete data.endereco.localidade;

    adicionarDadosNoFormData(produtor, produtorFormData);
    adicionarDadosNoFormData(data.documento, produtorFormData, "documento");
    adicionarDadosNoFormData(data.pessoa, produtorFormData, "pessoa");
    adicionarDadosNoFormData(data.endereco, produtorFormData, "endereco");
    produtorFormData.append("endereco.localidade.nome", localidade);

    try {
      props.setIsLoading(true);
      await api.post(`/produtores/`, produtorFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // Notifica que houve sucesso na edição
      notify("success", `Produtor cadastrado com sucesso!`);
      // Atualiza a tabela
      props.loadProdutores();
      //Fecha o modal
      props.toggle();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { data }: any = error.response;
        if (produtor.nome_banco === undefined) {
          notify("error", `Por favor, inserir dados bancários`);
        } else if (data?.documento?.numero) {
          setError("documento.numero", {
            type: "manual",
            message: data.documento.numero[0],
          });
        } else if (data?.cnpj) {
          setError("cnpj", {
            type: "manual",
            message: data.cnpj,
          });
        } else if (data?.documento?.cpf) {
          setError("documento.numero", {
            type: "manual",
            message: data.documento.cpf[0],
          });
        } else if (data?.matricula) {
          setError("matricula", {
            type: "manual",
            message: data.matricula,
          });
        } else if (data?.email) {
          setError("email", {
            type: "manual",
            message: data?.email[0],
          });
        } else if (data?.usuario?.username) {
          setError("username", {
            type: "manual",
            message: data.usuario.username[0],
          });
        } else if (data?.dap) {
          setError("dap", {
            type: "manual",
            message: data.dap[0],
          });
        } else {
          notify(
            "error",
            `Ocorreu um erro no cadastro, verifique o formulário`
          );
        }
      } else {
        console.log("not axios error");
      }
    }
    props.setIsLoading(false);
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <h6>Dados do Produtor</h6>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.nome">Nome/Razão Social</label>
              <input
                className={
                  errors.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Nome completo da pessoa"
                name="pessoa.nome"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.pessoa?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.data_nascimento">Nascimento</label>
              <input
                type="date"
                className={
                  errors.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="01/01/2001"
                name="pessoa.data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => value <= parseDate2(new Date()),
                })}
              />
              {errors.pessoa?.data_nascimento?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="documento">CPF</label>
              <Controller
                className={
                  errors.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="documento.numero"
                defaultValue={""}
                as={InputMask}
                placeholder="Ex.: 999.999.999-99"
                control={control}
                mask={CPFMask.mask}
                maskChar=""
                rules={{
                  required: true,
                  minLength: CPFMask.maxLength,
                }}
              />
              {errors.documento?.numero?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.documento?.numero?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 11 dígitos
                </span>
              )}
              {errors.documento?.numero && (
                <span className="error-message">
                  {errors.documento.numero.message}
                </span>
              )}
              {errors.documento?.cpf && (
                <span className="error-message">
                  {errors.documento.cpf.message}
                </span>
              )}
              {errors.documento?.numero?.type === "valido" && (
                <span className="error-message">CPF inválido</span>
              )}
              {errors.documento?.numero?.type === "unico" && (
                <span className="error-message">CPF já cadastrado</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="cnpj">CNPJ (Opcional)</label>
              <Controller
                className={
                  errors.cnpj ? "CustomInput error-input" : "CustomInput"
                }
                name="cnpj"
                as={InputMask}
                defaultValue={""}
                placeholder="Ex.: 99.999.999/9999-99"
                control={control}
                mask={CNPJMask.mask}
                maskChar=""
                rules={{
                  required: false,
                  minLength: CNPJMask.maxLength,
                }}
              />
              {errors.cnpj?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 14 dígitos
                </span>
              )}
              {errors.cnpj && (
                <span className="error-message">{errors.cnpj.message}</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="telefone">Telefone</label>
              <input
                type="text"
                className={
                  errors.telefone ? "CustomInput error-input" : "CustomInput"
                }
                name="telefone"
                placeholder="(99) 99999-9999"
                ref={register({
                  required: true,
                  minLength: 13,
                })}
                onChange={(e: any) => {
                  e.currentTarget.value = parseTelefone(e.currentTarget.value);
                }}
              />
              {errors.telefone?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.telefone?.type === "minLength" && (
                <span className="error-message">Mínimo 10 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="email">Email (opcional)</label>
              <input
                type="email"
                className={
                  errors.email ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="exemplo@email.com"
                name="email"
                ref={register({
                  required: false,
                })}
              />
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="producao_media_de_leite">Média de leite</label>
              <input
                type="number"
                className={
                  errors.producao_media_de_leite
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Ex: 100.00"
                name="producao_media_de_leite"
                ref={register({
                  required: true,
                  validate: (value) => value >= 0,
                })}
              />
              {errors.producao_media_de_leite?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.producao_media_de_leite?.type === "validate" && (
                <span className="error-message">O valor deve ser positivo</span>
              )}
              {errors.producao_media_de_leite && (
                <span className="error-message">
                  {errors.producao_media_de_leite.message}
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label>Tipo de Leite</label>
              <select
                name="tipo_leite"
                className={
                  errors.tipo_leite ? "CustomInput error-input" : "CustomInput"
                }
                ref={register({
                  required: true,
                  validate: (value) => value > 0,
                })}
                style={{ background: "white" }}
              >
                <option value={0} disabled selected>
                  Selecione o tipo de leite{" "}
                </option>
                {tiposDeLeite &&
                  tiposDeLeite.map((tipo) => (
                    <option key={tipo.value} value={tipo.value}>
                      {tipo.label}
                    </option>
                  ))}
              </select>
              {errors.tipo_leite?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.tipo_leite?.type === "validate" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <label htmlFor="certificado_emater">
              Produtor Certificado EMATER
            </label>
            <CustomInput>
              <select
                name="certificado_emater"
                className={
                  errors.certificado_emater
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                value={certificadoEmater}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedValue = Number(e.currentTarget.value);
                  setCertificadoEmater(selectedValue);
                }}
                ref={register({
                  required: true,
                  validate: (value) => value > 0,
                })}
                style={{ background: "white" }}
              >
                <option disabled selected>
                  Selecione sim ou não
                </option>
                <option value={1}>Sim</option>
                <option value={2}>Não</option>
              </select>
              {errors.certificado_emater?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.certificado_emater?.type === "validate" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
        </div>
        {certificadoEmater === 1 && (
          <>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="t">DAP - Declaração de aptidão ao PRONAF</label>
                <input
                  type="file"
                  className={
                    errors.dap ? "CustomInput error-input" : "CustomInput"
                  }
                  name="dap"
                  accept="application/pdf"
                  ref={register({
                    required: false,
                  })}
                />
                {errors.dap && (
                  <span className="error-message">{errors.dap.message}</span>
                )}
              </CustomInput>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="laticinio">Laticínio</label>
              <Select
                placeholder="Selecione"
                styles={customStyles}
                className={errors.laticinio ? "error-input" : ""}
                options={laticiniosOptions}
                value={selectedOptionLaticinio}
                onChange={(val) => {
                  setSelectedOptionLaticinio(val);
                }}
                noOptionsMessage={() => "Nenhum laticínio encontrado"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              {errors.laticinio && (
                <span className="error-message">Selecione um laticínio</span>
              )}
            </CustomInput>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <label htmlFor="UF">UF</label>
            <CustomInput>
              <select
                name="UF"
                className={
                  errors.UF ? "CustomInput error-input" : "CustomInput"
                }
                value={ufSelected}
                onChange={(e: any) => {
                  const selectedValueFormated = Number(e.currentTarget.value);
                  setUfSelected(selectedValueFormated);
                }}
                ref={register({
                  required: true,
                  validate: (value) => value > 0,
                })}
                style={{ background: "white" }}
              >
                <option disabled selected>
                  Selecione uma UF
                </option>
                {UFs &&
                  UFs.map((uf) => (
                    <option key={uf.value} value={uf.value}>
                      {uf.label}
                    </option>
                  ))}
              </select>
              {errors.UF?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.UF?.type === "validate" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.cep">CEP</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                defaultValue={""}
                control={control}
                mask="99999-999"
                maskChar=""
                placeholder="99999-999"
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">Deve conter 8 dígitos</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                type="text"
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.rua"
                ref={register({ required: true })}
                placeholder={"Av Maria Lacerda"}
              />
              {errors.endereco?.rua?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.numero">Número</label>
              <input
                type="text"
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.numero"
                ref={register()}
                defaultValue="s/n"
                onChange={(e) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
            </CustomInput>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                type="text"
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.bairro"
                ref={register({ required: true })}
                placeholder={"Centro"}
              />
              {errors.endereco?.bairro?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="endereco.localidade.nome">Localidade</label>
              <input
                type="text"
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="endereco.localidade.nome"
                ref={register({ required: true })}
                placeholder={"Comunidade Maria"}
              />
              {errors.endereco?.localidade?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          {ufSelected === 20 && (
            <div className="col-sm">
              <div className="label">
                <label htmlFor="endereco.municipio">Município</label>
                <Controller
                  name="endereco.municipio"
                  placeholder={"Selecione um município"}
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  className={errors.endereco?.municipio ? "error-input" : ""}
                  as={Select}
                  menuPlacement="top"
                  options={municipiosOptions}
                  styles={customStyles}
                  noOptionsMessage={() => "Nenhum município encontrado"}
                  filterOption={createFilter({ ignoreAccents: false })}
                />
                {errors.endereco?.municipio && (
                  <span className="error-message">Selecione um município</span>
                )}
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="row">
          <div className="col-sm">
            <h6>Dados bancários</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <CustomInput>
              <label htmlFor={"codigo_banco"}>Código</label>
              <input
                type="number"
                className={
                  errors.codigo_banco
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="codigo_banco"
                ref={register({
                  required: true,
                  validate: (value) => value <= 32767,
                })}
              />
              {errors.codigo_banco?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.codigo_banco?.type === "validate" && (
                <span className="error-message">
                  Certifique-se de que este valor seja inferior ou igual a
                  32767.
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor={"nome_banco"}>Banco</label>
              <input
                type="text"
                className={
                  errors.nome_banco ? "CustomInput error-input" : "CustomInput"
                }
                name="nome_banco"
                ref={register({
                  required: true,
                  maxLength: 20,
                })}
              />
              {errors.nome_banco?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.nome_banco?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 20
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor={"agencia_banco"}>Agência</label>
              <input
                type="text"
                className={
                  errors.agencia_banco
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="agencia_banco"
                ref={register({ required: true })}
              />
              {errors.agencia_banco?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor={"conta_banco"}>Conta</label>
              <input
                type="text"
                className={
                  errors.conta_banco ? "CustomInput error-input" : "CustomInput"
                }
                name="conta_banco"
                ref={register({ required: true })}
              />
              {errors.conta_banco?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
            <input
              type="hidden"
              name="laticinios"
              ref={register()}
              defaultValue=""
            />
          </div>
        </div>
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
