import Select from "react-select";
import styled from "styled-components";
import colors from "../../template/styles/colors";
import { TiUserDeleteOutline } from "react-icons/ti";
import { FaRegBuilding } from "react-icons/fa";
import { Spinner } from "reactstrap";

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .space {
    margin-left: 12px;
  }

  .customTable {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  table {
    margin-top: 10px;
  }

  .botoes {
    display: flex;
    align-items: center;
    column-count: 3;
    column-gap: 15px;
  }

  .bottom-buttons {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 12px 0;
  }

  .local-search form:nth-child(1) {
    width: 250px;
  }

  h6 {
    font-weight: bolder;
    margin: 0;
  }

  .filterForm span {
    color: red;
    font-weight: bold;
    margin-top: 5px;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }
`;

export const CustomReactSelect = styled(Select)`
  min-width: 200px;
`;

export const CustomIconDesvincular = styled(TiUserDeleteOutline)`
  font-size: 1rem;
`;

export const CustomIconTrocarPonto = styled(FaRegBuilding)`
  font-size: 1rem;
`;

export const CustomSpinner2 = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
`;

export const TableCount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
